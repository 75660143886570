import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Loading from 'components/common/loading/Loading';

import { LINK_LIBRARY_TYPES } from 'constants/linkLibrary';
import { QUICK_LINK_NOT_LOADED } from 'constants/dashboard';

const QuickLinkLink = ({ link }) => {
  return (
    <div className="linkLibrary__wrapper" key={link.id}>
      <div className="linkLibrary__linkWrapper" key={link.id}>
        <a className="quick-links-content__links" href={link.linkUrl} target="_blank" rel="noopener noreferrer">
          <span>{link.name}</span>
        </a>
      </div>
    </div>
  );
};

const parentChildClassNameMapper = {
  linkLibrary__mainCategory: 'quick-links-content__title',
  'quick-links-content__title': 'quick-links-content__title'
};

const QuickLinksContainer = ({ quickLink, className }) => {
  return (
    <>
      {quickLink.type === LINK_LIBRARY_TYPES.SUBCATEGORY && (
        <>
          <div className={className}>{quickLink.name}</div>
          {quickLink.children?.map(qLink => {
            return (
              <QuickLinksContainer quickLink={qLink} key={qLink.id} className={parentChildClassNameMapper[className]} />
            );
          })}
        </>
      )}
      {quickLink.type === LINK_LIBRARY_TYPES.CATEGORY && (
        <>
          {quickLink.children?.map(qLink => {
            return (
              <div className="quick-links-content">
                <QuickLinksContainer quickLink={qLink} key={qLink.id} className="linkLibrary__mainCategory" />
              </div>
            );
          })}
        </>
      )}
      {quickLink.type === LINK_LIBRARY_TYPES.LINK && (
        <>
          {quickLink.children?.map(qLink => {
            return <QuickLinkLink link={qLink} />;
          })}
        </>
      )}
    </>
  );
};

const QuickLinksWrapper = ({ linkLibraryData, isLoading, hasErrorInLoadingQuickLinks: hasError }) => {
  const [expandedCategories, setExpandedCategories] = useState(linkLibraryData[0]); // Used linkLibraryData[0] to show the first content by default.

  useEffect(() => {
    setExpandedCategories(linkLibraryData[0]);
  }, [linkLibraryData]);

  if (isLoading) {
    return (
      <div className="full-scope-card">
        <Loading />
      </div>
    );
  }

  const toggleCategory = categoryId => {
    const data = linkLibraryData.find(data => data.id === categoryId);

    setExpandedCategories(data);
  };

  return (
    <div className="full-scope-card m-0">
      {hasError ? (
        <div className="container mt-25">
          <div className="allocation__empty">
            <p>{QUICK_LINK_NOT_LOADED}</p>
          </div>
        </div>
      ) : (
        <>
          <div className="p-1rem linkLibrary__header">
            <div className="font-size-20 linkLibrary__title pl-32">Link Library</div>
            <ul className="secondary-nav__wrap">
              {linkLibraryData.map(category => (
                <li key={category.id} className="secondary-nav__node" onClick={() => toggleCategory(category.id)}>
                  <div
                    className={classNames('secondary-nav__link secondary-nav__link--primary clear-after', {
                      active: expandedCategories.id === category.id
                    })}
                  >
                    {category.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="quick-links-container pl-32">
            <QuickLinksContainer quickLink={expandedCategories} className="linkLibrary__mainCategory" />
          </div>
        </>
      )}
    </div>
  );
};

export default QuickLinksWrapper;
