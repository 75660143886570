import classNames from 'classnames';
import { BsThreeDotsVertical } from 'react-icons/bs';
import React, { useCallback, useEffect, useState } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { useSelector } from 'react-redux';
import { getIndexOfSN } from 'utils/table';
import { isObjectEmpty } from 'utils/object';
import { getFormattedDate } from 'utils/date';
import { handleError } from 'utils/errorHandler';
import { parse, stringify } from 'utils/queryParam';
import { interpolate, pluralize } from 'utils/string';

import * as routes from 'constants/routes';
import * as permission from 'constants/permissions';
import { DISPLAY_DATE_FORMAT } from 'constants/date';
import { MINIMUM_PAGE_COUNT, MINIMUM_PAGE_ITEM } from 'constants/page';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  EVENTS_REFERENCE_PERIOD,
  EVENTS_REFERENCE_PERIOD_PILL_TABS
} from 'constants/appConstants';
import { HOLIDAY_DESCRIPTION_LENGTH, HOLIDAY_NAME_LENGTH } from 'constants/string';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';
import PillTab from 'components/common/pillTab';
import Pagination from 'components/common/paginator';
import EditDelete from 'components/common/editDelete';
import TableHeader from 'components/common/table/TableHeader';

import * as holidayService from 'services/holidays';

import useCurrentFiscalYear from 'hooks/useCurrentFiscalYear';
import getCurrentReferencePeriod from 'utils/getCurrentReferencePeriod';

const HolidayList = props => {
  window.document.title = 'Holidays | Vyaguta';

  const [pageData, setPageData] = useState({});
  const [pageCount, setPageCount] = useState(DEFAULT_PAGE_NUMBER);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const {
    value: { permissions }
  } = useSelector(state => state.information);
  const [holidayList, setHolidayList] = useState([]);
  const [columnToSortBy, setColumnToSortBy] = useState('date');
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const EVENTS_CURRENT_REFERENCE_PERIOD = getCurrentReferencePeriod(props);
  const [queries, setQueries] = useState({
    referencePeriod: EVENTS_CURRENT_REFERENCE_PERIOD || EVENTS_REFERENCE_PERIOD.UPCOMING.toLowerCase()
  });

  const queryParams = parse(props.location.search, {
    decoder: str => decodeURIComponent(str)
  });

  const { page, size, referencePeriod } = queries;

  const currentFiscalYear = useCurrentFiscalYear();

  const handleEdit = row => {
    history.push(interpolate(routes.UPDATE_HOLIDAY, { id: row.original.id }));
  };

  const handleDelete = async row => {
    try {
      await holidayService.remove(row.original.id);

      toast.success({
        title: 'Success',
        message: 'Holiday deleted successfully.'
      });

      if (holidayList.length === MINIMUM_PAGE_ITEM) {
        queryParams.page = DEFAULT_PAGE_NUMBER;
        updateUrl({
          ...parse(props.location.search, {
            decoder: str => decodeURIComponent(str)
          }),
          page: DEFAULT_PAGE_NUMBER
        });
      }

      fetchHolidays();
    } catch (err) {
      handleError(err);
    }
  };

  const getEditCell = () => {
    const canEdit = permissions[permission.HOLIDAYS.UPDATE];
    const canDelete = permissions[permission.HOLIDAYS.DELETE];

    const canEditOrDelete = canEdit || canDelete;

    return {
      Header: '',
      width: 128,
      className: 'margin-left-auto',
      Cell: row =>
        canEditOrDelete ? (
          <span
            className="verticle-ellipse cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <PopOver
              interactive
              trigger="click"
              theme={'light'}
              size={'regular overflow-menu__regular'}
              position="left-start"
              disableOnScroll={true}
              popperOptions={{
                modifiers: {
                  addZIndex: {
                    enabled: true,
                    order: 810,
                    fn: data => ({
                      ...data,
                      styles: {
                        ...data.styles,
                        zIndex: 10
                      }
                    })
                  }
                }
              }}
              html={
                <EditDelete
                  onEdit={() => handleEdit(row)}
                  onDelete={() => handleDelete(row)}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  type="holiday"
                  title={row.original.name}
                />
              }
            >
              <BsThreeDotsVertical size={20} />
            </PopOver>
          </span>
        ) : null
    };
  };

  const fetchHolidays = async () => {
    try {
      setIsDataLoading(true);

      const size = queryParams.size || DEFAULT_PAGE_SIZE;
      const page = queryParams.page || DEFAULT_PAGE_NUMBER;

      const filters = {
        ...queries,
        page,
        size
      };

      setPageSize(size);

      const { data, meta } = await holidayService.fetch(filters);
      const { total } = meta;

      const pageCount = Math.ceil(total / size);

      setPageData(meta);

      const formattedData = data.map(({ description, date, ...rest }) => ({
        ...rest,
        description: description || '-',
        date: getFormattedDate(date, DISPLAY_DATE_FORMAT)
      }));

      setHolidayList(formattedData);
      setPageCount(pageCount);
    } catch (error) {
      handleError(error);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    if (isObjectEmpty(currentFiscalYear)) {
      return;
    }

    fetchHolidays();
  }, [props.location.search, queryParams.page, queryParams.size, currentFiscalYear]);

  const getRowProps = (state, rowInfo) => {
    return {
      className: 'align-items-center d-flex',
      onClick: e => {
        if (!permissions[permission.HOLIDAYS.UPDATE]) {
          return;
        }

        const url = interpolate(routes.UPDATE_HOLIDAY, { id: rowInfo.original.id });

        if (e.ctrlKey || e.metaKey) {
          window.open(url);
        } else {
          history.push(url);
        }
      }
    };
  };

  const getColumns = (
    isAscendingOrdered,
    setIsAscendingOrdered,
    columnToSortBy,
    setColumnToSortBy,
    pageData,
    pageSize
  ) => [
    {
      Header: <TableHeader label="SN." identifier="sn" />,
      accessor: 'sn',
      maxWidth: 56,
      sortable: false,
      filterable: false,
      Cell: props => {
        const index = getIndexOfSN(props, pageSize, pageData);
        return <span className="capital-text employees__table-text">{index < 10 ? `0${index}` : index}</span>;
      }
    },
    {
      Header: (
        <TableHeader
          label="Title"
          identifier="name"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            setIsAscendingOrdered(prevStatus => columnToSortBy !== 'name' || !prevStatus);
            setColumnToSortBy('name');
          }}
        />
      ),
      accessor: 'name',
      maxWidth: 300,
      Cell: props => {
        return (
          <>
            {props.original.name.length > HOLIDAY_NAME_LENGTH ? (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{props.original.name}</p>
                  </div>
                }
              >
                <div className="ellipsis">{props.original.name}</div>
              </PopOver>
            ) : (
              <div className="ellipsis">{props.original.name}</div>
            )}
          </>
        );
      }
    },
    {
      Header: <TableHeader label="Description" identifier="description" />,
      accessor: 'description',
      sortable: false,
      filterable: false,
      maxWidth: 640,
      Cell: props => {
        return (
          <>
            {props.original.description.length > HOLIDAY_DESCRIPTION_LENGTH ? (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{props.original.description}</p>
                  </div>
                }
              >
                <div className="ellipsis">{props.original.description}</div>
              </PopOver>
            ) : (
              <div className="ellipsis">{props.original.description}</div>
            )}
          </>
        );
      }
    },
    {
      Header: (
        <TableHeader
          label="Countries"
          identifier="countries"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            if (columnToSortBy !== 'countries') {
              setIsAscendingOrdered(true);
            } else {
              setIsAscendingOrdered(!isAscendingOrdered);
            }
            setColumnToSortBy('countries');
          }}
        />
      ),
      accessor: 'countries',
      maxWidth: 260,
      Cell: props => {
        const { countries } = props.original;
        return <>{countries ? countries.join(', ') : 'All'}</>;
      }
    },
    {
      Header: (
        <TableHeader
          label="Date"
          identifier="date"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            setIsAscendingOrdered(prevStatus => columnToSortBy !== 'date' || !prevStatus);
            setColumnToSortBy('date');
          }}
        />
      ),
      accessor: 'date',
      maxWidth: 120
    }
  ];

  const updateUrl = useCallback(
    value => {
      const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });
      history.push({
        pathname: props.location.pathname,
        search: queryParam
      });
    },
    [props.location.pathname]
  );

  useEffect(() => {
    updateUrl({
      ...parse(props.location.search, {
        decoder: str => decodeURIComponent(str)
      }),
      ...queries
    });
  }, [referencePeriod, page, size, props.location.search, updateUrl]);

  const onPageChange = selectedPage => {
    setQueries(value => ({ ...value, page: selectedPage }));
  };

  const onPageSizeChange = selectedSize => {
    setQueries(queries => ({ ...queries, page: DEFAULT_PAGE_NUMBER, size: selectedSize }));
  };
  const handleApplyFilter = filters => {
    setQueries({ ...filters, page: DEFAULT_PAGE_NUMBER, size: DEFAULT_PAGE_SIZE });
  };

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div>
          <div className="table-title">Holidays</div>
          <div className="table-subtitle">
            Showing {holidayList.length} {pluralize('Result', holidayList.length)}
          </div>
        </div>
        <div className="table-header__right">
          <PillTab
            tabs={EVENTS_REFERENCE_PERIOD_PILL_TABS}
            onTabClick={value => handleApplyFilter({ referencePeriod: value })}
            isActive={value => value === queries.referencePeriod.toLowerCase()}
          />
        </div>
      </div>
      <div className={classNames('full-scope-card__content')}>
        <>
          <Table
            loading={isDataLoading}
            data={holidayList}
            columns={[
              ...getColumns(
                isAscendingOrdered,
                setIsAscendingOrdered,
                columnToSortBy,
                setColumnToSortBy,
                pageData,
                pageSize
              ),
              getEditCell()
            ]}
            showPagination={false}
            noDataText="No holidays to show."
            getTrProps={getRowProps}
          />
          <Pagination
            pageData={pageData}
            pageCount={+(pageCount || MINIMUM_PAGE_COUNT)}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </>
      </div>
    </div>
  );
};

export default HolidayList;
