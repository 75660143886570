import { useEffect, useState } from 'react';

import http from 'utils/http';

const useAuthenticatedImage = ({ src, onError }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchImage = async imgSrc => {
      setIsLoading(true);
      try {
        const data = await http.get(imgSrc, {
          responseType: 'blob'
        });

        const objectURL = URL.createObjectURL(data);
        setImageSrc(objectURL);
      } catch (error) {
        onError && onError();
        console.error('Error while fetching image');
      } finally {
        setIsLoading(false);
      }
    };

    if (src) {
      fetchImage(src);
    }

    // Cleanup function to revoke object URL when component unmounts
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [src]);

  return {
    src: imageSrc,
    isLoading: isLoading
  };
};

export default useAuthenticatedImage;
