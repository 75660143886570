import * as Yup from 'yup';

import { ROLE } from 'constants/appConstants';

export const changeIssuerSchema = Yup.object({
  newLeaveIssuerId: Yup.number()
    .required('Please select user')
    .typeError('Please select user'),
  transitionDate: Yup.date()
    .required('Please select transition date')
    .typeError('Invalid date format'),
  newLeaveIssuerRole: Yup.string().required('Please select role'),
  otherRole: Yup.string().when('newLeaveIssuerRole', {
    is: value => value === ROLE.OTHER,
    then: Yup.string()
      .required('Please provide role')
      .max(255, 'Role should be of at most 255 characters')
  }),
  reason: Yup.string()
    .required('Please provide reason')
    .trim()
    .min(8, 'Reason should be of at least 8 characters.')
    .typeError('Please provide reason')
});

export const rejectChangeRequestSchema = Yup.object({
  rejectionReason: Yup.string()
    .required('Please provide a rejection reason.')
    .trim()
    .min(8, 'Rejection reason should be of at least 8 characters.')
});
