import * as Yup from 'yup';

const ratingSchema = ratingValue =>
  Yup.object().shape({
    rating: Yup.number().required(),
    description: Yup.string()
      .required(`Please provide description for rating ${ratingValue}`)
      .trim()
  });

export const addSkillSchema = Yup.object({
  name: Yup.string()
    .required('Please provide name')
    .trim()
    .typeError('Please provide name'),

  description: Yup.string()
    .trim()
    .typeError('Please provide description'),

  isGlobalRatingChecked: Yup.boolean(),

  localRating: Yup.array().when('isGlobalRatingChecked', {
    is: false,
    then: Yup.array().of(
      Yup.lazy((value, schema) => {
        return ratingSchema(schema.originalValue.rating);
      })
    ),
    otherwise: Yup.array().notRequired()
  })
});
