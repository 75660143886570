import classNames from 'classnames';
import React, { useState, useEffect } from 'react';

import { UiLink } from 'vyaguta-icons/ui';
import { INITIAL_OPEN_DELAY } from 'constants/appConstants';

const QuickLinksMenu = ({ isQuickLinksOpen, onClick, initialOpen = true }) => {
  const [isInitiallyOpen, setIsInitiallyOpen] = useState(true);

  useEffect(() => {
    if (!initialOpen) {
      return;
    }

    setTimeout(() => {
      setIsInitiallyOpen(false);
    }, INITIAL_OPEN_DELAY);
  }, []);

  return (
    <div className="quick-links">
      <div
        className={classNames('d-flex align-items-center content', {
          'content--show': initialOpen && isInitiallyOpen
        })}
        onClick={onClick}
      >
        <span className={classNames('label', { 'label--show': isQuickLinksOpen || (initialOpen && isInitiallyOpen) })}>
          Link Library
        </span>
        <div className="icon d-flex align-items-center">
          <UiLink size={18} color="#2196F3" className={classNames({ 'notices-icon-active': isQuickLinksOpen })} />
        </div>
      </div>
    </div>
  );
};

export default QuickLinksMenu;
