import classNames from 'classnames';
import { UiAngleRight, UiEdit, UiEditAlt, UiPen } from 'vyaguta-icons/ui';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';

import * as routes from 'constants/routes';
import { getFormattedDate } from 'utils/date';
import Avatar from 'components/common/avatar';
import PillStatus from 'components/common/pill/PillStatus';
import { capitalize, getEmpImageUrl, interpolate } from 'utils/string';
import { DATE_FORMAT_FOR_DISPLAY, NOTE_STATUS_TYPES } from 'constants/appConstants';

const EmployeeNoteItem = props => {
  const {
    index,
    note,
    showHeader = true,
    compact = false,
    isOpen: defaultState = false,
    className,
    authUserId
  } = props;

  const history = useHistory();
  const noteUserId = history.location.pathname.split('/')[2];

  const isCurrentNoteCreator = authUserId === note.createdBy;

  const [isOpen, setIsOpen] = useState(defaultState);

  useEffect(() => {
    setIsOpen(defaultState);
  }, [defaultState]);

  const toggleAccordion = useCallback(() => {
    setIsOpen(currentState => !currentState);
  }, []);

  return (
    <>
      <div
        className={classNames(
          'list-accordion',
          'user-select-none',
          {
            'list-accordion--compact': compact
          },
          className
        )}
      >
        <div className="list-accordion__row">
          {showHeader && (
            <div className="list-accordion__header" onClick={toggleAccordion}>
              <div className="list-accordion__header-left">
                <div className="list-accordion__icon-container">
                  <UiAngleRight
                    size={24}
                    className={classNames(
                      'list-accordion__icon',
                      { 'list-accordion__icon--down': isOpen },
                      { 'list-accordion__icon--right': !isOpen }
                    )}
                  />
                </div>
                <div className="list-accordion__header-left__number">
                  <p className="mr-24x">{note.sn}</p>
                </div>
                <div className="list-accordion__header-left__content">
                  <h4>{note.label} </h4>
                </div>
              </div>
              <div className="list-accordion__header-right">
                <div className="list-accordion__header-right__date">
                  <p className="list-accordion__header-right__content">
                    <p>{`${getFormattedDate(note.createdAt, DATE_FORMAT_FOR_DISPLAY)}`}</p>
                  </p>
                </div>
                <div className="list-accordion__header-right__content">
                  <PillStatus isActive={note.status === NOTE_STATUS_TYPES.ACTIVE} label={capitalize(note.status)} />
                </div>
              </div>
              {isCurrentNoteCreator && isOpen && (
                <div className="edit-icon">
                  <Link
                    className="btn--outlined-grey edit-icon__content"
                    to={interpolate(routes.UPDATE_EMPLOYEE_NOTE, { userId: noteUserId, noteId: note.id })}
                  >
                    <UiPen size={16} />
                  </Link>
                </div>
              )}
            </div>
          )}

          <div className={classNames('list-accordion__block-wrapper', { 'p-20': isOpen || !showHeader })}>
            <div
              className={classNames('list-accordion__block', {
                'list-accordion__block--show': isOpen || !showHeader
              })}
            >
              <div className="list-accordion__content">
                <div className="list-accordion__container">
                  <table className="list-accordion-table">
                    <tbody>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Title</span>
                        </td>
                        <td className="table__content">
                          <div className="note-label">{note.label}</div>
                        </td>
                      </tr>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Status</span>
                        </td>
                        <td className="table__content">
                          <div className="cell-content">
                            <PillStatus
                              isActive={note.status === NOTE_STATUS_TYPES.ACTIVE}
                              label={capitalize(note.status)}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Created Date</span>
                        </td>
                        <td className="table__content">
                          <div className="cell-content">
                            <span>{getFormattedDate(note.createdAt, DATE_FORMAT_FOR_DISPLAY)}</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Viewers</span>
                        </td>
                        <td className="table__content">
                          <div className="endorsers__list d-flex">
                            {note.viewers?.map(viewer => (
                              <Link
                                key={viewer.id}
                                className="d-flex align-items-center"
                                to={interpolate(routes.EMPLOYEE_PROFILE, { id: viewer.id })}
                                target="_blank"
                              >
                                <Avatar
                                  image={getEmpImageUrl(viewer.empId)}
                                  alt={viewer.fullName}
                                  size="small"
                                  className="mr-8"
                                />
                                <div className="profile-link fs-13 mr-8">{viewer.fullName}</div>
                              </Link>
                            ))}
                          </div>
                        </td>
                      </tr>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Actionable People</span>
                        </td>
                        <td className="table__content">
                          <div className="endorsers__list d-flex">
                            {note.actionableUsers?.map(user => (
                              <Link
                                key={user.id}
                                className="d-flex align-items-center"
                                to={interpolate(routes.EMPLOYEE_PROFILE, { id: user.id })}
                                target="_blank"
                              >
                                <Avatar
                                  image={getEmpImageUrl(user.empId)}
                                  alt={user.fullName}
                                  size="small"
                                  className="mr-8"
                                />
                                <div className="profile-link fs-13 mr-8">{user.fullName}</div>
                              </Link>
                            ))}
                          </div>
                        </td>
                      </tr>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Creator</span>
                        </td>
                        <td className="table__content">
                          <div className="endorsers__list d-flex">
                            <Link
                              className="d-flex align-items-center"
                              to={interpolate(routes.EMPLOYEE_PROFILE, { id: note?.noteCreator?.id })}
                              target="_blank"
                            >
                              <Avatar
                                image={getEmpImageUrl(note?.noteCreator?.empId)}
                                alt={note?.noteCreator?.fullName}
                                size="small"
                                className="mr-8"
                              />
                              <div className="profile-link fs-13 mr-8">{note?.noteCreator?.fullName}</div>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Action Date</span>
                        </td>
                        <td className="table__content">
                          <div className="cell-content">
                            <span>
                              {note.actionDate ? getFormattedDate(note.actionDate, DATE_FORMAT_FOR_DISPLAY) : '-'}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="list-accordion-table list-accordion__container--right">
                    <tbody>
                      <tr className="table__row">
                        <td className="table__col">
                          <span className="cell-title">Description</span>
                        </td>
                        <td className="table__content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: note.description || '-'
                            }}
                            className="custom_content"
                          ></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeNoteItem;
