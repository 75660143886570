export const USER_UPDATABLE_FIELDS = [
  'cvUrl',
  'dateofBirth',
  'bloodGroup',
  'maritialStatus',
  'personalEmail',
  'mobilePhone',
  'homePhone',
  'emergencyPhone',
  'emergencyContactRelationship',
  'temporaryAddress',
  'permanentAddress',
  'country',
  'githubId'
];
