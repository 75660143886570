import {
  CREATE_EVENT,
  CREATE_HOLIDAY,
  CREATE_NOTICE,
  CREATE_REMINDER,
  EVENTS,
  HOLIDAYS,
  NOTICES,
  REMINDERS
} from './routes';

import * as permissions from './permissions';

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';

export const DATE_FORMAT_FOR_DISPLAY = 'MMM DD, YYYY';

export const ABBREVIATED_DATE_FORMAT = 'MMM DD';

export const REACT_DATEPICKER_DATE_FORMAT = 'MMMM dd, yyyy';

export const SMALL_DATEPICKER_DATE_FORMAT = 'DD MMM, YYYY';

export const ddd_MMM_DD = 'ddd, MMM DD';

export const PROJECT_ALLOCATION_DATE_FORMAT = 'll';

export const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_TIMEZONE = '+05:45';

export const GITHUB_PROFILE_URL = 'https://github.com/:username';

export const SKYPE_URL = 'skype::username?call';

export const EMAIL_URL = 'mailto::email';

export const PHONE_URL = 'tel::phone';

export const ENV_TYPE = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  QA: 'qa',
  UAT: 'uat',
  LOCAL: 'local'
};

export const PAGE_SIZE = {
  MAX: 500,
  MIN: 5
};

export const NO_INTERNET_CONNECTION_ERROR_MESSAGE = 'Please check your internet connection.';

export const TWELEVE_HOUR_DATE_TIME_FORMAT = 'MMM DD, YYYY hh:mm:ss A';

export const MONTH_DAY_DISPLAY_FORMAT = 'MMM DD';

export const WEEK_DAY_DISPLAY_FORMAT = 'ddd';

export const MONTH_DISPLAY_FORMAT = 'MMM';

export const YEAR_DISPLAY_FORMAT = 'YYYY';

export const DAY_DISPLAY_FORMAT = 'DD';

export const DATE_DISPLAY_FORMAT = 'MMM DD, YYYY';

export const USER_FETCH_FIELDS = {
  ROLES: 'roles'
};

export const ROLES_IDS = {
  ALL: 'All',
  NONE: 'None',
  TEAM_MANAGER: 11
};

export const NOTE_NOT_FOUND_TEXT = 'Notes not found.';
export const NOTE_DESCRIPTION_PLACEHOLDER = 'Provide a suitable description for the note...';
export const EMPTY_NOTE_RESULT_MESSAGE = 'There are no notes created yet';

export const noteLabels = [
  {
    label: 'US visit',
    value: 'Us Visit'
  },
  {
    label: 'Change Project',
    value: 'Change Project'
  },
  {
    label: 'About to get married',
    value: 'About to get married'
  },
  {
    label: 'Plan for masters',
    value: 'Plan for masters'
  }
];

export const NOTE_FORM_FIELD_VALUES = {
  VIEWERS: 'viewers',
  ACTIONABLE_PEOPLE: 'actionableUsers'
};

export const TIME_ZONES = ['+11:00', '+05:45', '+05:30', '+01:00', '-05:00', '-06:00', '+07:00', '-07:00', '-08:00'];

export const BLOOD_GROUPS = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];

export const MARITAL_STATUS = ['Single', 'Married'];

export const MIN_DEBOUNCE_TIME = 200;
export const MIN_USER_SEARCH_LENGTH = 3;
export const DEFAULT_USER_LIST_SIZE = 20;

export const INITIAL_OPEN_DELAY = 3500;

export const LIST_TYPE = {
  EMPLOYEE: 'employee',
  PROJECT: 'project'
};

export const TYPE_USER_TEXT = 'Please type a name to search';
export const SEARCH_LEAVE_ISSUER_TEXT = 'Search Leave issuer, hit enter to select';
export const SEARCH_USER_TEXT = 'Search employee, hit enter to select';

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER'
};

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: GENDER.MALE
  },
  {
    label: 'Female',
    value: GENDER.FEMALE
  },
  {
    label: 'Others',
    value: GENDER.OTHER
  }
];

export const INITIAL_EMPLOYEE_SELECT_VALUE = {
  label: 'Search employees, hit enter to add it below ...',
  value: {}
};

export const SEARCH_TEAM_MANAGER_TEXT = 'Search Team Manager, hit enter to select';
export const NO_USERS_FOUND_TEXT = 'No users found for the given input. Please enter the valid identifier';

export const WORKING_TYPE_OPTIONS = [
  {
    label: 'Part Time',
    value: 'PART_TIME'
  },
  {
    label: 'Full Time',
    value: 'FULL_TIME'
  }
];

export const NOTE_STATUS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  }
];

export const DEFAULT_RELATION_STATUS = { label: 'All', value: '' };

export const NOTE_ROLE = [
  {
    label: 'Viewer',
    value: 'viewer'
  },
  {
    label: 'Actionable',
    value: 'actionable'
  },
  {
    label: 'Creator',
    value: 'creator'
  }
];

export const RELATION_STATUS = [
  { label: 'All', value: '' },
  { label: 'Team Manager', value: 'teamManager' },
  { label: 'Leave Issuer', value: 'leaveIssuer' },
  { label: 'Appraiser', value: 'appraiser' },
  { label: 'Co Appraiser', value: 'coAppraiser' }
];

export const NOTE_STATUS_TYPES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const HOLIDAY_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE'
  }
];

export const OCCASION_TYPE_IDS = {
  HOLIDAY: 1,
  EVENT: 2
};

export const NOTE_STATUS_FILTER = [
  {
    value: null,
    label: 'All'
  },
  ...NOTE_STATUS
];

export const NOTE_ROLE_FILTER = [
  {
    value: null,
    label: 'All'
  },
  ...NOTE_ROLE
];

export const DEFAULT_NOTE_STATUS_FILTER = {
  value: 'active',
  label: 'Active'
};

export const DEFAULT_NOTE_ROLE_FILTER = {
  value: null,
  label: 'All'
};

export const DEFAULT_AVAILABILITY_START_TIME = {
  value: '09:00',
  type: 'AM'
};

export const DEFAULT_AVAILABILITY_END_TIME = {
  value: '06:00',
  type: 'PM'
};

export const ALLOCATION = {
  PROJECT: 'project',
  AREA: 'area',
  BENCH: 'bench'
};

export const BILLING_BILLED = 'Billed';
export const BILLING_LEARNING = 'Learning';

export const ALLOCATION_APPEND_TEXT_SINGULAR = 'hr/week';
export const ALLOCATION_APPEND_TEXT_PLURAL = 'hrs/week';

export const EMPLOYEE_SORT_BY = {
  FIRST_NAME: 'firstName',
  DESIGNATION: 'designation'
};
export const EMPLOYEE_SORT_ORDER = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC'
};

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const UNDER_MAINTENANCE_TITLE = 'We are currently fixing things';
export const UNDER_MAINTENANCE_MESSAGE1 = "We'll be back soon, we promise.";
export const UNDER_MAINTENANCE_MESSAGE2 = 'Please let the team know if there is anything urgent.';

export const DEFAULT_HISTORY_ENTRIES = 1;

export const PERMISSION_REVOKE_SUCCESS_MESSAGE = 'Permission revoked successfully.';
export const PERMISSION_GRANT_SUCCESS_MESSAGE = 'Permission granted successfully.';

export const CURRENT_ENGAGEMENT_STATUS_NONE = 'None';
export const CHANGE_ENGAGEMENT_STATUS_PAGE_TITLE = 'Change Employment Status';
export const ENGAGEMENT_STATUS_UPDATE_SUCCESS_MESSAGE = 'Employment status has been updated successfully.';
export const ENGAGEMENT_STATUS_TRANSITION_CONFIRMATION_TITLE = 'Do you want to change the employment status?';

export const ENGAGEMENT_STATUS_TERMINATION_CONFIRMATION_TITLE = 'Are you sure?';
export const ENGAGEMENT_STATUS_TERMINATION_CONFIRMATION_MESSAGE =
  "All the leave credits of :firstName :lastName will be cleared and can't be reverted back if status is updated to terminated. Do you want to continue?";

export const ENGAGEMENT_STATUS_TRANSITION_CONFIRMATION_MESSAGE =
  'You are about to change the employment status from ' +
  ':currentEngagementStatusLabel to :newEngagementStatusLabel. The following categories of leave will be added while ' +
  'changing the employment status from :currentEngagementStatusLabel to :newEngagementStatusLabel';

export const ENGAGEMENT_STATUS_LEAVE_CREDIT_SUCCESS_MESSAGE =
  ':leaveType leave credit for the engagement status transition has been added successfully.';
export const ENGAGEMENT_STATUS_LEAVE_CREDIT_FAILED_MESSAGE =
  'There was an error trying to update the :leaveType leave credit for the engagement status transition. Please update the :leaveType leave credits manually.';

export const EMPLOYEE_RESIGNATION_DELETE_CONFIRMATION_TITLE = 'Delete tentative exit date?';

export const EMPLOYEE_RESIGNATION_DELETE_CONFIRMATION_MESSAGE =
  'You are about to delete the tentative exit date of the :firstName :lastName.';

export const BREAKPOINTS = {
  xs: '576px',
  small: '768px',
  medium: '992px',
  large: '1200px',
  xl: '1400px'
};

export const CORE_APP_VERSION = 'CORE_APP_VERSION';

export const ROLE = {
  PROJECT_MANAGER: 'Project Manager',
  TEAM_LEAD: 'Team Lead',
  TEAM_MANAGER: 'Team Manager',
  TEAM_MEMBER: 'Team Member',
  OTHER: 'Other',
  SUB_CONTRACTOR: 'Sub-Contractor',
  SUPER_ADMIN: 'Super Admin',
  HR_ADMIN: 'HR Admin',
  HR: 'HR'
};

export const NEW_LEAVE_ISSUER_IS_OPTIONS = [
  {
    label: 'Project Manager',
    value: ROLE.PROJECT_MANAGER
  },
  {
    label: 'Team Lead',
    value: ROLE.TEAM_LEAD
  },
  {
    label: 'Team Manager',
    value: ROLE.TEAM_MANAGER
  },
  {
    labe: 'Team Member',
    value: ROLE.TEAM_MEMBER
  },
  {
    label: 'Other',
    value: ROLE.OTHER
  }
];

export const CREATE_EVENTS_ROUTE_OPTIONS = {
  [REMINDERS]: CREATE_REMINDER,
  [EVENTS]: CREATE_EVENT,
  [NOTICES]: CREATE_NOTICE,
  [HOLIDAYS]: CREATE_HOLIDAY
};

export const ADD_BUTTON_TEXT_OPTIONS = {
  [REMINDERS]: 'Add Reminder',
  [EVENTS]: 'Add Event',
  [NOTICES]: 'Add Notice',
  [HOLIDAYS]: 'Add Holiday'
};

export const CREATE_EVENTS_PERMISSION_OPTIONS = {
  [REMINDERS]: [permissions.REMINDERS.CREATE],
  [EVENTS]: [permissions.EVENTS.CREATE],
  [NOTICES]: [permissions.NOTICES.CREATE],
  [HOLIDAYS]: [permissions.HOLIDAYS.CREATE]
};

export const EVENTS_REFERENCE_PERIOD = {
  UPCOMING: 'Upcoming',
  PAST: 'Past',
  ALL: 'All'
};

export const MY_ALLOCATION = {
  CURRENT: 'Current',
  BOOKING: 'Booking'
};

export const EVENTS_REFERENCE_PERIOD_PILL_TABS = [
  {
    label: EVENTS_REFERENCE_PERIOD.UPCOMING,
    value: 'upcoming',
    isDefaultActive: true
  },
  {
    label: EVENTS_REFERENCE_PERIOD.PAST,
    value: 'past'
  },
  {
    label: EVENTS_REFERENCE_PERIOD.ALL,
    value: 'all'
  }
];

export const ALLOCATION_PILL_TAB_OPTIONS = [
  {
    label: MY_ALLOCATION.CURRENT,
    value: 'current',
    isDefaultActive: true
  },
  {
    label: MY_ALLOCATION.BOOKING,
    value: 'booking'
  }
];

export const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

export const DEFAULT_DEBOUNCE_TIME = 1000;

export const EDIT_PAST_HOLIDAY_NOT_ALLOWED = 'Editing past holidays is not allowed';

export const DELETE_PAST_HOLIDAY_NOT_ALLOWED = 'Deleting past holidays is not allowed';

export const EDIT_OR_DELETE_PAST_HOLIDAY = 'Editing or deleting past holiday is not allowed.';

export const AVAILABILITY_TIME_RANGES = {
  MORNING_SHIFT: { start: '05:00 AM', end: '02:00 PM' },
  DAY_SHIFT: { start: '09:00 AM', end: '06:00 PM' },
  EVENING_SHIFT: { start: '01:00 PM', end: '10:00 PM' },
  LATE_EVENING_SHIFT: { start: '03:00 PM', end: '12:00 AM' }
};
