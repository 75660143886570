import config from 'config';
import pinterpolate from 'pinterpolate';
import { useSelector } from 'react-redux';
import { pulse } from 'assets/images.js';
import React, { useEffect, useMemo } from 'react';
import { SecondaryNav } from '@vyaguta/vyaguta-ui';
import { Route, Redirect, Switch } from 'react-router-dom';

import useQuery from 'hooks/useQuery.js';

import interpolate from 'pinterpolate';

import LeaveCredits from './LeaveCredits.js';
import EmployeeGoals from './EmployeeGoals.js';
import EmployeeAssets from './EmployeeAssets.js';
import * as permissions from 'constants/permissions';
import CreateEditNote from '../notes/CreateEditNote.js';
import ProfileDescription from './ProfileDescription.js';
import Loading from 'components/common/loading/Loading.js';
import AuthorizedRoute from 'components/home/AuthorizedRoute.js';
import NoteList from 'components/home/employees/components/notes/NoteList';

import { en } from 'constants/lang/en.js';
import * as routes from 'constants/routes';

import { buildUrl } from 'utils/string.js';
import { handleError } from 'utils/errorHandler.js';

import { useFetchLeaveBalance } from 'hooks/query/useFetchLeaveBalance.js';
import { useFetchFiscalYearByCountry } from 'hooks/query/useFetchFiscalYearByCountry.js';

const ProfileBody = props => {
  const { authUser, employee } = props;

  const [query, setQuery] = useQuery(props);

  const {
    value: { permissions: userPermissions }
  } = useSelector(state => state.information);

  const canView = userPermissions[permissions.NOTES.CREATE];

  const isSelf = authUser.id === employee.id;
  const canViewAssets = isSelf || userPermissions[permissions.EMPLOYEES.ASSETS.VIEW];

  const canApplyLeave = useMemo(
    () =>
      userPermissions[permissions.LEAVE.CREATE] ||
      authUser.id === employee.id ||
      (employee.leaveIssuer && authUser.id === employee.leaveIssuer.id),
    [authUser.id, employee.id, userPermissions]
  );

  const canViewLeaveBalance = Boolean(
    userPermissions[permissions.LEAVE.LEAVE_BALANCE.VIEW] || isSelf || authUser.id === employee.leaveIssuer?.id
  );

  const { data: fiscalYears } = useFetchFiscalYearByCountry(employee.country, canViewLeaveBalance);

  const currentFiscalYear = useMemo(() => {
    if (!fiscalYears) {
      return;
    }

    return fiscalYears.find(fiscalYear => fiscalYear.isCurrent);
  }, [fiscalYears]);

  const { data: leaveBalance, error, isFetched, isLoading } = useFetchLeaveBalance(
    {
      userId: employee.id,
      fiscalYearId: currentFiscalYear?.id
    },
    canViewLeaveBalance
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const navigationList = [
    {
      label: 'Summary',
      to: pinterpolate(routes.EMPLOYEE_PROFILE, { id: employee.id })
    },
    {
      label: 'Goals/Achievements',
      to: pinterpolate(routes.EMPLOYEE_PROFILE_GOALS, { id: employee.id })
    },
    // {
    //   label: 'Feedbacks',
    //   to: pinterpolate(routes.EMPLOYEE_PROFILE_FEEDBACKS, { id: employee.id })
    // },
    ...(leaveBalance?.length
      ? [
          {
            label: 'Leave Credits',
            visible: leaveBalance?.length !== 0,
            to: pinterpolate(routes.EMPLOYEE_PROFILE_LEAVE_CREDITS, { id: employee.id })
          }
        ]
      : []),
    ...(canView
      ? [
          {
            exact: false,
            label: 'Notes',
            to: pinterpolate(routes.EMPLOYEE_NOTE, { userId: employee.id })
          }
        ]
      : []),
    ...(canViewAssets
      ? [
          {
            label: 'Assets',
            to: pinterpolate(routes.EMPLOYEE_ASSETS, { id: employee.id })
          }
        ]
      : [])
  ];

  if ((!isFetched || isLoading) && canViewLeaveBalance) {
    return (
      <div className="container profile-body-container">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div id="profile-body-container">
        {navigationList.length > 1 && (
          <div className="profile-body-nav container mt-15">
            <SecondaryNav navItems={navigationList} color="primary" className="profile-body-nav__secondary">
              <a
                href={interpolate(buildUrl(config.pulseBaseUrl, config.endpoints.pulse.userFeed), {
                  id: employee.id
                })}
                className="profile-body-nav__pulse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={pulse} alt="pulse_logo" />
                <span>{en.PULSE}</span>
              </a>
            </SecondaryNav>
          </div>
        )}
        <Switch>
          <Route exact path={routes.EMPLOYEE_PROFILE} component={() => <ProfileDescription {...props} />} />
          <Route
            exact
            path={routes.EMPLOYEE_PROFILE_LEAVE_CREDITS}
            render={() => (
              <LeaveCredits
                data={leaveBalance}
                employeeId={employee.id}
                canApplyLeave={canApplyLeave}
                resignation={employee.resignation}
                fiscalYearId={currentFiscalYear?.id}
              />
            )}
          />
          <Route
            exact
            path={routes.EMPLOYEE_PROFILE_GOALS}
            render={() => <EmployeeGoals employeeId={employee.id} query={query} setQuery={setQuery} />}
          />
          <Route exact path={routes.EMPLOYEE_ASSETS} render={() => <EmployeeAssets employeeId={employee.id} />} />
          {/* <Route
              exact
              path={routes.EMPLOYEE_PROFILE_FEEDBACKS}
              render={() => <EmployeeFeedbacks employeeId={employee.id} query={query} setQuery={setQuery} />}
            /> */}
          {/* <AuthorizedRoute
              exact
              path={routes.EMPLOYEE_PROFILE_MY_TEAM}
              allowSelf={employee.id === authUser.id}
              component={EmployeeTeams}
              {...props}
            /> */}
          <AuthorizedRoute
            exact
            permission={[permissions.NOTES.VIEW]}
            path={routes.EMPLOYEE_NOTE}
            component={NoteList}
            {...props}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.NOTES.CREATE]}
            path={pinterpolate(routes.CREATE_EMPLOYEE_NOTE, { userId: employee.id })}
            component={props => <CreateEditNote {...props} />}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.NOTES.CREATE]}
            path={pinterpolate(routes.UPDATE_EMPLOYEE_NOTE, { userId: employee.id })}
            component={props => <CreateEditNote {...props} />}
          />
          <Redirect to={routes.ACTIVE_EMPLOYEES} />
        </Switch>
      </div>
    </div>
  );
};

export default ProfileBody;
