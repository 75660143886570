import moment from 'moment';
import Modal from 'react-modal';
import classNames from 'classnames';
import React, { useState, useEffect, useMemo } from 'react';

import DropDown from 'components/common/dropDown';

// Components
import Loading from 'components/common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';
import DatePicker from 'components/common/datepicker/DatePicker';

import { getRequiredErrorLabel } from 'utils/string';
import { combineArrayObjects, isObjectEmpty } from 'utils/object';
import { getFormattedDate, getMonthsLaterDate, sortByDate } from 'utils/date';
import { PROBATION, FIXED_TERM_CONTRACT, HIRED } from 'constants/employeeStatus';

import EmployeeStatusDetails from './EmployeeStatusDetails';

Modal.setAppElement('#root');

const AddEngagementStatusModal = props => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    isOpen,
    onClose,
    buttonLabel,
    setFieldTouched,
    engagementStatus,
    editIndex,
    isCreateForm
  } = props;

  const { joinDate } = values;

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [employeeStatusPayload, setEmployeeStatusPayload] = useState({});
  const [initialPayload, setInitialPayload] = useState({});

  useEffect(() => {
    setInitialPayload({ transitionDate: isCreateForm ? values.joinDate : getFormattedDate() });
  }, [values.joinDate, isCreateForm]);

  useEffect(() => {
    setEmployeeStatusPayload(employeeStatusPayload => {
      if (employeeStatusPayload.engagementStatus?.name !== FIXED_TERM_CONTRACT) {
        delete employeeStatusPayload.endDate;
      }

      if (![PROBATION, FIXED_TERM_CONTRACT].includes(employeeStatusPayload.engagementStatus?.name)) {
        delete employeeStatusPayload.empStatusDetails;
      }

      return {
        ...employeeStatusPayload,
        ...(employeeStatusPayload.engagementStatus?.name === FIXED_TERM_CONTRACT && {
          endDate: employeeStatusPayload.endDate || getMonthsLaterDate(employeeStatusPayload.transitionDate, 6)
        })
      };
    });
  }, [employeeStatusPayload.engagementStatus]);

  useEffect(() => {
    setEmployeeStatusPayload(isNaN(editIndex) ? initialPayload : values.empStatusHistory[editIndex]);
  }, [editIndex, values.empStatusHistory, initialPayload]);

  const engagementStatusOptions = useMemo(
    () => engagementStatus.map(status => ({ label: status.name, value: status })),
    [engagementStatus]
  );

  const getDefaultValue = () => {
    if (isObjectEmpty(employeeStatusPayload.engagementStatus)) {
      return null;
    }

    if (!employeeStatusPayload.engagementStatus) {
      return null;
    }

    return {
      label: employeeStatusPayload.engagementStatus.name,
      value: employeeStatusPayload.engagementStatus
    };
  };

  useEffect(() => {
    if (!touched.empStatusHistory) {
      return;
    }

    const { engagementStatus, empStatusDetails, transitionDate, endDate } = employeeStatusPayload;

    const errorArray = [];

    if (touched.empStatusHistory.engagementStatus && !engagementStatus) {
      errorArray.push({ engagementStatus: getRequiredErrorLabel('engagementStatus') });
    }

    if (touched.empStatusHistory.transitionDate && !transitionDate) {
      errorArray.push({ transitionDate: getRequiredErrorLabel('start date') });
    }

    if (
      touched.empStatusHistory.transitionDate &&
      engagementStatus?.name === HIRED &&
      moment(transitionDate).isAfter(joinDate)
    ) {
      errorArray.push({ transitionDate: 'Start date cannot be before join date' });
    }

    if (engagementStatus?.name === FIXED_TERM_CONTRACT) {
      errorArray.push({ endDate: touched.empStatusHistory.endDate && !endDate && getRequiredErrorLabel('end date') });
    }

    if ([PROBATION, FIXED_TERM_CONTRACT].includes(engagementStatus?.name)) {
      errorArray.push({
        empStatusDetails: empStatusDetails?.map((empStatusDetailsItem, index) => {
          return {
            category:
              Boolean(
                touched.empStatusHistory?.empStatusDetails &&
                  touched.empStatusHistory?.empStatusDetails[index]?.category &&
                  !empStatusDetailsItem?.category
              ) && getRequiredErrorLabel('category'),
            date:
              Boolean(
                touched.empStatusHistory?.empStatusDetails &&
                  touched.empStatusHistory?.empStatusDetails[index]?.date &&
                  !empStatusDetailsItem?.date
              ) && getRequiredErrorLabel('date')
          };
        })
      });
    }

    const finalError = combineArrayObjects(errorArray);

    setError(err => ({
      ...err,
      ...finalError
    }));
  }, [touched, employeeStatusPayload, joinDate]);

  const handleCancel = () => {
    setEmployeeStatusPayload(initialPayload);
    setError({});
    setFieldTouched('empStatusHistory', false);
    onClose();
  };

  const handleEngagementStatusSave = () => {
    const { engagementStatus, transitionDate, empStatusDetails, endDate } = employeeStatusPayload;

    const hasValidCategories =
      [PROBATION, FIXED_TERM_CONTRACT].includes(engagementStatus.name) &&
      !empStatusDetails?.every(({ category }) => category);

    const hasValidCategoryDates =
      [PROBATION, FIXED_TERM_CONTRACT].includes(engagementStatus.name) && !empStatusDetails?.every(({ date }) => date);

    const isValidEnagementStatusDate =
      (engagementStatus.name === FIXED_TERM_CONTRACT && !endDate) ||
      (engagementStatus.name === HIRED && moment(transitionDate).isAfter(joinDate));

    if (
      !engagementStatus ||
      !transitionDate ||
      hasValidCategories ||
      hasValidCategoryDates ||
      isValidEnagementStatusDate
    ) {
      handleBlur({
        target: {
          name: 'empStatusHistory.engagementStatus'
        }
      });
      handleBlur({
        target: {
          name: 'empStatusHistory.transitionDate'
        }
      });
      handleBlur({
        target: {
          name: 'empStatusHistory.endDate'
        }
      });

      empStatusDetails &&
        empStatusDetails.forEach((empStatusDetails, index) => {
          handleBlur({ target: { name: `empStatusHistory.empStatusDetails[${index}].category` } });
          handleBlur({ target: { name: `empStatusHistory.empStatusDetails[${index}].date` } });
        });

      return;
    }

    let value;

    if (!isNaN(editIndex)) {
      value = [...values.empStatusHistory];
      value[editIndex] = employeeStatusPayload;
    } else {
      value = [employeeStatusPayload, ...values.empStatusHistory];
    }

    sortByDate(value, 'transitionDate');

    setIsLoading(true);
    handleChange({
      target: {
        name: 'empStatusHistory',
        value
      }
    });
    setEmployeeStatusPayload(initialPayload);
    setFieldTouched('empStatusHistory', false);
    setIsLoading(false);
    onClose();
  };

  const isValidStartDate = date => {
    if (employeeStatusPayload.engagementStatus?.name === HIRED) {
      return !date.isSameOrBefore(values.joinDate, 'days');
    }

    return date.isBefore(values.joinDate);
  };

  return (
    <Modal
      isOpen={isOpen}
      className="lf-modal lf-modal--engagement-status"
      contentLabel="Example Modal"
      overlayClassName="lf-modal__overlay"
      bodyOpenClassName="overflow-hidden"
    >
      <div className="add-designation-modal">
        <p className="add-designation-modal__header">{buttonLabel === 'Add' ? 'Add' : 'Edit'} Employment Status</p>

        <div className="add-designation-modal__form">
          <div className="add-designation-modal__form__full-width">
            <div className="add-designation-modal__form__full-width__space">
              <InputWrapper label="Employment Status" error={error.engagementStatus} isMandatory>
                <DropDown
                  hasError={Boolean(error.engagementStatus)}
                  defaultValue={getDefaultValue()}
                  onDropDownChange={item => {
                    setEmployeeStatusPayload(payload => ({ ...payload, engagementStatus: item.value }));
                  }}
                  onBlur={() => handleBlur({ target: { name: 'empStatusHistory.engagementStatus' } })}
                  options={engagementStatusOptions}
                  isSearchable={true}
                  isDisabled={false}
                  className="employee-form__dropdown"
                />
              </InputWrapper>
            </div>
            <div className="add-designation-modal__form__full-width__space">
              <InputWrapper label="Start Date" error={error.transitionDate} isMandatory className="employee-form__date">
                <DatePicker
                  hasError={Boolean(error.transitionDate)}
                  date={employeeStatusPayload.transitionDate ? moment(employeeStatusPayload.transitionDate) : null}
                  onDateChange={date =>
                    setEmployeeStatusPayload(payload => ({
                      ...payload,
                      transitionDate: getFormattedDate(date),
                      ...(payload.engagementStatus.name === FIXED_TERM_CONTRACT && {
                        endDate: moment(getMonthsLaterDate(date, 6))
                      })
                    }))
                  }
                  onClose={() => handleBlur({ target: { name: 'empStatusHistory.transitionDate' } })}
                  isOutsideRange={isValidStartDate}
                />
              </InputWrapper>
            </div>
            {employeeStatusPayload?.engagementStatus?.name === FIXED_TERM_CONTRACT && (
              <div className="add-designation-modal__form__full-width__space">
                <InputWrapper label="End Date" error={error.endDate} isMandatory className="employee-form__date">
                  <DatePicker
                    hasError={Boolean(error.endDate)}
                    date={employeeStatusPayload.endDate ? moment(employeeStatusPayload.endDate) : null}
                    onDateChange={date =>
                      setEmployeeStatusPayload(payload => ({ ...payload, endDate: getFormattedDate(date) }))
                    }
                    isOutsideRange={date => date.isBefore(employeeStatusPayload.transitionDate)}
                    onClose={() => handleBlur({ target: { name: 'empStatusHistory.endDate' } })}
                  />
                </InputWrapper>
              </div>
            )}
          </div>
          {[PROBATION, FIXED_TERM_CONTRACT].includes(employeeStatusPayload?.engagementStatus?.name) && (
            <EmployeeStatusDetails
              employeeStatusPayload={employeeStatusPayload}
              setEmployeeStatusPayload={setEmployeeStatusPayload}
              values={values}
              handleBlur={handleBlur}
              error={error}
              touched={touched}
              editIndex={editIndex}
            />
          )}
        </div>
        <div className="add-designation-modal__bottom">
          <button
            className={classNames('btn btn--primary', { 'btn--loading': isLoading })}
            disabled={isLoading}
            onClick={handleEngagementStatusSave}
          >
            <span className="btn__label">{buttonLabel} &amp; Close</span>
            {isLoading && <Loading />}
          </button>
          <button className="btn btn--outlined-grey ml-md-24" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEngagementStatusModal;
