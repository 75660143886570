import React from 'react';
import classNames from 'classnames';

import { EMPLOYEE_PROFILE } from 'constants/routes';
import { getEmpImageUrl, interpolate } from 'utils/string';

import Avatar from './Avatar';

/**
 * @param  {Object} props
 * @param  {{empId:string, label:string, id:string | {{empId: number, id: number ....}}}} props
 * @returns {JSX}
 */
const AvatarLinkComponent = props => {
  if (!props) {
    return null;
  }

  const { empId, name, id, fontSize = 14, customClass, showName = true, size = 'small', href } = props;

  return (
    <a
      className="d-flex align-items-center"
      href={href || interpolate(EMPLOYEE_PROFILE, { id })}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => e.stopPropagation()}
    >
      <Avatar image={getEmpImageUrl(empId)} alt={name} size={size} className={classNames({ 'mr-8': showName })} />
      {showName && (
        <div className={classNames(`profile-link fs${fontSize} mr-8`, { [customClass]: customClass })}>{name}</div>
      )}
    </a>
  );
};

export default AvatarLinkComponent;
