import config from 'config';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getAllocationAppendLabel } from 'utils/user';
import { isDateInFuture, isDateToday } from 'utils/date';
import { shouldHighlightPotentialEndDate } from 'utils/allocation';
import { getEmpImageUrl, interpolate, startCase } from 'utils/string';

import Avatar from 'components/common/avatar';
import ChangeAppraiserModal from 'components/home/dashboard/components/ChangeAppraiserModal';
import AllocationTimePeriod from 'components/home/dashboard/components/AllocationTimePeriod';

import * as routes from 'constants/routes';
import { BILLING_BILLED } from 'constants/appConstants';
import { REQUEST_FOR_APPRAISERS_CHANGE } from 'constants/profile';

export default function ProjectAllocationList(props) {
  const { projectAllocation, className = '', childRef, isSelf, isTeamManager } = props;

  const {
    project,
    area,
    startDate,
    endDate,
    potentialEndDate,
    allocation,
    allocationType,
    billingStatus,
    role,
    appraiser,
    coAppraiser,
    id: allocationId
  } = projectAllocation;

  const isOffboardedToday = isDateToday(endDate);
  const isCurrentProject = isDateToday(endDate) || isDateInFuture(endDate) || !endDate;

  const [isAppraiserChangeModalOpen, setIsAppraiserChangeModalOpen] = useState(false);

  const hiddenBtn = () => {
    return <div style={{ display: 'hidden' }}></div>;
  };

  return (
    <li
      ref={childRef}
      className={classNames(`project ${className}`, {
        'project-current prpx-24': isCurrentProject
      })}
    >
      <span className="project__dot"></span>
      <div className="d-flex f-wrap mb-8">
        <div className="project-brief">
          <h4 className="project-name">
            {project?.name ? (
              <span
                className="cursor-pointer project-name__link"
                onClick={() =>
                  window.open(
                    interpolate(`${config.teamsAppURL}${config.endpoints.teams.app.project}`, {
                      id: project.id
                    })
                  )
                }
              >
                {project.name}
              </span>
            ) : (
              area?.name || startCase(allocationType)
            )}
          </h4>

          {isCurrentProject ? <div className="badge current-project">Current</div> : null}
        </div>

        {isCurrentProject && isSelf && !isOffboardedToday && (
          <>
            <ChangeAppraiserModal
              TriggerBtn={hiddenBtn}
              isOpen={isAppraiserChangeModalOpen}
              onClose={() => setIsAppraiserChangeModalOpen(false)}
              filteredAllocation={[{ name: project?.name || area?.name, id: allocationId }]}
            />
            <button
              aria-hidden="true"
              type="button"
              className="appraiser-change-btn ml-10 cursor-pointer"
              onClick={() => {
                setIsAppraiserChangeModalOpen(true);
              }}
            >
              {REQUEST_FOR_APPRAISERS_CHANGE}
            </button>
          </>
        )}
      </div>

      <div className="project-details mb-8">
        {role?.name && <div className="project-info">{role.name}</div>}
        <div className="project-info">{`${allocation} ${getAllocationAppendLabel(allocation)}`} Allocation</div>
        {billingStatus === BILLING_BILLED && <div className="project-info">{billingStatus}</div>}
      </div>
      <div className="project-details">
        <AllocationTimePeriod
          startDate={startDate}
          endDate={endDate}
          potentialEndDate={potentialEndDate}
          isTeamManager={isTeamManager}
          isSelf={isSelf}
          className="font-size-12 mb-8"
        />
      </div>
      {appraiser?.id ? (
        <>
          <div className="project-details mb-8">
            <div className="project-info">Appraiser:</div>
            <div className="project-appraiser">
              <Link
                className="d-flex align-items-center"
                to={interpolate(routes.EMPLOYEE_PROFILE, { id: appraiser.id })}
              >
                <Avatar
                  image={getEmpImageUrl(appraiser.empId)}
                  alt={appraiser.fullname}
                  size="small"
                  className="mr-8"
                />
                <div className="profile-link fs-12 mr-8">{appraiser.fullname}</div>
              </Link>
            </div>
          </div>
          {coAppraiser?.id ? (
            <div className="project-details mb-8">
              <div className="project-info">Co-appraiser:</div>
              <div className="project-appraiser">
                <Link
                  className="d-flex align-items-center"
                  to={interpolate(routes.EMPLOYEE_PROFILE, { id: coAppraiser.id })}
                >
                  <Avatar
                    image={getEmpImageUrl(coAppraiser.empId)}
                    alt={coAppraiser.fullname}
                    size="small"
                    className="mr-8"
                  />
                  <div className="profile-link fs-12 mr-8">{coAppraiser.fullname}</div>
                </Link>
              </div>
            </div>
          ) : (
            <div className="project-details mb-8">
              <div className="project-info">Co-appraiser:</div>
              <div className="project-appraiser project-appraiser__empty"></div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="project-details mb-8">
            <div className="project-info">Appraiser:</div>
            <div className="project-appraiser project-appraiser__empty"></div>
          </div>
          <div className="project-details mb-8">
            <div className="project-info">Co-appraiser:</div>
            <div className="project-appraiser project-appraiser__empty"></div>
          </div>
        </>
      )}
    </li>
  );
}
