import config from '../config';
import * as routes from 'constants/routes';
import * as permissions from './permissions';

export const getHeaderNavs = currentPath => {
  return [
    {
      label: 'People',
      link: config.vyaguta.people,
      isActive: (currentPath !== '/') & (currentPath !== '/noticeboard')
    },
    {
      label: 'Teams',
      link: config.vyaguta.teams
    },
    {
      label: 'Attendance',
      link: config.vyaguta.attendance
    },
    {
      label: 'JUMP',
      link: config.vyaguta.jump
    },
    {
      label: 'Honor',
      link: config.vyaguta.honor
    },
    {
      label: 'OKR',
      link: config.vyaguta.okr
    },
    {
      label: 'Pulse',
      link: config.vyaguta.pulse
    }
  ];
};

export const getSecondaryNav = () => {
  return [
    {
      label: 'Leapfroggers',
      to: routes.ACTIVE_EMPLOYEES,
      requiredAuthorization: false,
      exact: false
    },
    {
      label: 'People Finder',
      to: routes.RESOURCE_FINDER,
      allowFor: [permissions.EMPLOYEES.FINDER],
      exact: true
    },
    {
      label: 'Settings',
      to: routes.SETTINGS,
      allowFor: [permissions.SKILLS.VIEW, permissions.DESIGNATIONS.VIEW, permissions.USER_PERMISSIONS.VIEW],
      exact: false
    },
    {
      label: 'Events and Notices',
      to: routes.EVENTS_AND_NOTICES,
      allowFor: [
        permissions.REMINDERS.VIEW,
        permissions.EVENTS.VIEW,
        permissions.NOTICES.VIEW,
        permissions.HOLIDAYS.VIEW
      ],
      exact: false
    },
    {
      label: 'Notes Summary',
      to: routes.NOTE_SUMMARY,
      allowFor: [permissions.NOTES.VIEW],
      exact: false
    },
    {
      label: 'Leave Issuer Requests',
      to: routes.LEAVE_ISSUER_CHANGE_REQUESTS,
      allowFor: [permissions.LEAVE_ISSUER_CHANGE_REQUESTS.VIEW],
      exact: false
    }
  ];
};
