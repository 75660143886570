import qs from 'qs';
import history from './history';

/**
 * Parse query params from url string.
 *
 * @example
 * parse('foo=bar&goo=car&hoo=dar')
 * => {foo: 'bar', goo: 'car', hoo: 'dar'}
 *
 * @param {Object} value
 */
export function parse(value, options = {}) {
  return qs.parse(value.replace('?', ''), options);
}

/**
 * Stringify to url with query params.
 *
 * @example
 * stringify('link', { foo: 'bar', goo: 'car', hoo: 'dar' })
 * => 'link?foo=bar&goo=car&hoo=bar'
 *
 * @param {Object} route
 * @param {Object} params
 * @param {Object} [options={}] -options for qs stringify
 */

export function stringify(route, params, options = {}) {
  if (params === null || !(params instanceof Object) || Object.keys(params).length < 1) {
    return route;
  }

  return route + '?' + qs.stringify(params, options);
}

/**
 * Update url with query params.
 *
 * @param {object} props
 * @param {string} value
 */
export const updateUrl = (props, value) => {
  const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });
  history.push({
    pathname: props.location.pathname,
    search: queryParam
  });
};

export const parseQueryURL = (query, { convertToArray = false, typeConversion = false } = {}) => {
  if (!query) {
    return {};
  }

  return query
    .substring(1, query.length)
    .split('&')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      const convertedValue = value && value.replace(/%20/g, ' ');

      if (!convertToArray || !convertedValue) {
        return {
          ...acc,
          [key]: convertedValue
        };
      }

      if (!value.includes(',')) {
        return {
          ...acc,
          [key]: typeConversion ? Number(convertedValue) || convertedValue : convertedValue
        };
      }

      const valueInArray = convertedValue.split(',');

      if (!typeConversion) {
        return {
          ...acc,
          [key]: valueInArray
        };
      }

      const typeConverted = valueInArray.map(item => Number(item) || item);

      return {
        ...acc,
        [key]: typeConverted
      };
    }, {});
};

const getURLQuery = props => {
  return parseQueryURL(props.location.search);
};

const setURLQuery = (props, value) => {
  const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });

  props.history.push({
    search: queryParam
  });
};

export const useQuery = (props, initialState = {}) => {
  const query = getURLQuery(props) || initialState;

  const setQuery = query => setURLQuery(props, query);

  return [query, setQuery];
};
