import moment from 'moment';
import { Formik } from 'formik';
import classnames from 'classnames';
import React, { useState } from 'react';
import { HORIZONTAL_ORIENTATION } from 'react-dates';
import { FiAlertCircle, FiCalendar } from 'react-icons/fi';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import holidaySchema from 'schemas/holiday';

import * as holidayService from 'services/holidays';

import * as routes from 'constants/routes';
import { DEFAULT_DATE_FORMAT } from 'constants/date';
import { COUNTRIES, COUNTRIES_FORM_LIST } from 'constants/countries';
import { DEFAULT_HISTORY_ENTRIES, OCCASION_TYPE_IDS } from 'constants/appConstants';

import LFModal from 'components/common/LFModal';
import Checkbox from 'components/common/checkbox';
import Loading from 'components/common/loading/Loading';
import DatePicker from 'components/common/datepicker/DatePicker';
import DeleteButton from 'components/common/button/DeleteButton';

const initialValues = {
  name: '',
  description: '',
  date: moment(),
  countries: []
};

/**
 * Function to get initial values of selected countries.
 *
 * @param {Object|undefined} holiday
 * @returns {Object}
 */
function getInitialSelectedList(holiday) {
  const isEdit = holiday?.id;

  if (!isEdit) {
    return COUNTRIES_FORM_LIST.reduce((acc, curr) => {
      acc[curr] = false;

      return acc;
    }, {});
  }

  if (!holiday.countries) {
    return COUNTRIES_FORM_LIST.reduce((acc, curr) => {
      acc[curr] = true;

      return acc;
    }, {});
  }

  const { countries } = holiday;
  return COUNTRIES_FORM_LIST.reduce((acc, curr) => {
    acc[curr] = countries.includes(curr);

    return acc;
  }, {});
}

const HolidayForm = ({ holiday }) => {
  const [submitting, setSubmitting] = useState(false);

  const [selectedCountries, setSelectedCountries] = useState(getInitialSelectedList(holiday));

  const handleCancelClick = () =>
    history.length > DEFAULT_HISTORY_ENTRIES ? history.goBack() : history.push(routes.HOLIDAYS);

  const handleSubmit = async values => {
    const holidayPayload = {
      name: values.name?.trim(),
      description: values.description?.trim(),
      date: values.date.startOf('day').format(DEFAULT_DATE_FORMAT),
      type: OCCASION_TYPE_IDS.HOLIDAY,
      countries: values.countries?.join(',')
    };

    if (!values.countries || values.countries.length === COUNTRIES.length) {
      delete holidayPayload.countries;
    }

    try {
      setSubmitting(true);

      if (holiday) {
        await holidayService.update(holiday.id, holidayPayload);
      } else {
        await holidayService.create(holidayPayload);
      }

      toast.success({
        title: 'Success',
        message: holiday ? 'Holiday updated successfully.' : 'Holiday created successfully.'
      });

      history.push(routes.HOLIDAYS);
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleHolidayDelete = async () => {
    try {
      await holidayService.remove(holiday.id);
      toast.success({
        title: 'Success',
        message: 'Holiday deleted successfully.'
      });

      history.length > DEFAULT_HISTORY_ENTRIES ? history.goBack() : history.push(routes.HOLIDAYS);
    } catch (err) {
      handleError(err);
    }
  };

  const handleCountryChange = (event, formikValues, setFormikValue) => {
    const {
      target: { value, checked }
    } = event;

    if (value === 'All') {
      const allTrueCountries = COUNTRIES_FORM_LIST.reduce((acc, curr) => ({ ...acc, [curr]: checked }), {});

      setFormikValue('countries', checked ? COUNTRIES : []);
      setSelectedCountries(allTrueCountries);

      return;
    }

    const { countries: currentFormikCountries } = formikValues;

    const updatedValues = checked
      ? [...currentFormikCountries, value]
      : currentFormikCountries.filter(country => country !== value);

    setFormikValue('countries', updatedValues);

    setSelectedCountries(prev => {
      const newSelectedCountires = { ...prev, [value]: checked };

      const isAllSelected = Object.entries(newSelectedCountires).every(([key, value]) => {
        if (key === 'All') {
          return true;
        }

        return value;
      });

      return { ...newSelectedCountires, All: isAllSelected };
    });
  };

  return (
    <Formik
      initialValues={
        holiday ? { ...holiday, ...(!holiday.countries ? { countries: COUNTRIES } : {}) } : { ...initialValues }
      }
      validationSchema={holidaySchema}
      onSubmit={handleSubmit}
      render={({ handleChange, handleSubmit, touched, errors, values, handleBlur, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="holiday-form">
          <div className="full-scope-card__content">
            <div className="form-wrap">
              <div className="form-wrap__row form-wrap__row--no-margin clearfix">
                <div className="form-wrap__col col-sm-6 px-0">
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Title<span className="required ml-5">*</span>
                    </label>
                    <input
                      name="name"
                      type="text"
                      value={values.name}
                      placeholder="Provide a suitable title for the holiday"
                      className={classnames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.name && touched.name
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></input>
                    {errors.name && touched.name && <div className="error-block">{errors.name}</div>}
                  </div>

                  <div className="form-group">
                    <label className="form-label dark--text">
                      Description<span className="required ml-5">*</span>
                    </label>
                    <textarea
                      name="description"
                      type="text"
                      value={values.description}
                      placeholder="Provide a suitable description for the holiday"
                      className={classnames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.description && touched.description
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></textarea>
                    {errors.description && touched.description && (
                      <div className="error-block">{errors.description}</div>
                    )}
                  </div>

                  <div className="d-flex flex-direction-responsive">
                    <div className="form-group width--half">
                      <label className="form-label dark--text">
                        Date<span className="required ml-5">*</span>
                      </label>
                      <div className="form-elem--icon-pos">
                        <DatePicker
                          id="date"
                          displayFormat="LL"
                          customInputIcon={<FiCalendar size={18} className="color-grey-50" />}
                          date={values.date}
                          onDateChange={date => {
                            date = moment(date);
                            if (!date._isValid) {
                              return;
                            }

                            setFieldValue('date', date);
                          }}
                          numberOfMonths={1}
                          orientation={HORIZONTAL_ORIENTATION}
                          isOutsideRange={undefined}
                        />
                      </div>
                      {errors.date && touched.date && <div className="error-block">{errors.date}</div>}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label dark--text">
                      Country<span className="required ml-5">*</span>
                    </label>
                    <div className="form-elem--icon-pos">
                      <div className="pl-10">
                        <Checkbox
                          options={COUNTRIES_FORM_LIST.map(country => ({ label: country, value: country }))}
                          selectedOptions={selectedCountries}
                          onChange={e => handleCountryChange(e, values, setFieldValue)}
                        />
                      </div>
                      {errors.countries && touched.countries && <div className="error-block">{errors.countries}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar-footer action-bar-footer--bordered-top px-16">
              <button
                type="submit"
                className={classnames('btn btn--primary btn--curved mr-10', {
                  'btn--loading': submitting
                })}
                disabled={submitting}
              >
                <span className={submitting ? 'invisible' : ''}>Save</span>
                {submitting && <Loading />}
              </button>
              <button
                type="button"
                className={classnames('btn btn--outlined-grey btn--curved mr-10')}
                disabled={submitting}
                onClick={handleCancelClick}
              >
                Cancel
              </button>

              {holiday && (
                <LFModal
                  TriggerBtn={DeleteButton}
                  modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
                  title={{ text: 'Delete this holiday?', type: 'danger' }}
                  className="delete-modal"
                  message={{ text: "You won't be able to revert this!", type: 'delete__text' }}
                  renderFooter={true}
                  buttons={[
                    {
                      text: 'Confirm',
                      type: 'red',
                      className: 'mr-12 delete__btn',
                      onClick: handleHolidayDelete,
                      close: true,
                      showLoading: true
                    },
                    {
                      text: 'Cancel',
                      type: 'outlined-grey',
                      className: 'py-8 px-12',
                      close: true
                    }
                  ]}
                />
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default HolidayForm;
