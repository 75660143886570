import * as Yup from 'yup';
import { getRequiredErrorLabel } from 'utils/string';
import { WORKING_SHIFTS_OPTIONS } from 'constants/worklog';

const getOfficialInformationSchema = isCreateForm =>
  Yup.object({
    firstName: Yup.string()
      .matches(/^[a-zA-Z][\sa-zA-Z]{1,20}$/, 'Invalid Name')
      .required(getRequiredErrorLabel('first name'))
      .typeError(getRequiredErrorLabel('first name')),
    middleName: Yup.string()
      .matches(/^[a-zA-Z][\sa-zA-Z]{1,20}$/, 'Invalid Name')
      .nullable(),
    lastName: Yup.string()
      .matches(/^[a-zA-Z][\sa-zA-Z]{1,20}$/, 'Invalid Name')
      .required(getRequiredErrorLabel('last name'))
      .typeError(getRequiredErrorLabel('last name')),
    ...(!isCreateForm && {
      empId: Yup.number()
        .required(getRequiredErrorLabel('employee Id'))
        .typeError(getRequiredErrorLabel('employee Id'))
    }),
    joinDate: Yup.date()
      .required(getRequiredErrorLabel('join date'))
      .typeError('Invalid date'),
    recruiteeUrl: Yup.string()
      .trim()
      .test('urlValidation', 'Url is not valid', function(value) {
        const validatorRegX = /(www\.)?[-a-zA-Z0-9@:%_\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;

        return Boolean(value) ? validatorRegX.test(value) : true;
      })
      .nullable(),
    username: Yup.string()
      .email()
      .matches(/.*\.com$/, 'Invalid email address')
      .required(getRequiredErrorLabel('email address'))
      .typeError(getRequiredErrorLabel('email address')),
    departmentId: Yup.number()
      .required(getRequiredErrorLabel('department'))
      .typeError(getRequiredErrorLabel('department')),
    ...(isCreateForm
      ? {
          leaveIssuerId: Yup.number()
            .required(getRequiredErrorLabel('leave issuer'))
            .typeError(getRequiredErrorLabel('leave issuer'))
        }
      : {
          leaveIssuerId: Yup.number()
            .typeError(getRequiredErrorLabel('leave issuer'))
            .nullable()
        }),
    scheduledType: Yup.string()
      .oneOf(['PART_TIME', 'FULL_TIME'])
      .required(getRequiredErrorLabel('employee working type'))
      .typeError(getRequiredErrorLabel('employee working type')),
    workingShift: Yup.string()
      .oneOf(WORKING_SHIFTS_OPTIONS.map(({ value }) => value))
      .required(getRequiredErrorLabel('working shift'))
      .typeError(getRequiredErrorLabel('working shift')),
    availabilityStartTime: Yup.string()
      .required(getRequiredErrorLabel('availability time'))
      .typeError(getRequiredErrorLabel('availability time')),
    availabilityEndTime: Yup.string()
      .required(getRequiredErrorLabel('availability time'))
      .typeError(getRequiredErrorLabel('availability time')),
    ...(isCreateForm
      ? {
          teamManagerId: Yup.number()
            .required(getRequiredErrorLabel('team manager'))
            .typeError(getRequiredErrorLabel('team manager'))
        }
      : {
          teamManagerId: Yup.number()
            .typeError(getRequiredErrorLabel('team manager'))
            .nullable()
        }),
    roleIds: Yup.array()
      .of(Yup.number())
      .nullable(),
    pastExperienceYears: Yup.number()
      .min(0)
      .max(70)
      .nullable(),
    pastExperienceMonths: Yup.number()
      .min(0)
      .max(12)
      .nullable(),
    cvUrl: Yup.string()
      .trim()
      .test('urlValidation', 'Url is not valid', function(value) {
        const validatorRegX = /(www\.)?[-a-zA-Z0-9@:%_\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;

        return Boolean(value) ? validatorRegX.test(value) : true;
      })
      .nullable(),
    employeeImage: Yup.array()
      .of(Yup.object().notRequired())
      .max(1)
      .required(getRequiredErrorLabel('employee image'))
      .typeError(getRequiredErrorLabel('employee image'))
  });

const ProfileInfo = Yup.object({
  gender: Yup.string()
    .required(getRequiredErrorLabel('gender'))
    .typeError(getRequiredErrorLabel('gender')),
  dateofBirth: Yup.date()
    .required(getRequiredErrorLabel('date of birth'))
    .typeError('Invalid date of birth'),
  bloodGroup: Yup.string()
    .typeError(getRequiredErrorLabel('blood group'))
    .required(getRequiredErrorLabel('blood group')),
  maritialStatus: Yup.string()
    .typeError(getRequiredErrorLabel('marital status'))
    .required(getRequiredErrorLabel('marital status')),
  personalEmail: Yup.string()
    .email()
    .required(getRequiredErrorLabel('email'))
    .typeError('Invalid email'),
  mobilePhone: Yup.string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-./0-9]*$/, 'Invalid Phone Number')
    .typeError(getRequiredErrorLabel('phone number'))
    .required(getRequiredErrorLabel('phone number')),
  homePhone: Yup.string()
    .matches(/^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-./0-9]*$/, 'Invalid Phone Number')
    .nullable(),
  emergencyPhone: Yup.string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-./0-9]*$/, 'Invalid Phone Number')
    .required(getRequiredErrorLabel('emergency phone number'))
    .typeError('Invalid Phone Number'),
  emergencyContactRelationship: Yup.string()
    .required(getRequiredErrorLabel('relation with emergency contact'))
    .typeError(getRequiredErrorLabel('relation with emergency contact')),
  temporaryAddress: Yup.string()
    .required(getRequiredErrorLabel('temporary address'))
    .typeError(getRequiredErrorLabel('temporary address')),
  permanentAddress: Yup.string()
    .required(getRequiredErrorLabel('permanent address'))
    .typeError(getRequiredErrorLabel('permanent address')),
  country: Yup.string()
    .typeError(getRequiredErrorLabel('country'))
    .required(getRequiredErrorLabel('country')),
  timezone: Yup.string()
    .typeError(getRequiredErrorLabel('timezone'))
    .required(getRequiredErrorLabel('timezone')),
  githubId: Yup.string()
    .nullable()
    .matches(/^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/, 'Invalid username, only lower-case strings and dashes allowed.')
});

const leapfrogHistory = Yup.object({
  designationAreaHistory: Yup.array()
    .of(
      Yup.object().shape({
        transitionDate: Yup.date()
          .required(getRequiredErrorLabel('transition date'))
          .typeError(getRequiredErrorLabel('transition date')),
        area: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string()
          })
          .required(getRequiredErrorLabel('area'))
          .typeError(getRequiredErrorLabel('area')),
        designation: Yup.object()
          .shape({
            id: Yup.number().required(getRequiredErrorLabel('designation id')),
            name: Yup.string().required(getRequiredErrorLabel('designation name'))
          })
          .typeError(getRequiredErrorLabel('designation'))
      })
    )
    .min(1)
    .label('Designation and Area'),
  empStatusHistory: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        transitionDate: Yup.date().required(getRequiredErrorLabel('transition date')),
        engagementStatus: Yup.object().shape({
          id: Yup.number().required(getRequiredErrorLabel('status id')),
          name: Yup.string().required(getRequiredErrorLabel('status name'))
        })
      })
    )
    .min(1)
    .label('Engagement status')
});

const appraiserAllocation = Yup.object({
  appraiserId: Yup.number()
    .required(getRequiredErrorLabel('appraiser'))
    .typeError(getRequiredErrorLabel('appraiser')),
  coAppraiserId: Yup.number()
    .typeError('Invalid co-appraiser')
    .nullable()
});

export default { getOfficialInformationSchema, ProfileInfo, leapfrogHistory, appraiserAllocation };
