import React from 'react';
import classNames from 'classnames';

import { FiPlus } from 'react-icons/fi';

import SkillPill from './SkillPill';
import LastSkillParent from './LastSkillParent';

const SkillList = props => {
  const {
    skill,
    parentData,
    openAddSkillModal,
    openEditSkillModal,
    showAddSkills,
    selectedSkills,
    setIsOverlayOpenFor,
    isOpenFor,
    isSelf,
    isTeamManager
  } = props;
  const { name, subSkills } = skill;

  const mappedSkillRating = selectedSkills?.reduce((acc, curr) => {
    acc[curr.id] = { id: curr.id, rating: curr.rating, skillStatus: curr.skillStatus };

    return acc;
  }, {});

  const subSkillParentData = [...parentData, skill];

  let nestedChildrenLevel = 0;

  if (subSkills.length) {
    nestedChildrenLevel++;
  }

  if (subSkills?.some(item => item.subSkills.length)) {
    nestedChildrenLevel++;
  }

  const handlePillClick = (skill, parentData) => {
    setIsOverlayOpenFor && setIsOverlayOpenFor({ ...skill, parentData });
    showAddSkills && openEditSkillModal(skill, parentData);
  };

  return (
    <div className="skills-list__container">
      {nestedChildrenLevel === 2 && (
        <>
          <div className="first-skill-container">
            <div
              className={classNames('first-skill-container__header', {
                'first-skill-container__header--clickable': showAddSkills
              })}
              onClick={() => showAddSkills && openEditSkillModal(skill, parentData)}
            >
              {name}
            </div>

            {subSkills?.map(secondSkill => {
              return (
                <LastSkillParent
                  key={secondSkill.id}
                  skill={secondSkill}
                  parentData={subSkillParentData}
                  openAddSkillModal={openAddSkillModal}
                  openEditSkillModal={openEditSkillModal}
                  showAddSkills={showAddSkills}
                  mappedSkillRating={mappedSkillRating}
                  setIsOverlayOpenFor={setIsOverlayOpenFor}
                  isOpenFor={isOpenFor}
                  isSelf={isSelf}
                  isTeamManager={isTeamManager}
                />
              );
            })}
          </div>

          {showAddSkills && (
            <div className="first-skill-container__link">
              <FiPlus color="#29B6F6" size={14} />
              <button className="text" onClick={() => openAddSkillModal(subSkillParentData)}>
                Add skill under {skill.name}
              </button>
            </div>
          )}
        </>
      )}

      {nestedChildrenLevel === 1 && (
        <LastSkillParent
          skill={skill}
          parentData={parentData}
          openAddSkillModal={openAddSkillModal}
          openEditSkillModal={openEditSkillModal}
          showAddSkills={showAddSkills}
          mappedSkillRating={mappedSkillRating}
          setIsOverlayOpenFor={setIsOverlayOpenFor}
          isOpenFor={isOpenFor}
          isSelf={isSelf}
          isTeamManager={isTeamManager}
        />
      )}

      {nestedChildrenLevel === 0 && (
        <SkillPill
          skill={skill}
          onClick={() => handlePillClick(skill, parentData)}
          mappedSkillRating={mappedSkillRating}
          setIsOverlayOpenFor={setIsOverlayOpenFor}
          parentData={parentData}
          isOpenFor={isOpenFor}
          isSelf={isSelf}
          isTeamManager={isTeamManager}
        />
      )}
    </div>
  );
};

export default SkillList;
