import React from 'react';
import classNames from 'classnames';
import { getEmpImageUrl } from 'utils/string';

import Avatar from './Avatar';

/**
 * @param  {Object} options
 * @param  {{empId:string, label:string, value:Number|number | {{empId: number, id: number ....}}}} options
 * @returns {JSX}
 */
const AvatarComponent = options => {
  if (!options) {
    return null;
  }

  const { empId, label, value, customClassName, size = 'small' } = options;

  return (
    <div className="d-flex">
      <Avatar
        image={getEmpImageUrl(empId || value?.empId)}
        alt={label}
        size={size}
        className={classNames({ [customClassName]: customClassName })}
      />
      <div className="d-flex align-items-center ml-5">{label}</div>
    </div>
  );
};

export default AvatarComponent;
