import lodash from 'lodash';
import config from '../config';
import pinterpolate from 'pinterpolate';

/**
 * Build supplied string by interpolating properties after delimiter(':') with the given parameters.
 *
 * @example
 * interpolate('/posts/:id', {id: 1})
 * => '/posts/1'
 *
 * @param {string} str
 * @param {object} params
 * @returns {string}
 */
export const interpolate = (str, params) => pinterpolate(str, params);

/**
 * Extracts fullname from user object.
 *
 * @param {user} Object
 * @returns {String}
 */
export const extractFullName = ({ firstName, middleName, lastName }, includeMiddleName = false) =>
  `${firstName} ${includeMiddleName && middleName ? middleName + ' ' : ''}${lastName}`;

/**
 * Extracts month and day from date
 *
 * @param {Date} date
 */
export function stripYear(date) {
  return date.toDateString().slice(4, 10);
}

/**
 *
 * Parses query to usable object
 *
 * @param {String} query
 * @param {Boolean} hasArray
 */
export const parseQuery = (query, hasArray = true) => {
  return query
    .substring(1, query.length)
    .split('&')
    .reduce((acc, curr) => {
      let items = curr.split('=');
      items[1] = items[1] && items[1].replace(/%20/g, ' ');

      if (hasArray) {
        const keySize = items[0].length;

        if (keySize > 2 && items[0].substring(keySize - 2, keySize) === '[]') {
          const newKey = items[0].substring(0, keySize - 2);
          return {
            ...acc,
            [newKey]: acc[newKey] ? [...acc[newKey], items[1]] : [items[1]]
          };
        }
      }

      return items[0] && items[1]
        ? {
            ...acc,
            [items[0]]: decodeURIComponent(items[1])
          }
        : acc;
    }, {});
};

/**
 *
 * Unparses object to query
 *
 * @param {String} query
 */
export const unParseQuery = object => {
  return Object.keys(object)
    .reduce((acc, curr) => {
      if (Array.isArray(object[curr])) {
        return `${acc}${curr}[]=${object[curr].join(`&${curr}[]=`)}&`;
      }

      return curr && object[curr] ? `${acc}${curr}=${encodeURIComponent(object[curr])}&` : acc;
    }, '?')
    .slice(0, -1);
};

/**
 * Formats URL to be used in the browser.
 *
 * @param {String} url
 * @returns {String}
 */
export function formatURL(string) {
  return string.includes('://') ? string : '//' + string;
}

/**
 * Formats text to starting letter uppercase.
 *
 * @param {String} text
 * @returns {String}
 */
export function startCase(text) {
  return lodash.startCase(text.toLowerCase());
}

/**
 * Function to pluralize the given string with given suffix.
 * for eg: result ==> results (by default).
 *
 * @param {String} string
 * @param {Number} count
 * @param {String} suffix
 * @returns
 */
export const pluralize = (string, count = 2, suffix = 's') => (count >= 2 || count === 0 ? string + suffix : string);

/**
 * Returns random color
 *
 * @returns {Color}
 */
export function getRandomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

/* Truncate a string to num characters.
 *
 * @param {String} str
 * @param {Number} num
 */
export const truncate = (str, num) => str && (str.length > num ? str.slice(0, num) + ' ...' : str);

/**
 * @param {String} label
 * @returns {String}
 */ export const getRequiredErrorLabel = label => `Please provide ${label}`;

/**
 *
 * @param {String} label
 * @returns {String}
 */
export const getDuplicateErrorLabel = label => `${label} already exists`;

/**
 *
 * @returns Unique value on each call
 */
export const getUniqueKey = () => Math.floor(Math.random() * Date.now());

/**
 * Add zero to the left of the number if it is less than given place value for better formatting.
 * for eg: 12 ==> '12'  1 ==> '01'
 *
 * @param {Number} number
 * @param {Number} digitCount
 * @returns {String}
 */
export const padZero = (number = 0, requiredLength = 2) => {
  const currentLength = number.toString().length;

  const neededToPadLength = requiredLength - currentLength;

  return neededToPadLength > 0 ? `${'0'.repeat(neededToPadLength)}${number}` : number;
};

/**
 * Formats given time to 00 : 00 AM format.
 * for eg : (1,AM) is converted 01:00 AM
 *
 * @param {String} time
 * @param {String} amOrPM
 * @returns {String}
 */
export const formatTime = (time = '', amOrPM) => {
  const hours = +String(time).slice(0, 2) || 0;
  const mins = +String(time).slice(3, 5) || 0;

  return `${padZero(hours)}:${padZero(mins)} ${amOrPM}`;
};

/**
 * Employee Profile image url
 *
 * @param {string} empId
 * @returns {string}
 */

export const getEmpImageUrl = empId => interpolate(config.empImageURI || '', { empId });

/**
 *
 * @param {String} formattedTime
 * @returns {Object}
 */
export const getTime = formattedTime => {
  const type = formattedTime.slice(-2);
  const value = formattedTime.slice(0, 5);

  return { type, value };
};

export const underscoreToSpace = text =>
  text &&
  text
    .split('_')
    .join(' ')
    .toLowerCase();

/**
 *
 * @param {String} str
 * @returns
 */
export const isStringEmpty = str => {
  return !str || str.trimStart().length === 0;
};

/**
 *
 * @param {String} str
 * @param {number} minLength
 * @returns {Boolean}
 */
export const checkStringLength = (str, minLength) => {
  return str.trimStart().length < minLength;
};

/**
 * Capitalize a string.
 *
 * @param {string} str
 * @returns {string}
 */
export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Build a Url removing multiple slashes and trailing slash.
 *
 * @param {Array<string>} routes
 * @returns {string}
 *
 * @example
 * buildUrl('http://localhost:3000', 'api', 'v1', 'users', '1') // http://localhost:3000/api/v1/users/1
 * buildUrl('http://localhost:3000/', '//api/', '/v1/', '///users///', '//1////') // http://localhost:3000/api/v1/users/1
 *
 * buildUrl('google.com', 'searchText') // google.com/searchText
 * buildUrl('google.com', '/searchText') // google.com/searchText
 * buildUrl('google.com/', '/searchText') // google.com/searchText
 * buildUrl('//google.com/', '/searchText') // //google.com/searchText
 * buildUrl('https://google.com/', '/searchText') //  https://google.com/searchText
 * buildUrl('http://google.com/', '/searchText') // http://google.com/searchText
 * buildUrl("http://google.com/", "/searchText?redirectUrl=https://google.com"); // http://google.com/searchText?redirectUrl=https://google.com
 */
export const buildUrl = (...routes) => {
  return routes
    .join('/')
    .replace(/(https?:\/\/|^\/\/)|(\/)+/g, '$1$2') // remove double slashes
    .replace(/\/+$/, ''); // remove trailing slash
};

/**
 * Remove https://
 * @param {String} url
 * @returns {String}
 * @example withoutProtocolURL('https://github.com/leapfrogtechnology/vyaguta-okr/pull/96')
 * @return 'github.com/leapfrogtechnology/vyaguta-okr/pull/96'
 */
export const withoutProtocolURL = url => {
  if (!url) {
    return url;
  }

  const arr = url.split('//');

  if (arr.length === 1) {
    return url;
  }

  return url.split('//')[1];
};

/**
 * Remove https:// and www.
 * @param {String} url
 * @returns {String}
 * @example withoutProtocolAndSubDomainURL('https://www.google.com')
 * @return 'google.com'
 */
export const withoutProtocolAndSubDomainURL = url => {
  if (!url) return url;

  let withoutProtocol = url;

  if (url.startsWith('http://') || url.startsWith('https://')) {
    withoutProtocol = url.split('//')[1];
  }

  if (withoutProtocol.startsWith('www.')) {
    return withoutProtocol.slice(4);
  }

  return withoutProtocol;
};

/**
 * Converts an strings to a comma-separated array of string.
 * @param {String} value
 * @returns {String} splitString
 * @example stringToArray('1,2,3,4,5',',')
 * @return ['1','2','3','4','5']
 */
export const stringToArray = (value, splitString = ',', toNumber = false) => {
  if (toNumber) {
    return value?.split(splitString).map(Number);
  }

  return value?.split(splitString);
};

/**
 * Converts an array of strings to a comma-separated string.
 * @param {string[]} value
 * @returns {String} splitString
 * @example arrayToString(['1','2','3','4','5'])
 * @return '1,2,3,4,5'
 */
export const arrayToString = (value, joinString = ',') => {
  return value?.join(joinString);
};

/**
 * Returns the first and last name from a full name with middle name(s).
 * @param {string} fullName - The full name with middle name(s).
 * @returns {string} The first and last name.
 * @example
 * getFirstAndLastName("John Smith"); // returns "John Smith"
 * getFirstAndLastName("John A. Smith"); // returns "John Smith"
 * getFirstAndLastName("John Adam Smith"); // returns "John Smith"
 * getFirstAndLastName(""); // returns ""
 */
export const getFirstAndLastName = fullName => {
  if (!fullName) {
    return '';
  }

  const names = fullName.split(' ');

  if (names.length <= 2) {
    return fullName;
  }

  const firstName = names[0];
  const lastName = names[names.length - 1];

  return `${firstName} ${lastName}`;
};

/**
 * Returns the name with a possession suffix.
 *
 * @param {string} name
 * @returns {string}
 *
 * @example
 * getWithPossessionSuffix('John'); // returns "John's"
 * getWithPossessionSuffix('Johns'); // returns "Johns'"
 * getWithPossessionSuffix('John Smith'); // returns "John Smith's"
 * getWithPossessionSuffix('John Smiths'); // returns "John Smiths'"
 */
export const getWithPossessionSuffix = name => {
  if (name.endsWith('s')) {
    return `${name}'`;
  }

  return `${name}'s`;
};

/**
 * Returns the first from a full name
 * @param {string} fullName - The full name with middle name(s).
 * @returns {string} The first
 * @example
 * getFirstAndLastName("John Smith"); // returns "John"
 * getFirstAndLastName("John A. Smith"); // returns "John"
 * getFirstAndLastName("John Adam Smith"); // returns "John"
 * getFirstAndLastName(""); // returns ""
 */
export const getFirstName = fullName => {
  if (!fullName) {
    return '';
  }

  const names = fullName.split(' ');

  const firstName = names[0];

  return firstName;
};
