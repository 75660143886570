import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MdPhone } from 'react-icons/md';
import { FaSkype } from 'react-icons/fa';
import { RiPencilLine } from 'react-icons/ri';
import { FiMail, FiGithub, FiSmartphone, FiPaperclip } from 'react-icons/fi';

import pinterpolate from 'pinterpolate';

import { noImage } from 'assets/images';

import config from 'config';

import { EmployeeBasicDetail } from '.';

import { isObjectEmpty } from 'utils/object';
import { extractFullName } from 'utils/string';
import { toLinkComponent } from 'utils/component';

import ImageViewer from './ImageViewer';
import ActivationFormModal from './ActivationFormModal';
import CopyButton from 'components/common/copyButton/CopyButton';
import AuthenticatedImage from 'components/common/authenticatedImage';
import DropDownMenu from 'components/common/dropdownmenu/DropDownMenu';

import { EMPLOYEE_STATUS_STYLES, TERMINATED } from 'constants/employeeStatus';
import { GITHUB_PROFILE_URL, SKYPE_URL, EMAIL_URL, PHONE_URL } from 'constants/appConstants';
import {
  UPDATE_EMPLOYEE,
  UPDATE_LEAVE_ISSUER,
  UPDATE_EMPLOYEE_SKILLS,
  CHANGE_DESIGNATION,
  UPDATE_TEAM_MANAGER,
  CHANGE_ENGAGEMENT,
  CHANGE_TENTATIVE_EXIT
} from 'constants/routes';
import { EMPLOYEES, USER_PERMISSIONS } from 'constants/permissions';

const ProfileHeader = ({ employee, user, updateEmployee }) => {
  const { empProfileImageURI, empOriginalImageURI } = config;

  const [isViewingProfile, setViewingProfile] = useState(false);

  const {
    value: { permissions }
  } = useSelector(state => state.information);

  const { id } = employee || 1;

  const isSelf = user.id === employee.id;
  const isSuperAdmin = permissions[USER_PERMISSIONS.CREATE_SUPER_ADMIN];

  let links = [];

  if (isSelf || permissions[EMPLOYEES.PROFILE.UPDATE]) {
    links.push({
      to: pinterpolate(UPDATE_EMPLOYEE, { id }),
      label: 'Edit Profile',
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  if (isSelf || permissions[EMPLOYEES.SKILLS.UPDATE]) {
    links.push({
      to: pinterpolate(UPDATE_EMPLOYEE_SKILLS, { id }),
      label: 'Edit Skills',
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  if ((!isSelf || isSuperAdmin) && permissions[EMPLOYEES.ENGAGEMENTS.UPDATE]) {
    links.push({
      to: pinterpolate(CHANGE_ENGAGEMENT, { id }),
      label: 'Change Employment Status',
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  if ((!isSelf || isSuperAdmin) && permissions[EMPLOYEES.UPDATE_LEAVE_ISSUER]) {
    links.push({
      to: pinterpolate(UPDATE_LEAVE_ISSUER, { id }),
      label: 'Change Leave Issuer',
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  if ((!isSelf || isSuperAdmin) && permissions[EMPLOYEES.UPDATE_TEAM_MANAGER]) {
    links.push({
      to: pinterpolate(UPDATE_TEAM_MANAGER, { id }),
      label: 'Change Team Manager',
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  if ((!isSelf || isSuperAdmin) && permissions[EMPLOYEES.DESIGNATIONS.UPDATE]) {
    links.push({
      to: pinterpolate(CHANGE_DESIGNATION, { id }),
      label: 'Change Designation',
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  if ((!isSelf || isSuperAdmin) && permissions[EMPLOYEES.RESIGNATIONS.VIEW]) {
    links.push({
      to: pinterpolate(CHANGE_TENTATIVE_EXIT, { id }),
      label: `${employee?.resignation?.id ? 'Edit' : 'Add'} Tentative Exit Date`,
      className: 'order-1 font-11 font-bold momt-10'
    });
  }

  const getEmploymentInfo = employee => {
    const empStatus = employee.empStatus;
    const employeeStatusClass = EMPLOYEE_STATUS_STYLES[empStatus];

    return <div className={`emp-info ${employeeStatusClass}`}>{empStatus}</div>;
  };

  const getOriginalUrlFrom = empId => {
    return pinterpolate(empOriginalImageURI, { empId });
  };

  const getProfileUrlFrom = empId => {
    return pinterpolate(empProfileImageURI, { empId });
  };

  const fullName = employee ? extractFullName(employee, true) : '';

  const alternateNumber =
    employee.homePhone && employee.homePhone.trim()
      ? [
          {
            LogoComponent: MdPhone,
            DataComponent: () => (
              <>
                {employee.homePhone.split(',').map(phone =>
                  toLinkComponent(phone, PHONE_URL, {
                    phone: phone
                  })()
                )}
              </>
            )
          }
        ]
      : [];

  if (isObjectEmpty(employee)) {
    return null;
  }

  return (
    <>
      <ImageViewer
        visible={isViewingProfile}
        onClose={() => setViewingProfile(false)}
        image={{ src: getOriginalUrlFrom(employee.empId), alt: extractFullName(employee) }}
      />
      <div className="flex-fix d-flex container profile-container align-items-end fd-row">
        <div className="profile-header order--1 flex-fix d-flex fd-row w-80">
          {employee.empId ? (
            <AuthenticatedImage
              onClick={() => setViewingProfile(true)}
              className="profile-image"
              src={getProfileUrlFrom(employee.empId)}
              alt={fullName}
            >
              <img src={noImage} className="profile-image" alt={fullName} />
            </AuthenticatedImage>
          ) : (
            { noImage }
          )}
          <div className="profile-info-container">
            <div className="profile-info flex-fix d-flex fd-row align-items-center">
              <div className="font-24 font-bold mr-8">{fullName}</div>
              <span className="employee-id">{`#${employee.empId}`}</span>
              {getEmploymentInfo(employee)}
            </div>
            <span className="d-inline-block designation-name">
              {employee.designation && employee.designation.name}
              {employee.designation && employee.designation.area && `, ${employee.designation.area.name}`}
            </span>
            <EmployeeBasicDetail
              fieldMap={[
                {
                  LogoComponent: FiMail,
                  DataComponent: toLinkComponent(employee.email, EMAIL_URL, { email: employee.email }),
                  ExtraComponent: () => <CopyButton value={employee.email} />
                },
                {
                  LogoComponent: FiGithub,
                  DataComponent: toLinkComponent(
                    employee.githubId,
                    GITHUB_PROFILE_URL,
                    {
                      username: employee.githubId
                    },
                    true
                  )
                },
                {
                  LogoComponent: FaSkype,
                  DataComponent: toLinkComponent(
                    employee.skypeId,
                    SKYPE_URL,
                    {
                      username: employee.skypeId
                    },
                    true
                  )
                },
                {
                  LogoComponent: FiSmartphone,
                  DataComponent: toLinkComponent(employee.mobilePhone, PHONE_URL, {
                    phone: employee.mobilePhone
                  })
                },
                ...alternateNumber,
                {
                  LogoComponent: FiPaperclip,
                  DataComponent: employee.cvUrl && toLinkComponent('Resume', employee.cvUrl, {}, true)
                }
              ]}
            />
          </div>
        </div>
        {employee.empStatus === TERMINATED ? (
          permissions[EMPLOYEES.PROFILE.UPDATE] && (
            <ActivationFormModal employee={employee} afterSubmit={updateEmployee} />
          )
        ) : links.length ? (
          <DropDownMenu
            Icon={RiPencilLine}
            classNames="r-auto"
            iconColor="#999999"
            links={links}
            buttonName="Edit"
          ></DropDownMenu>
        ) : null}
      </div>
    </>
  );
};

export default ProfileHeader;
