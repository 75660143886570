import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { UiAngleRight } from 'vyaguta-icons/ui';

import Table from 'components/common/table';

const NoteAccordionTable = props => {
  const { columns, data, loading, subComponent, showPagination, emptyMessage, customClass } = props;

  const [expanded, setExpanded] = useState({});

  const getColumns = () => {
    return [
      {
        Header: '',
        sortable: false,
        accessor: 'arrow',
        maxWidth: 40,
        className: 'd-grid align-items-center justify-content-center text-overflow-unset',
        Cell: row => (
          <UiAngleRight
            size={20}
            className={classNames('list-accordion__icon', {
              'list-accordion__icon--down': expanded?.[row.viewIndex] || false,
              'list-accordion__icon--right': !expanded?.[row.viewIndex] || true
            })}
          />
        )
      }
    ];
  };

  const expandRow = row => {
    const oldExpanded = { ...expanded };

    if (oldExpanded[row.viewIndex]) {
      oldExpanded[row.viewIndex] = !oldExpanded[row.viewIndex];
    } else {
      oldExpanded[row.viewIndex] = true;
    }

    setExpanded(oldExpanded);
  };

  useEffect(() => {
    setExpanded({});
  }, [data]);

  return (
    <div className={`note-accordion-table ${customClass}`}>
      <Table
        columns={[...getColumns(), ...columns]}
        data={data}
        loading={loading}
        expanded={expanded}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              expandRow(rowInfo);
            }
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: classNames('list-accordion__row', {
              'list-accordion__row--expanded': expanded?.[rowInfo?.viewIndex] || false
            })
          };
        }}
        subComponent={subComponent}
        showPagination={showPagination}
        noDataText={emptyMessage}
      />
    </div>
  );
};

export default NoteAccordionTable;
