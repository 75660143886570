import React from 'react';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import initialFormData from '../initialFormData';

import { EMPLOYEE_PROFILE } from 'constants/routes';
import { DATE_FORMAT_DEFAULT } from 'constants/appConstants';
import { USER_UPDATABLE_FIELDS } from 'constants/updatableFields';
import { EMPLOYEES, USER_PERMISSIONS } from 'constants/permissions';

import * as toast from 'utils/toast';
import { interpolate } from 'utils/string';
import * as userService from 'services/user';
import { getFormattedDate } from 'utils/date';
import { extractFullName } from 'utils/string';
import { handleError } from 'utils/errorHandler';
import { withoutAttrs, withOnlyAttrs } from 'utils/object';

import * as roleService from 'services/role';
import * as departmentService from 'services/department';
import * as designationService from 'services/designation';
import * as designationAreaService from 'services/designationArea';
import * as engagementStatusService from 'services/engagementStatus';

import { filterAllowedRoles } from 'utils/user';
import Loading from '../../common/loading/Loading';
import EmployeeForm from '../employees/EmployeeForm';

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    window.document.title = 'Profile edit | Vyaguta';

    this.state = {
      employees: [],
      departments: [],
      designations: [],
      isLoading: true,
      designationAreas: [],
      engagementStatus: [],
      isSubmitting: false,
      selectedEmployee: null,
      formData: { ...initialFormData },
      roles: []
    };
  }

  componentDidMount() {
    this.fetchAndSetData();
  }

  fetchEmployee = async id => {
    return await userService.fetchById(id);
  };

  fetchAndSetData = async () => {
    try {
      const [designations, departments, designationAreas, engagementStatus, roles] = await Promise.all([
        this.fetchDesignations(),
        this.fetchDepartments(),
        this.fetchDesignationAreas(),
        this.fetchEngagementStatus(),
        this.fetchRoles()
      ]);

      const toShow = await this.fetchEmployee(this.props.empId);

      const { roles: userRoles = [] } = (await userService.fetchRoles(this.props.empId)) || {};

      window.document.title = this.getPageTitle(toShow);

      this.setState({
        selectedEmployee: toShow,
        formData: this.populateFormData({ ...toShow, roles: userRoles }),
        departments,
        designations,
        designationAreas,
        engagementStatus,
        roles: lodash.uniqBy([...filterAllowedRoles(roles, this.props.permissions), ...userRoles], 'id')
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  /**
   * Fetchs active employees.
   *
   * @returns {Array}
   */
  fetchActiveEmployees = async () => {
    const response = await userService.fetchAll({
      size: 1000
    });

    const { data } = response;

    return data;
  };

  /**
   * Fetchs departments.
   *
   * @returns {Array}
   */
  fetchDepartments = async () => {
    const response = await departmentService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   * Fetchs designations.
   *
   * @returns {Array}
   */
  fetchDesignations = async () => {
    const response = await designationService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   * Fetches Areas.
   *
   * @returns {Array}
   */
  fetchDesignationAreas = async () => {
    const response = await designationAreaService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   * Fetches Engagement status.
   *
   * @returns {Array}
   */
  fetchEngagementStatus = async () => {
    const response = await engagementStatusService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   *
   * @returns {Array} roles data
   */
  fetchRoles = async () => {
    const data = await roleService.fetchAll();

    return data;
  };

  findEmployeeById = (employees, id) => employees.find(emp => emp.id === id);

  populateFormData = employee => {
    let newFormData = { ...initialFormData };

    if (!employee) return newFormData;

    for (let key in newFormData) {
      if (key.substring(0, 2) === 'is') {
        newFormData[key] = employee[key] ? 1 : 0;
      } else {
        newFormData[key] = employee[key];
      }
    }

    let joinDate = employee['joinDate'];

    newFormData['joinDate'] = (joinDate && getFormattedDate(joinDate, DATE_FORMAT_DEFAULT)) || null;

    newFormData['supervisorId'] = (employee.supervisor && employee.supervisor.id) || null;

    newFormData['leaveIssuerId'] = (employee.leaveIssuer && employee.leaveIssuer.id) || null;

    newFormData['teamManagerId'] = (employee.teamManager && employee.teamManager.id) || null;

    newFormData['coachId'] = (employee.coach && employee.coach.id) || null;

    newFormData['departmentId'] = (employee.department && employee.department.id) || null;

    newFormData['username'] = employee['email'];

    newFormData['roleIds'] = employee.roles && employee.roles.map(item => item.id);

    if (employee.pastExperience) {
      newFormData['pastExperienceYears'] = Math.floor(employee?.pastExperience / 12);
      newFormData['pastExperienceMonths'] = employee?.pastExperience % 12;
    }

    newFormData['availabilityStartTime'] = {
      value: employee.availabilityTime?.startTime,
      type: employee.availabilityTime.startTimeType
    };

    newFormData['availabilityEndTime'] = {
      value: employee.availabilityTime?.endTime,
      type: employee.availabilityTime.endTimeType
    };

    newFormData['empStatusHistory'] = lodash.orderBy(
      employee.empStatusHistory,
      ['transitionDate', 'id'],
      ['desc', 'desc']
    );

    newFormData['designationAreaHistory'] = lodash.orderBy(
      employee.designationAreaHistory,
      ['transitionDate', 'id'],
      ['desc', 'desc']
    );

    if (employee.avatarUrl) {
      newFormData.employeeImage = [
        {
          data_url: employee.avatarUrl,
          file: {
            name: employee.avatarUrl
              .split('/')
              .slice(-1)[0]
              .split('?')[0]
          }
        }
      ];
    }

    return newFormData;
  };

  handleEmployeeChange = event => {
    const { employees } = this.state;

    const empId = event.target.value;

    const employee = this.findEmployeeById(employees, empId);

    this.setState({
      selectedEmployee: employee,
      formData: this.populateFormData(employee)
    });
  };

  handleUpdateClick = async formData => {
    this.setState({ isSubmitting: true });

    const { selectedEmployee, employees } = this.state;

    const updateFormData = withoutAttrs(formData, ['employeeImage']);

    const { employeeImage } = formData;

    const userJson = { ...updateFormData, id: selectedEmployee.id, middleName: updateFormData.middleName || null };

    try {
      let updatedUser = {};

      const { data } = await userService.update(userJson, userJson.id);

      updatedUser = { ...data };

      const userId = (data && data.id) || null;

      if (userId && employeeImage instanceof File) {
        let imageUploadFormData = new FormData();
        imageUploadFormData.append('employeeImage', employeeImage);

        const { data: imageUploadData } = await userService.upload(userId, imageUploadFormData);
        updatedUser.avatarUrl = imageUploadData.avatarUrl;
      }

      toast.success({
        title: 'Success',
        message: 'Profile has been updated successfully.'
      });

      this.setState({
        toPage: {
          pageUrl: EMPLOYEE_PROFILE,
          params: {
            id: userId
          }
        }
      });

      const newEmployees = employees.reduce((acc, cur) => {
        if (cur.id !== userJson.id) {
          return [...acc, cur];
        }

        return [...acc, updatedUser];
      }, []);

      this.setState({ employees: newEmployees });
    } catch (error) {
      handleError(error);
    }

    this.setState({ isSubmitting: false });
  };

  canEdit = key => {
    const isSelf = +this.props.empId === this.props.user.id;
    const isSuperAdmin = this.props.permissions[USER_PERMISSIONS.CREATE_SUPER_ADMIN];

    if (key === 'roleIds') {
      const allowedRoleIds = filterAllowedRoles(this.state.roles, this.props.permissions).reduce(
        (prevValue, currentValue) => ({
          ...prevValue,
          [currentValue.id]: currentValue.id
        }),
        {}
      );

      return isSuperAdmin || (!isSelf && this.state.formData.roleIds.every(roleId => allowedRoleIds[roleId]));
    }

    return isSelf && !isSuperAdmin
      ? USER_UPDATABLE_FIELDS.includes(key)
      : this.props.permissions[EMPLOYEES.PROFILE.UPDATE];
  };

  getPageTitle = selectedEmployee => {
    const employeeFullName = selectedEmployee ? extractFullName(selectedEmployee) : '';
    const pageTitle = employeeFullName ? `${employeeFullName}'s profile edit | Vyaguta` : 'Profile edit | Vyaguta';

    return pageTitle;
  };

  render() {
    const { isLoading, selectedEmployee, toPage } = this.state;

    if (toPage) {
      let togo = interpolate(toPage.pageUrl, toPage.params);
      return <Redirect to={togo} />;
    }

    return isLoading ? (
      <div className="container loading-container">
        <Loading />
      </div>
    ) : (
      <div>
        {selectedEmployee && (
          <EmployeeForm
            isSubmitting={this.state.isSubmitting}
            formData={this.state.formData}
            isCreateForm={false}
            canEdit={this.canEdit}
            handleSubmit={this.handleUpdateClick}
            departments={this.state.departments}
            designations={this.state.designations}
            designationAreas={this.state.designationAreas}
            engagementStatus={this.state.engagementStatus}
            roles={this.state.roles}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({ permissions: state.information.value.permissions });

export default connect(mapStateToProps)(UpdateForm);
