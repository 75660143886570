import { useSelector } from 'react-redux';
import React, { useState, useMemo } from 'react';
import { NavLink, Redirect, Switch } from 'react-router-dom';

import { Skills, SkillsV1 } from '../skills';
import AuthorizedRoute from '../AuthorizedRoute';
import UserPermissions from '../userPermissions';
import Designation from '../designation/Designation';
import AddEditDesignation from '../designation/pages/AddEditDesignation';

import { en } from 'constants/lang/en';
import * as permissions from 'constants/permissions';
import {
  CREATE_DESIGNATION,
  DESIGNATION,
  SKILLS,
  SKILLS_V1,
  UPDATE_DESIGNATION,
  USER_PERMISSION
} from 'constants/routes';

import { getDefaultPath } from 'utils/path';

import useModalState from 'hooks/useModalState';

const Settings = props => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const { user } = props;

  const {
    value: { permissions: userPermissions }
  } = useSelector(state => state.information);

  const {
    open: openGlobalRatingModal,
    setOpen: setOpenGlobalRatingModal,
    onClose: onGlobalRatingModalClose
  } = useModalState();

  const navBars = [
    // {
    //   label: 'Skills V1',
    //   path: SKILLS_V1,
    //   permission: permissions.SKILLS.VIEW
    // },
    {
      label: 'Skills',
      path: SKILLS,
      permission: permissions.SKILLS.VIEW
    },
    {
      label: 'Designation',
      path: DESIGNATION,
      permission: permissions.DESIGNATIONS.VIEW
    },
    {
      label: 'Role Management',
      path: USER_PERMISSION,
      permission: permissions.USER_PERMISSIONS.VIEW
    }
  ];

  const navBarsWithPermissions = useMemo(() => {
    return navBars.map(navBar => {
      return {
        ...navBar,
        hasPermission: userPermissions[navBar.permission]
      };
    });
  }, [navBars, userPermissions]);

  const hasCustomClass = !navBarsWithPermissions.hasPermission;

  const getActionBarNavs = navBars => {
    return (
      <div className="mtpx-30 f-wrap justify-content-between d-flex nav-card">
        <ul className="secondary-nav__wrap">
          {navBars.map(({ label, path, hasPermission }) => {
            if (!hasPermission) {
              return null;
            }

            return (
              <li key={path} className="secondary-nav__node">
                <NavLink
                  className="secondary-nav__link secondary-nav__link--primary clear-after"
                  activeClassName="active"
                  to={path}
                >
                  {label}
                </NavLink>
              </li>
            );
          })}
        </ul>

        <div>
          <AuthorizedRoute
            permission={[permissions.SKILLS.CREATE]}
            exact
            path={SKILLS}
            component={props => (
              <button className="button button--primary" onClick={() => setOpenGlobalRatingModal(true)}>
                {en.BUTTON.EDIT_GLOBAL_RATING}
              </button>
            )}
            user={user}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            permission={[permissions.SKILLS.CREATE]}
            exact
            path={SKILLS}
            component={props => (
              <button className="button button--secondary" onClick={() => setIsCategoryModalOpen(true)}>
                {en.BUTTON.ADD_CATEGORY}
              </button>
            )}
            user={user}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
        </div>
      </div>
    );
  };

  return (
    <main>
      <div className="container">
        {getActionBarNavs(navBarsWithPermissions)}
        <Switch>
          <AuthorizedRoute
            permission={[permissions.SKILLS.VIEW]}
            exact
            path={SKILLS_V1}
            component={SkillsV1}
            user={user}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            permission={[permissions.SKILLS.VIEW]}
            exact
            path={SKILLS}
            component={Skills}
            openGlobalRatingModal={openGlobalRatingModal}
            onGlobalRatingModalClose={onGlobalRatingModalClose}
            isCategoryModalOpen={isCategoryModalOpen}
            setIsCategoryModalOpen={setIsCategoryModalOpen}
            user={user}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            permission={[permissions.DESIGNATIONS.VIEW]}
            exact
            path={DESIGNATION}
            component={Designation}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            permission={[permissions.DESIGNATIONS.CREATE]}
            path={CREATE_DESIGNATION}
            component={AddEditDesignation}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.DESIGNATIONS.UPDATE]}
            path={UPDATE_DESIGNATION}
            component={AddEditDesignation}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            permission={[permissions.USER_PERMISSIONS.VIEW]}
            exact
            path={USER_PERMISSION}
            component={UserPermissions}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <Redirect to={getDefaultPath(navBarsWithPermissions)} />
        </Switch>
      </div>
    </main>
  );
};

export default Settings;
