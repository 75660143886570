import React, { useState, useEffect } from 'react';

import { ReleaseNote as ReleaseNoteModal } from '@vyaguta/vyaguta-ui';

import { releaseNotes } from 'constants/releaseNotes';
import { CORE_APP_VERSION } from 'constants/appConstants';

import { set, get } from 'utils/memory';

import config from 'config';

const ReleaseNote = () => {
  const savedAppVersion = get(CORE_APP_VERSION);

  const [showReleaseNotes, setShowReleaseNotes] = useState(false);
  const [hasViewedReleaseNotes, setHasViewedReleaseNotes] = useState(false);

  useEffect(() => {
    const hasViewed = savedAppVersion === config.appVersion;
    setHasViewedReleaseNotes(hasViewed);
  }, [savedAppVersion]);

  const handleShowReleaseNotesClick = () => {
    setShowReleaseNotes(true);
  };

  const handleClose = () => {
    setShowReleaseNotes(false);
    setHasViewedReleaseNotes(true);
    set(CORE_APP_VERSION, config.appVersion);
  };

  return (
    <ReleaseNoteModal
      isOpen={showReleaseNotes || !hasViewedReleaseNotes}
      onClose={handleClose}
      onOpen={handleShowReleaseNotesClick}
      currentVersion={config.appVersion}
      moduleTitle={config.appName}
      releaseNotes={releaseNotes}
      classes={{
        buttonClassName: 'release-button ml-25'
      }}
    />
  );
};

export default ReleaseNote;
