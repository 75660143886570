import React from 'react';
import classNames from 'classnames';

import { FiPlus } from 'react-icons/fi';

import Empty from 'components/common/empty/Empty';

import SkillList from './SkillList';

const SkillCategoryChild = props => {
  const {
    showAddSkills,
    openEditCategoryModal,
    openAddSkillModal,
    openEditSkillModal,
    selectedCategory,
    selectedSkills,
    skillListClass,
    setIsOverlayOpenFor,
    isOpenFor,
    isSelf,
    isTeamManager
  } = props;

  const parentData = [selectedCategory];

  return (
    <>
      <div className="skill-category-header">
        <div
          className={classNames('skill-category-header__text', {
            'skill-category-header__text--clickable': showAddSkills
          })}
          onClick={() => showAddSkills && openEditCategoryModal(selectedCategory)}
        >
          {selectedCategory.name}
        </div>
        {showAddSkills && (
          <button
            className="skill-category-header__btn d-flex align-items-center"
            onClick={() => openAddSkillModal([selectedCategory])}
          >
            <div className="mtpx-2 mrpx-6">
              <FiPlus color="#29B6F6" size={14} />
            </div>
            <div>Add Skill</div>
          </button>
        )}
      </div>
      <div className={classNames('skills-list', skillListClass)}>
        {!selectedCategory.subSkills?.length && (
          <div className="skills-list__empty-container">
            <Empty message="Added skills will show up here.">
              {showAddSkills && (
                <button
                  type="button"
                  className={classNames('btn btn--outlined-grey btn--curved mr-10')}
                  onClick={() => openAddSkillModal([selectedCategory])}
                >
                  Add Skill
                </button>
              )}
            </Empty>
          </div>
        )}

        {!!selectedCategory.subSkills.length &&
          selectedCategory.subSkills.map(firstSkill => {
            return (
              <SkillList
                key={firstSkill.id}
                skill={firstSkill}
                parentData={parentData}
                openAddSkillModal={openAddSkillModal}
                openEditSkillModal={openEditSkillModal}
                showAddSkills={showAddSkills}
                selectedSkills={selectedSkills}
                setIsOverlayOpenFor={setIsOverlayOpenFor}
                isOpenFor={isOpenFor}
                isSelf={isSelf}
                isTeamManager={isTeamManager}
              />
            );
          })}
      </div>
    </>
  );
};

export default SkillCategoryChild;
