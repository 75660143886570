export const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DAY_FORMAT = 'ddd';
export const TODAY = 'today';
export const TIMESHEET_DATE_FORMAT = 'ddd, MMM DD YYYY';
export const SUNDAY = 0;
export const SATURDAY = 6;
export const WEEKENDS_DAY_CODE = [SUNDAY, SATURDAY];
export const BUFFER_DAYS = 10;
export const H_MM = 'h:mma';
export const DDDD = 'dddd';
export const HH_MM_SS = 'HH:mm:ss';
export const H_MM_A = 'h:mm a';

export const MONTH_MAPPING = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
};

export const DAYS_MAPPING = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday'
};
