import React from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Forbidden } from '@vyaguta/vyaguta-ui';

import history from 'utils/history';
import { HOME } from 'constants/routes';
import Loading from 'components/common/loading/Loading';

const AuthorizedRoute = ({ component: Component, permission, allowSelf, customClassName, ...rest }) => {
  const {
    value: { permissions },
    loading: isPermissionLoading
  } = useSelector(state => state.information);

  if (isPermissionLoading) {
    return (
      <div className="container flash-card">
        <div className="full-scope-card loading__container">
          <Loading />
        </div>
      </div>
    );
  }

  const canView = permission.some(permission => permissions?.[permission]) || allowSelf;

  return (
    <Route
      {...rest}
      render={props => {
        return canView ? (
          <Component {...rest} {...props} />
        ) : (
          <Forbidden
            redirectHandler={() => history.push(HOME)}
            className={classNames('flash-card', { [customClassName]: customClassName })}
          />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
