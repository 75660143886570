import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchRoles as getRoles } from 'services/user';

import { handleError } from '../utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';

const initialState = {
  loading: false,
  status: STATUS.IDLE,
  value: []
};

export const fetchMyRole = createAsyncThunk('userRoles/fetchUserRoles', async userId => {
  try {
    const data = await getRoles(userId);

    const userRoles = data?.roles?.map(item => item.name) || [];

    return userRoles;
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

const myRolesSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMyRole.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          return { ...state, loading: true, status: STATUS.PENDING };
        }

        return state;
      })
      .addCase(fetchMyRole.fulfilled, (state, action) => {
        return {
          ...state,
          value: action.payload,
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchMyRole.rejected, state => {
        return { ...state, loading: false, status: STATUS.REJECTED };
      });
  }
});

export default myRolesSlice.reducer;
