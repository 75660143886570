import moment from 'moment';
import Modal from 'react-modal';
import classNames from 'classnames';
import React, { useState, useEffect, useMemo } from 'react';

import DropDown from 'components/common/dropDown';
import Loading from 'components/common/loading/Loading';
import DatePicker from 'components/common/datepicker/DatePicker';

import InputWrapper from 'components/common/inputWrapper';

import { isObjectEmpty } from 'utils/object';
import { getRequiredErrorLabel } from 'utils/string';
import { getFormattedDate, sortByDate } from 'utils/date';

Modal.setAppElement('#root');

const AddDesignationModal = props => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    isOpen,
    buttonLabel,
    setFieldTouched,
    designations,
    areas,
    editIndex,
    onClose,
    isCreateForm
  } = props;

  const [designationAreaPayload, setDesignationAreaPayload] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initialPayload, setInitialPayload] = useState({});

  useEffect(() => {
    setInitialPayload({ transitionDate: isCreateForm ? values.joinDate : getFormattedDate() });
  }, [values.joinDate, isCreateForm]);

  useEffect(() => {
    setDesignationAreaPayload(isNaN(+editIndex) ? initialPayload : values.designationAreaHistory[editIndex]);
  }, [editIndex, values.designationAreaHistory, initialPayload]);

  const areaOptions = useMemo(() => areas.map(area => ({ label: area.name, value: area })), [areas]);

  const designationOptions = useMemo(
    () => designations.map(designation => ({ label: designation.name, value: designation })),
    [designations]
  );

  const getDefaultValue = () => {
    return {
      designation: !isObjectEmpty(designationAreaPayload.designation)
        ? { label: designationAreaPayload.designation.name, value: designationAreaPayload.designation }
        : null,
      area: !isObjectEmpty(designationAreaPayload.area)
        ? { label: designationAreaPayload.area.name, value: designationAreaPayload.area }
        : null
    };
  };

  const [error, setError] = useState({});

  useEffect(() => {
    if (!touched.designationAreaHistory) {
      return;
    }

    const { designation, transitionDate, area } = designationAreaPayload;

    setError(err => ({
      ...err,
      designation: touched.designationAreaHistory.designation && !designation && getRequiredErrorLabel('designation'),
      area: touched.designationAreaHistory.area && !area && getRequiredErrorLabel('area'),
      transitionDate:
        touched.designationAreaHistory.transitionDate && !transitionDate && getRequiredErrorLabel('transition date')
    }));
  }, [touched, designationAreaPayload]);

  const handleCancel = () => {
    setDesignationAreaPayload(initialPayload);
    setError({});
    setFieldTouched('designationAreaHistory', false);
    onClose();
  };

  const handleDesignationSave = () => {
    const { designation, transitionDate, area } = designationAreaPayload;

    if (!designation || !transitionDate || !area) {
      handleBlur({
        target: {
          name: 'designationAreaHistory.designation'
        }
      });
      handleBlur({
        target: {
          name: 'designationAreaHistory.transitionDate'
        }
      });
      handleBlur({
        target: {
          name: 'designationAreaHistory.area'
        }
      });

      return;
    }

    let value;

    if (!isNaN(editIndex)) {
      value = [...values.designationAreaHistory];
      value[editIndex] = designationAreaPayload;
    } else {
      value = [designationAreaPayload, ...values.designationAreaHistory];
    }

    sortByDate(value, 'transitionDate');

    setIsLoading(true);
    handleChange({
      target: {
        name: 'designationAreaHistory',
        value
      }
    });
    setDesignationAreaPayload(initialPayload);
    setFieldTouched('designationAreaHistory', false);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="lf-modal"
      overlayClassName="lf-modal__overlay"
      bodyOpenClassName="overflow-hidden"
    >
      <div className="add-designation-modal">
        <p className="add-designation-modal__header">{buttonLabel === 'Add' ? 'Add New' : 'Edit'} Designation</p>
        <div className="add-designation-modal__form">
          <div className="add-designation-modal__form__full-width">
            <div className="add-designation-modal__form__full-width__space">
              <InputWrapper label="Designation" error={error.designation} isMandatory>
                <DropDown
                  hasError={Boolean(error.designation)}
                  defaultValue={getDefaultValue().designation}
                  onDropDownChange={item => {
                    setDesignationAreaPayload(payload => ({ ...payload, designation: item.value }));
                  }}
                  onBlur={() => handleBlur({ target: { name: 'designationAreaHistory.designation' } })}
                  options={designationOptions}
                  isSearchable={true}
                  isDisabled={false}
                  className="employee-form__dropdown"
                />
              </InputWrapper>
            </div>
            <div className="add-designation-modal__form__full-width__space">
              <InputWrapper label="Area" error={error.area} isMandatory>
                <DropDown
                  hasError={Boolean(error.area)}
                  isSearchable={true}
                  isDisabled={false}
                  defaultValue={getDefaultValue().area}
                  options={areaOptions}
                  onBlur={() => handleBlur({ target: { name: 'designationAreaHistory.area' } })}
                  onDropDownChange={item => setDesignationAreaPayload(payload => ({ ...payload, area: item.value }))}
                  className="employee-form__dropdown"
                />
              </InputWrapper>
            </div>
          </div>
          <div className="add-designation-modal__form__full-width">
            <div className="add-designation-modal__form__full-width__space">
              <InputWrapper
                label="Transition Date"
                error={error.transitionDate}
                isMandatory
                className="employee-form__date"
              >
                <DatePicker
                  hasError={Boolean(error.transitionDate)}
                  date={designationAreaPayload.transitionDate ? moment(designationAreaPayload.transitionDate) : null}
                  onDateChange={date =>
                    setDesignationAreaPayload(payload => ({ ...payload, transitionDate: getFormattedDate(date) }))
                  }
                  onClose={() => handleBlur({ target: { name: 'designationAreaHistory.transitionDate' } })}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
        <div className="add-designation-modal__bottom">
          <button
            className={classNames('btn btn--primary', { 'btn--loading': isLoading })}
            disabled={isLoading}
            onClick={handleDesignationSave}
          >
            <span className="btn__label">{buttonLabel} &amp; Close</span>
            {isLoading && <Loading />}
          </button>
          <button className="btn btn--outlined-grey ml-md-24" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddDesignationModal;
