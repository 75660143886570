import * as Yup from 'yup';
import MomentDateSchemaType from './momentdate';
import { getRequiredErrorLabel } from 'utils/string';
import { ENGAGEMENT_STATUS_IDS } from 'constants/employeeStatus';

const momentSchema = new MomentDateSchemaType();

const changeEngagementStatusSchema = ({ currentEngagementStatusId, currentTransitionDate }) => {
  return Yup.object().shape({
    engagementStatusId: Yup.number()
      .required(getRequiredErrorLabel('employment status'))
      .typeError(getRequiredErrorLabel('employment status'))
      .notOneOf([currentEngagementStatusId], "Employment status can't be same as current employment status"),
    transitionDate: momentSchema
      .typeError(getRequiredErrorLabel('transition date'))
      .required(getRequiredErrorLabel('transition date'))
      .min(currentTransitionDate, 'Transition date cannot be earlier than current transition date.'),
    endDate: momentSchema
      .when('engagementStatusId', {
        is: ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT,
        then: momentSchema
          .required(getRequiredErrorLabel('end date'))
          .typeError(getRequiredErrorLabel('end date'))
          .min(Yup.ref('transitionDate'), 'End date cannot be earlier than transition date.')
      })
      .nullable(),
    empStatusDetails: Yup.array()
      .when('engagementStatusId', {
        is: engagementStatusId =>
          engagementStatusId === ENGAGEMENT_STATUS_IDS.PROBATION ||
          engagementStatusId === ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT,
        then: Yup.array().of(
          Yup.object({
            category: Yup.string().required(getRequiredErrorLabel('category')),
            date: Yup.date()
              .typeError(getRequiredErrorLabel('date'))
              .required(getRequiredErrorLabel('date'))
          })
        )
      })
      .nullable()
  });
};

export const confirmEngagementStatusSchema = ({ mensurationLeaveRequired }) => {
  const shape = {
    discretionaryLeave: Yup.number()
      .required(getRequiredErrorLabel('discretionary leave'))
      .typeError(getRequiredErrorLabel('discretionary leave')),
    sickLeave: Yup.number()
      .required(getRequiredErrorLabel('sick leave'))
      .typeError(getRequiredErrorLabel('sick leave'))
  };

  if (mensurationLeaveRequired) {
    shape.mensurationLeave = Yup.number()
      .required(getRequiredErrorLabel('menstruation leave'))
      .typeError(getRequiredErrorLabel('menstruation leave'));
  }

  return Yup.object().shape(shape);
};

export default changeEngagementStatusSchema;
