import { HIRED, TERMINATED } from 'constants/employeeStatus';
import { CREATE_ROLE_PERMISSION_MAP } from 'constants/permissions';
import { ALLOCATION_APPEND_TEXT_PLURAL, ALLOCATION_APPEND_TEXT_SINGULAR } from 'constants/appConstants';

import { joinValues } from './object';
import { extractFullName } from './string';
import { getDateDifference, isDateInFuture } from './date';

/**
 * Get full name of user
 *
 * @param {Object} user
 * @returns {String}
 */
export const getFullName = user => `${user.firstName} ${user.middleName || ''} ${user.lastName}`;

/**
 * Merge item1 and item2 based on the comparator key value, considering the specified order.
 *
 * @param {Object} item1
 * @param {Object} item2
 * @param {Object.key} comparator
 * @param {Boolean} pushBySmallerValue
 * @returns {Object}
 */
export const pusher = (item1, item2, comparator = 'id', pushBySmallerValue = false) => {
  if (item1[comparator] > item2[comparator]) {
    return { ...item2, ...item1, [comparator]: pushBySmallerValue ? item2[comparator] : item1[comparator] };
  }

  if (item1[comparator] < item2[comparator]) {
    return { ...item1, ...item2, [comparator]: pushBySmallerValue ? item1[comparator] : item2[comparator] };
  }

  return { ...item1, ...item2 };
};

/**
 * Merge two arrays based on comparator provided.
 *
 * @param {Array<EngagementStatus>} item1
 * @param {Array<Designation>} item2
 * @param {Any<Object.key>} comparator
 * @returns {Array}
 */
export const mergeDesignationAndEngagementStatus = (item1 = [], item2 = [], comparator = 'id') => {
  let itemOneIndex = 0;
  let itemTwoIndex = 0;

  const final = [];

  while (itemOneIndex < item1.length - 1 && itemTwoIndex < item2.length - 1) {
    const isHired = item1[itemOneIndex]?.engagementStatus?.name === HIRED;
    final.push(pusher(item1[itemOneIndex], item2[itemTwoIndex], comparator, isHired));

    if (item1[itemOneIndex + 1][comparator] === item2[itemTwoIndex + 1][comparator]) {
      itemTwoIndex++;
      itemOneIndex++;
      continue;
    }

    if (item1[itemOneIndex + 1][comparator] < item2[itemTwoIndex + 1][comparator]) {
      itemOneIndex++;
      continue;
    }
    itemTwoIndex++;
  }

  if (itemOneIndex === item1.length - 1) {
    while (itemTwoIndex < item2.length) {
      const isHired = item1[itemOneIndex]?.engagementStatus?.name === HIRED;

      final.push(pusher(item1[itemOneIndex], item2[itemTwoIndex], comparator, isHired));
      itemTwoIndex++;
    }
  } else if (itemTwoIndex === item2.length - 1) {
    while (itemOneIndex < item1.length) {
      const isHired = item1[itemOneIndex]?.engagementStatus?.name === HIRED;

      final.push(pusher(item1[itemOneIndex], item2[itemTwoIndex], comparator, isHired));
      itemOneIndex++;
    }
  }

  return final;
};

export const filterAllowedRoles = (roles, userPermissions) => {
  const allowedRoles = roles.filter(item => {
    return userPermissions[CREATE_ROLE_PERMISSION_MAP[item.id]] || !CREATE_ROLE_PERMISSION_MAP[item.id];
  });

  return allowedRoles;
};

export const formatEmployeeAvailabilityTime = ({ startTime, startTimeType, endTime, endTimeType }) =>
  `${joinValues({ startTime, startTimeType })} - ${joinValues({ endTime, endTimeType })}`;

export const getAllocationAppendLabel = value => {
  const appendLabel = {
    singular: ALLOCATION_APPEND_TEXT_SINGULAR,
    plural: ALLOCATION_APPEND_TEXT_PLURAL
  };

  return value === 1 ? appendLabel.singular : appendLabel.plural;
};

export const formatNoteAuthorizedUsers = userOption => {
  return userOption.map(({ value, empId, label }) => ({
    id: value,
    empId,
    fullName: label
  }));
};

export const getEmployeeSelectOptions = employee => ({
  value: employee?.id || '',
  empId: employee?.empId || '',
  label: employee ? extractFullName(employee) : ''
});

export const isUpComingStatusTerminated = employeeStatusHistory => {
  const index = employeeStatusHistory.findIndex(({ transitionDate }) => !isDateInFuture(transitionDate));

  const currentStatus = employeeStatusHistory[index === -1 ? 0 : index];

  return employeeStatusHistory.some(
    ({ engagementStatus, transitionDate }) =>
      engagementStatus.name === TERMINATED && getDateDifference(currentStatus.transitionDate, transitionDate) >= 0
  );
};
