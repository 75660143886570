import React from 'react';
import { useSelector } from 'react-redux';

import config from 'config';

import HomeRouter from './HomeRouter';
import Header from 'components/common/layouts/header/Header';

import { UserContext } from 'components/App';
import Maintenance from 'components/common/maintenance/Maintenance';

const Dashboard = () => {
  const {
    value: { notices },
    loading: isNoticeCountLoading
  } = useSelector(state => state.information);

  const noticeCount = notices?.length;

  return (
    <UserContext.Consumer>
      {user => (
        <>
          <Header user={user} noticeCount={noticeCount} isNoticeCountLoading={isNoticeCountLoading} />
          {config.isMaintenanceMode ? (
            <Maintenance />
          ) : (
            <HomeRouter user={user} noticeCount={noticeCount} isNoticeCountLoading={isNoticeCountLoading} />
          )}
        </>
      )}
    </UserContext.Consumer>
  );
};

export default Dashboard;
