import classNames from 'classnames';
import pinterpolate from 'pinterpolate';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import history from 'utils/history';
import { isObjectEmpty } from 'utils/object';

import PopOver from 'components/common/popover';

import { mixPanelEvents } from 'constants/mixPanel';
import { CHANGE_ISSUER_REQUEST } from 'constants/routes';

import * as mixpanel from 'services/mixPanel';
import { fetchLeaveIssuerChangeRequestByUserId } from 'services/leaveIssuerChangeRequest';

const EmployeePageDetail = props => {
  const { id, fieldMap, employee } = props;
  const { user } = useSelector(state => state.information.value);

  const [leaveIssuerRequest, setLeaveIssuerRequest] = useState(null);
  const [isLeaveIssuerRequestLoading, setIsLeaveIssuerRequestLoading] = useState(false);

  const fetchIssuerByUserId = async () => {
    try {
      setIsLeaveIssuerRequestLoading(true);

      const { data } = await fetchLeaveIssuerChangeRequestByUserId(id);

      setLeaveIssuerRequest(data);
    } catch (error) {
    } finally {
      setIsLeaveIssuerRequestLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchIssuerByUserId();
  }, []);

  const handleRequestChangeClick = () => {
    const { button: requestChange } = mixPanelEvents.select.leaveIssuerChangeRequest;

    history.push(pinterpolate(CHANGE_ISSUER_REQUEST, { id }), { leaveIssuerRequest, employee });

    mixpanel.trackEvent(requestChange);
  };

  return fieldMap.map(({ label, DataComponent, props, isUnAuthorizedUser }, index) => {
    if (isUnAuthorizedUser) {
      return null;
    }

    const shouldShowRequestChangeButton =
      label === 'Leave Issuer' && !isLeaveIssuerRequestLoading && leaveIssuerRequest && user.id !== id;

    return (
      DataComponent && (
        <div
          className={classNames('d-flex fd-row align-items-base flex-fix info-item', {
            'mt-15': label != 'Skill set',
            'skill-set-v2': label === 'Skill set'
          })}
          key={index}
        >
          <div className="emp-detail-name">{label}</div>
          <span className="capital-text">
            <DataComponent {...props} />
          </span>
          {shouldShowRequestChangeButton && (
            <PopOver
              visible={!isObjectEmpty(leaveIssuerRequest)}
              interactive
              useContext
              html={
                <div className="tooltip-info">
                  <span className="tooltip-info__title">
                    <b>{leaveIssuerRequest?.requestedBy?.fullname}</b> requested to change the leave issuer to{' '}
                    <b>{leaveIssuerRequest?.newLeaveIssuer?.fullname}</b>.{' '}
                    <Link
                      className="tooltip-info__link"
                      to={{
                        pathname: pinterpolate(CHANGE_ISSUER_REQUEST, { id }),
                        state: { leaveIssuerRequest, employee }
                      }}
                      onClick={() => {
                        const { linkFromProfile } = mixPanelEvents.select.leaveIssuerChangeRequest;

                        mixpanel.trackEvent(linkFromProfile);
                      }}
                    >
                      Click to view
                    </Link>
                    .
                  </span>
                </div>
              }
            >
              <button
                disabled={!isObjectEmpty(leaveIssuerRequest)}
                onClick={handleRequestChangeClick}
                className="btn btn--outlined-grey change-issuer-request__btn ml-10 cursor-pointer"
              >
                {isObjectEmpty(leaveIssuerRequest) ? 'Request for change' : 'Change requested'}
              </button>
            </PopOver>
          )}
        </div>
      )
    );
  });
};

export default EmployeePageDetail;
