import React from 'react';
import classNames from 'classnames';

import { FiPlusCircle } from 'react-icons/fi';

import SkillPill from './SkillPill';

const LastSkillParent = props => {
  const {
    skill,
    parentData,
    openAddSkillModal,
    openEditSkillModal,
    showAddSkills,
    mappedSkillRating,
    setIsOverlayOpenFor,
    isOpenFor,
    isSelf,
    isTeamManager
  } = props;
  const { name, subSkills, totalSkills } = skill;

  const subSkillParentData = [...parentData, skill];

  const handlePillClick = (skill, parentData) => {
    if (!mappedSkillRating) {
      setIsOverlayOpenFor &&
        ((isSelf && skill.skillStatus) || isTeamManager) &&
        setIsOverlayOpenFor({ ...skill, parentData });
    }

    showAddSkills && openEditSkillModal(skill, parentData);
  };

  if (subSkills.length === 0) {
    return (
      <SkillPill
        skill={skill}
        onClick={() => handlePillClick(skill, parentData)}
        mappedSkillRating={mappedSkillRating}
        setIsOverlayOpenFor={setIsOverlayOpenFor}
        isOpenFor={isOpenFor}
        isSelf={isSelf}
        isTeamManager={isTeamManager}
      />
    );
  }

  return (
    <div className="last-skill-parent">
      <div className="last-skill-parent__left-container">
        <div
          className={classNames('name', {
            'name--clickable': showAddSkills
          })}
          onClick={() => showAddSkills && openEditSkillModal(skill, parentData)}
        >
          {name}
        </div>
        <div className="count">
          {subSkills.length}
          {totalSkills && `/${totalSkills}`} skills
        </div>
      </div>

      <div className="last-skill-parent__right-container">
        {subSkills?.map(lastSkill => {
          return (
            <SkillPill
              key={lastSkill.id}
              skill={lastSkill}
              onClick={() => handlePillClick(lastSkill, subSkillParentData)}
              mappedSkillRating={mappedSkillRating}
              setIsOverlayOpenFor={setIsOverlayOpenFor}
              isOpenFor={isOpenFor}
              isSelf={isSelf}
              isTeamManager={isTeamManager}
            />
          );
        })}

        <div className="add-skill">
          {showAddSkills && (
            <button onClick={() => openAddSkillModal(subSkillParentData)}>
              <FiPlusCircle className="add-skill__icon" color="#29B6F6" size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LastSkillParent;
