import React from 'react';

// Components

import FormWrapper from './components/employeeForm/forms/formWrapper';

// Constants

const EmployeeForm = props => {
  const {
    formData,
    isCreateForm,
    employees,
    designations,
    departments,
    designationAreas,
    engagementStatus,
    skills,
    canEdit,
    roles
  } = props;

  const handleSubmit = values => props.handleSubmit(values);

  return (
    <FormWrapper
      initialFormData={formData}
      isSubmitting={props.isSubmitting}
      isCreateForm={isCreateForm}
      canEdit={canEdit}
      employees={employees}
      onSubmit={handleSubmit}
      designations={designations}
      departments={departments}
      designationAreas={designationAreas}
      engagementStatus={engagementStatus}
      skills={skills}
      roles={roles}
    />
  );
};

export default EmployeeForm;
