export const PROBATION = 'Probation';
export const PERMANENT = 'Permanent';
export const INTERN = 'Intern';
export const TRAINEE = 'Trainee';
export const TERMINATED = 'Terminated';
export const CONSULTANT = 'Consultant';
export const FIXED_TERM_CONTRACT = 'Fixed-term Contract';
export const HIRED = 'Hired';
export const SUB_CONTRACT = 'Sub-contract';

export const ENGAGEMENT_STATUS_IDS = {
  PROBATION: 4,
  FIXED_TERM_CONTRACT: 1
};

export const EMPLOYEE_STATUS_STYLES = {
  [FIXED_TERM_CONTRACT]: 'bg-color-pink',
  [INTERN]: 'bg-color-purple',
  [PERMANENT]: 'bg-color-green',
  [PROBATION]: 'bg-color-yellow',
  [TERMINATED]: 'bg-color-red',
  [TRAINEE]: 'bg-color-orange',
  [CONSULTANT]: 'bg-color-blue',
  [SUB_CONTRACT]: 'bg-color-gray',
  [HIRED]: 'bg-color-blue'
};

export const EMPLOYEE_STATUSES = [PROBATION, PERMANENT, INTERN, TRAINEE, TERMINATED, CONSULTANT];
