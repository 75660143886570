import moment from 'moment';
import { Formik } from 'formik';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import { FiCheck, FiX } from 'vyaguta-icons/fi';
import { UiDownloadAlt } from 'vyaguta-icons/ui';
import React, { useEffect, useMemo, useState } from 'react';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';
import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import FilterPills from 'components/common/filterPills';
import { CustomCheckbox } from 'components/common/checkbox';
import TableHeader from 'components/common/table/TableHeader';
import SmallDatePicker from 'components/common/datepicker/SmallDatePicker';
import AvatarLinkComponent from 'components/common/avatar/AvatarLinkComponent';

import * as toast from 'utils/toast';
import { hasKey } from 'utils/object';
import { downloadCSV } from 'utils/csv';
import { parse } from 'utils/queryParam';
import { handleError } from 'utils/errorHandler';
import { interpolate, startCase } from 'utils/string';
import { getFormattedDate, getMonthsFirstDate } from 'utils/date';

import useQuery from 'hooks/useQuery';
import useTitle from 'hooks/useTitle';

import {
  LEAVE_ISSUER_CHANGE_STATUS,
  statusLabels,
  requestPillStatus,
  requestPillStatusColor
} from 'constants/changeLeaveIssuer';
import { EMPLOYEE_PROFILE } from 'constants/routes';
import { mixPanelEvents } from 'constants/mixPanel';
import { DISPLAY_DATE_FORMAT } from 'constants/date';
import { SMALL_DATEPICKER_DATE_FORMAT } from 'constants/appConstants';
import { LEAVE_ISSUER_CHANGE_REASON_LENGTH, LEAVE_ISSUER_CHANGE_USER_NAME_LENGTH } from 'constants/string';

import * as mixpanel from 'services/mixPanel';
import * as leaveIssuerChangeRequestService from 'services/leaveIssuerChangeRequest';

import { rejectChangeRequestSchema } from 'schemas/changeIssuerSchema';

const ChangeLeaveIssuerList = props => {
  useTitle('Leave Issuer Change Requests');

  const [query, setQuery] = useQuery(props);

  const [isLoading, setIsLoading] = useState(true);
  const [leaveIssuerChangeRequestList, setLeaveIssuerChangeRequestList] = useState([]);

  const [isAscendingOrdered, setIsAscendingOrdered] = useState(false);
  const [columnToSortBy, setColumnToSortBy] = useState('dateOfRequest');

  const [requestSummary, setRequestSummary] = useState({});

  const [dataToShow, setDataToShow] = useState([]);

  const [selectedRequestIds, setSelectedRequestIds] = useState([]);
  const [isBulkApproving, setIsBulkApproving] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const [rowData, setRowData] = useState({});

  const [isRejectingRequest, setIsRejectingRequest] = useState(false);

  const [isExporting, setIsExporting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryParams = parse(props.location.search);

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [filters, setFilters] = useState({
    ...queryParams,
    startDate: query.startDate || getMonthsFirstDate(),
    endDate: query.endDate || getFormattedDate()
  });

  const defaultFilters = {
    startDate: getMonthsFirstDate(),
    endDate: getFormattedDate()
  };

  const isStartDateChanged = getFormattedDate(filters.startDate) !== getFormattedDate(defaultFilters.startDate);
  const isEndDateChanged = getFormattedDate(filters.endDate) !== getFormattedDate(defaultFilters.endDate);

  useEffect(() => {
    const { list: viewChangeRequestList } = mixPanelEvents.view.leaveIssuerChangeRequest;

    mixpanel.trackEvent(viewChangeRequestList);
  }, []);

  const handleReset = () => {
    const { resetButton } = mixPanelEvents.select.leaveIssuerChangeRequest.list;

    if (!isFilterApplied) {
      return;
    }

    setIsFilterApplied(false);

    setFilters({
      status: requestPillStatus.PENDING,
      ...defaultFilters
    });

    applyFilter({
      status: requestPillStatus.PENDING,
      ...defaultFilters
    });

    mixpanel.trackEvent(resetButton);
  };

  const applyFilter = filter => {
    setQuery({ ...query, ...filter });
  };

  const handleApplyFilter = () => {
    const { applyFilterButton } = mixPanelEvents.select.leaveIssuerChangeRequest.list;

    if (!isFilterChanged) {
      return;
    }

    applyFilter(filters);
    setIsFilterChanged(false);
    setIsFilterApplied(true);

    mixpanel.trackEvent(applyFilterButton);
  };

  const handleExportClick = async () => {
    try {
      setIsExporting(true);

      const { exportCSVButton } = mixPanelEvents.select.leaveIssuerChangeRequest.list;

      const { data: blob } = await leaveIssuerChangeRequestService.exportAll(query);

      const fileName = `${
        query.status ? startCase(query.status) + ' ' : ''
      }Leave Issuer Change Requests (${getFormattedDate(query.startDate, DISPLAY_DATE_FORMAT)} - ${getFormattedDate(
        query.endDate,
        DISPLAY_DATE_FORMAT
      )}).csv`;

      downloadCSV(blob, fileName);

      mixpanel.trackEvent(exportCSVButton);
    } catch (error) {
      handleError(error);
    } finally {
      setIsExporting(false);
    }
  };

  const handleRejectClick = async values => {
    const user = rowData.user;
    const status = LEAVE_ISSUER_CHANGE_STATUS.REJECTED;
    const rejectRequestByPeopleManagement = mixPanelEvents.reject.leaveIssuerChangeRequest.byPeopleManagement;

    try {
      setIsRejectingRequest(true);

      await leaveIssuerChangeRequestService.updateLeaveIssuerChangeRequest(user.id, {
        status,
        comment: values.rejectionReason
      });

      toast.success({
        title: 'Success',
        message: `Leave issuer change request of ${user.fullname} has been rejected.`
      });

      mixpanel.trackEvent(rejectRequestByPeopleManagement);

      fetchLeaveIssuerChangeRequestList();
    } catch (error) {
      handleError(error);
    } finally {
      setIsRejectingRequest(false);
      setIsModalOpen(false);
    }
  };

  const handleApproveClick = async (user, status, newLeaveIssuer, setIsApprovingRequest) => {
    try {
      setIsApprovingRequest(true);

      const approveRequestByPeopleManagement = mixPanelEvents.approve.leaveIssuerChangeRequest.byPeopleManagement;

      await leaveIssuerChangeRequestService.updateLeaveIssuerChangeRequest(user.id, { status });

      toast.success({
        title: 'Success',
        message: `Leave issuer of ${user.fullname} has been updated to ${newLeaveIssuer.fullname}.`
      });

      mixpanel.trackEvent(approveRequestByPeopleManagement);

      fetchLeaveIssuerChangeRequestList();
    } catch (error) {
      handleError(error);
    } finally {
      setIsApprovingRequest(false);
    }
  };

  const handleBulkApproveClick = async () => {
    try {
      setIsBulkApproving(true);

      const { bulkByPeopleManagement: bulkApproveByPeopleManagement } = mixPanelEvents.approve.leaveIssuerChangeRequest;

      const body = {
        requestIds: selectedRequestIds,
        status: LEAVE_ISSUER_CHANGE_STATUS.APPROVED
      };

      const {
        data: { requestIds: updatedIds }
      } = await leaveIssuerChangeRequestService.bulkUpdateLeaveIssuerChangeRequest(body);

      if (!updatedIds.length) {
        toast.error({
          title: 'Error',
          message: 'Cannot approve leave issuer change request for self.'
        });
      } else {
        toast.success({
          title: 'Success',
          message: 'Leave issuer change requests have been approved.'
        });

        mixpanel.trackEvent(bulkApproveByPeopleManagement);

        fetchLeaveIssuerChangeRequestList();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsBulkApproving(false);
    }
  };

  const getDataToShow = (data, status) => {
    if (!status) {
      const { all: selectAllPills } = mixPanelEvents.select.leaveIssuerChangeRequest.list.pills;

      mixpanel.trackEvent(selectAllPills);

      return data;
    }

    if (status === requestPillStatus.APPROVED) {
      const { approved: selectApprovedPills } = mixPanelEvents.select.leaveIssuerChangeRequest.list.pills;

      const dataToShow = data.reduce((acc, curr) => {
        if (curr.status === LEAVE_ISSUER_CHANGE_STATUS.APPROVED) {
          return [...acc, curr];
        }

        return acc;
      }, []);

      mixpanel.trackEvent(selectApprovedPills);

      return dataToShow;
    }

    if (status === requestPillStatus.PENDING) {
      const { pending: selectPendingPills } = mixPanelEvents.select.leaveIssuerChangeRequest.list.pills;

      const dataToShow = data.reduce((acc, curr) => {
        if (curr.status === LEAVE_ISSUER_CHANGE_STATUS.PENDING) {
          return [...acc, curr];
        }

        return acc;
      }, []);

      mixpanel.trackEvent(selectPendingPills);

      return dataToShow;
    }

    if (status === requestPillStatus.REJECTED) {
      const { rejected: selectRejectedPills } = mixPanelEvents.select.leaveIssuerChangeRequest.list.pills;

      const dataToShow = data.reduce((acc, curr) => {
        if (curr.status === LEAVE_ISSUER_CHANGE_STATUS.REJECTED) {
          return [...acc, curr];
        }

        return acc;
      }, []);

      mixpanel.trackEvent(selectRejectedPills);

      return dataToShow;
    }
  };

  const fetchLeaveIssuerChangeRequestList = async () => {
    try {
      setIsLoading(true);

      const { data } = await leaveIssuerChangeRequestService.fetchAll(filters);

      const pendingRequests = data.reduce((acc, curr) => {
        if (curr.status === LEAVE_ISSUER_CHANGE_STATUS.PENDING) {
          return acc + 1;
        }

        return acc;
      }, 0);

      const approvedRequests = data.reduce((acc, curr) => {
        if (curr.status === LEAVE_ISSUER_CHANGE_STATUS.APPROVED) {
          return acc + 1;
        }

        return acc;
      }, 0);

      const rejectedRequests = data.reduce((acc, curr) => {
        if (curr.status === LEAVE_ISSUER_CHANGE_STATUS.REJECTED) {
          return acc + 1;
        }

        return acc;
      }, 0);

      setRequestSummary({
        pending: pendingRequests,
        approved: approvedRequests,
        rejected: rejectedRequests
      });

      setLeaveIssuerChangeRequestList(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setQuery({
      ...query,
      status: hasKey(filters, 'status') ? query.status : requestPillStatus.PENDING,
      startDate: query.startDate || getMonthsFirstDate(),
      endDate: query.endDate || getFormattedDate()
    });

    fetchLeaveIssuerChangeRequestList();
  }, [query.startDate, query.endDate]);

  useEffect(() => {
    const queryParams = parse(props.location.search);

    const { status } = queryParams;

    const data = getDataToShow(leaveIssuerChangeRequestList, status);

    setDataToShow(data);
    setSelectedRequestIds([]);
  }, [props.location.search, leaveIssuerChangeRequestList]);

  const getColumns = useMemo(
    () => (isAscendingOrdered, setIsAscendingOrdered, columnToSortBy, setColumnToSortBy) => [
      {
        Header: <TableHeader label="" identifier="checkbox" />,
        sortable: false,
        filterable: false,
        maxWidth: 20,
        resizable: false,
        className: 'cursor-default',
        Cell: props => {
          const {
            original: { id: currentId, status }
          } = props;

          if (status !== LEAVE_ISSUER_CHANGE_STATUS.PENDING) {
            return (
              <CustomCheckbox
                disabled
                className="p-absolute opacity-0 request-checkbox staffing-request-checkbox cursor-not-allowed"
              />
            );
          }

          return (
            <CustomCheckbox
              className="p-absolute opacity-0 request-checkbox staffing-request-checkbox"
              onChange={e => {
                e.stopPropagation();

                const uncheckCheckbox = selectedRequestIds.includes(currentId);

                setSelectedRequestIds(prevValue => {
                  if (uncheckCheckbox) {
                    setIsSelectAllChecked(false);

                    return prevValue.filter(id => id !== currentId);
                  }

                  return [...prevValue, currentId];
                });
              }}
              checked={selectedRequestIds.includes(currentId)}
            />
          );
        }
      },
      {
        Header: <TableHeader label="Employee" identifier="employee" />,
        sortable: false,
        filterable: false,
        width: 190,
        className: 'cursor-default',
        Cell: props => {
          const { user } = props.original;

          return (
            <PopOver
              interactive
              html={
                <div className="tooltip-info word-break">
                  <p className="tooltip-info__title">{user.fullname}</p>
                </div>
              }
              visible={user.fullname.length > LEAVE_ISSUER_CHANGE_USER_NAME_LENGTH}
            >
              <AvatarLinkComponent customClass="ellipsis" empId={user.empId} id={user.id} name={user.fullname} />
            </PopOver>
          );
        }
      },
      {
        Header: <TableHeader label="Requested By" identifier="requestedBy" />,
        sortable: false,
        filterable: false,
        maxWidth: 125,
        className: 'cursor-default',
        Cell: props => {
          const { requestedBy, createdByRole } = props.original;

          return (
            <div className="d-flex flex-direction-column">
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{requestedBy.fullname}</p>
                  </div>
                }
                visible={requestedBy.fullname.length > LEAVE_ISSUER_CHANGE_USER_NAME_LENGTH}
              >
                <a
                  className="d-flex align-items-center"
                  href={interpolate(EMPLOYEE_PROFILE, { id: requestedBy.id })}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                >
                  <span className="ellipsis">{requestedBy.fullname}</span>
                </a>
              </PopOver>
              <span className="font-size-10">{createdByRole}</span>
            </div>
          );
        }
      },
      {
        Header: <TableHeader label="Requested To" identifier="requestedTo" />,
        sortable: false,
        filterable: false,
        maxWidth: 125,
        className: 'cursor-default',
        Cell: props => {
          const { newLeaveIssuer, newLeaveIssuerRole } = props.original;

          return (
            <div className="d-flex flex-direction-column">
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{newLeaveIssuer.fullname}</p>
                  </div>
                }
                visible={newLeaveIssuer.fullname.length > LEAVE_ISSUER_CHANGE_USER_NAME_LENGTH}
              >
                <a
                  className="d-flex align-items-center"
                  href={interpolate(EMPLOYEE_PROFILE, { id: newLeaveIssuer.id })}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                >
                  <span className="ellipsis">{newLeaveIssuer.fullname}</span>
                </a>
              </PopOver>
              <span className="font-size-10">{newLeaveIssuerRole}</span>
            </div>
          );
        }
      },
      {
        Header: <TableHeader label="Issuer Count" identifier="count" />,
        sortable: false,
        filterable: false,
        accessor: 'count',
        maxWidth: 120,
        className: 'cursor-default',
        Cell: props => {
          const { issueCount } = props.original;

          return <div>{issueCount}</div>;
        }
      },
      {
        Header: (
          <TableHeader
            label="Date of Request"
            identifier="dateOfRequest"
            columnToSortBy={columnToSortBy}
            isAscendingOrdered={isAscendingOrdered}
            onClick={() => {
              setIsAscendingOrdered(prevStatus => columnToSortBy !== 'dateOfRequest' || !prevStatus);

              setColumnToSortBy('dateOfRequest');
            }}
          />
        ),
        accessor: 'createdAt',
        maxWidth: 200,
        className: 'cursor-default',
        Cell: props => {
          return <div>{getFormattedDate(props.original.createdAt, DISPLAY_DATE_FORMAT)}</div>;
        }
      },
      {
        Header: (
          <TableHeader
            label="Transition Date"
            identifier="transitionDate"
            columnToSortBy={columnToSortBy}
            isAscendingOrdered={isAscendingOrdered}
            onClick={() => {
              setIsAscendingOrdered(prevStatus => columnToSortBy !== 'transitionDate' || !prevStatus);

              setColumnToSortBy('transitionDate');
            }}
          />
        ),
        accessor: 'transitionDate',
        maxWidth: 150,
        className: 'cursor-default',
        Cell: props => {
          return <div>{getFormattedDate(props.original.transitionDate, DISPLAY_DATE_FORMAT)}</div>;
        }
      },
      {
        Header: <TableHeader label="Reason" identifier="reason" />,
        accessor: 'reason',
        maxWidth: 120,
        sortable: false,
        filterable: false,
        className: 'cursor-default',
        Cell: props => {
          const { reason } = props.original;

          return (
            <>
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{reason}</p>
                  </div>
                }
                visible={reason.length > LEAVE_ISSUER_CHANGE_REASON_LENGTH}
              >
                <div className="ellipsis">{reason}</div>
              </PopOver>
            </>
          );
        }
      },
      {
        Header: <TableHeader label="Status" identifier="status" />,
        maxWidth: 110,
        sortable: false,
        filterable: false,
        className: 'cursor-default',
        Cell: props => {
          const { status } = props.original;

          return (
            <div className="d-flex gap-px-8 align-items-center">
              <div
                className={classNames('leave-issuer-list__status-bulb', {
                  [`leave-issuer-list__status-bulb--${status.toLowerCase()}`]: status
                })}
              ></div>
              <div className="levae-issuer-list__status-label">{status}</div>
            </div>
          );
        }
      },
      {
        Header: <TableHeader label="Action" identifier="action" />,
        maxWidth: 120,
        sortable: false,
        filterable: false,
        className: 'cursor-default',
        Cell: props => {
          const { user, status, newLeaveIssuer, comment } = props.original;

          const [isApprovingRequest, setIsApprovingRequest] = useState(false);

          if (status === LEAVE_ISSUER_CHANGE_STATUS.REJECTED) {
            return (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">
                      <span className="font-weight-bold">Reason:</span> {comment}
                    </p>
                  </div>
                }
                visible={!!comment}
              >
                <div className="leave-issuer-list__approved">{status}</div>
              </PopOver>
            );
          }

          if (status === LEAVE_ISSUER_CHANGE_STATUS.APPROVED) {
            return <div className="leave-issuer-list__approved">{status}</div>;
          }

          return (
            <div className="d-flex gap-px-8">
              <button
                className={classNames('leave-issuer-list__button leave-issuer-list__button--approve', {
                  'leave-issuer-list__button--approve-loading': isApprovingRequest
                })}
                onClick={() =>
                  handleApproveClick(user, LEAVE_ISSUER_CHANGE_STATUS.APPROVED, newLeaveIssuer, setIsApprovingRequest)
                }
                disabled={isApprovingRequest || isRejectingRequest}
              >
                {isApprovingRequest ? <Loading className="p-absolute white spinner-small" /> : <FiCheck size={16} />}
              </button>
              <button
                className={classNames('leave-issuer-list__button leave-issuer-list__button--reject', {
                  'leave-issuer-list__button--reject-loading': isRejectingRequest
                })}
                onClick={() => {
                  setIsModalOpen(true);
                  setRowData(props.original);
                }}
                disabled={isRejectingRequest || isApprovingRequest}
              >
                {isRejectingRequest ? <Loading className="p-absolute white spinner-small" /> : <FiX size={16} />}
              </button>
            </div>
          );
        }
      }
    ],
    [selectedRequestIds]
  );

  const onChangeSelectAllCheckbox = e => {
    e.stopPropagation();

    if (e.target.checked) {
      setSelectedRequestIds(
        dataToShow.filter(item => item.status === LEAVE_ISSUER_CHANGE_STATUS.PENDING).map(({ id }) => id)
      );
      setIsSelectAllChecked(true);
    } else {
      setSelectedRequestIds([]);
      setIsSelectAllChecked(false);
    }
  };

  return (
    <main>
      <LFModal
        customBtnClass="hide-d-none"
        stopPropagation={true}
        modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
        className="leave-issuer-reject-modal"
        title={{
          text: 'Do you want to reject this request?',
          type: 'danger'
        }}
        message={{
          text: `This action cannot be reverted back once rejected. Please write the reason for the rejection.`,
          type: 'lf-modal__message'
        }}
        isOpen={isModalOpen}
        htmlContent={
          <Formik
            validationSchema={rejectChangeRequestSchema}
            onSubmit={handleRejectClick}
            render={({ handleChange, handleSubmit, errors, values, handleBlur }) => (
              <form onSubmit={handleSubmit} className="holiday-form">
                <div>
                  <label className="form-label grey--text">
                    Reason for rejection<span className="required ml-5">*</span>
                  </label>
                  <textarea
                    name="rejectionReason"
                    type="text"
                    value={values.rejectionReason}
                    placeholder="Write a reason for rejecting the request..."
                    className={classNames('form-elem form-elem--input-sm', {
                      'form-elem--error': errors.rejectionReason
                    })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></textarea>
                  {errors.rejectionReason && <div className="error-block">{errors.rejectionReason}</div>}
                </div>

                <div className="ptpx-20">
                  <button
                    className="btn btn--large btn--red mrpx-14 delete__btn"
                    disabled={isRejectingRequest}
                    onClick={handleSubmit}
                  >
                    <span className={classNames({ invisible: isRejectingRequest })}>Yes, reject</span>
                    {isRejectingRequest && <Loading className="p-absolute white spinner-small" />}
                  </button>

                  <button
                    class="btn btn--large btn--outlined-grey py-8 px-12"
                    onClick={() => setIsModalOpen(false)}
                    disabled={isRejectingRequest}
                  >
                    No, go back
                  </button>
                </div>
              </form>
            )}
          />
        }
      />

      <div className="container">
        <div className="full-scope-card">
          <div className="full-scope-card__header d-flex justify-content-between name-wrap">
            <div className="d-flex align-items-center">
              <h3 className="table-title mrpx-40">Leave Issuer Requests</h3>
              {!isLoading && (
                <FilterPills
                  statusLabels={statusLabels}
                  statuses={requestPillStatus}
                  colorMap={requestPillStatusColor}
                  summary={requestSummary}
                  onChange={selectedFilter => {
                    setFilters({ ...filters, status: selectedFilter });
                  }}
                />
              )}
            </div>

            <button
              type="submit"
              className={classNames('btn btn--secondary plpx-12 prpx-12', {
                invisible: !selectedRequestIds.length
              })}
              onClick={handleBulkApproveClick}
              disabled={isBulkApproving}
            >
              {isBulkApproving && <Loading className="p-absolute black" />}
              <span className={classNames({ invisible: isBulkApproving })}>Approve selected</span>
            </button>
          </div>

          <div className="d-flex align-items-center border-bottom-gray ptpx-4 pbpx-4 prpx-4 plpx-4">
            <div className="hpx-45 mlpx-5 d-flex gap-px-8">
              <SmallDatePicker
                date={moment(filters.startDate)}
                displayFormat={SMALL_DATEPICKER_DATE_FORMAT}
                label={'Start Date'}
                onDateChange={date => {
                  setFilters({ ...filters, startDate: getFormattedDate(date) });
                  setIsFilterChanged(true);
                }}
                isOutsideRange={date => date.isAfter(filters.endDate)}
                isDark={isStartDateChanged}
                fromEnhancedFilter
                noBorder
              />

              <SmallDatePicker
                date={moment(filters.endDate)}
                displayFormat={SMALL_DATEPICKER_DATE_FORMAT}
                label={'End Date'}
                onDateChange={date => {
                  setFilters({ ...filters, endDate: getFormattedDate(date) });
                  setIsFilterChanged(true);
                }}
                isOutsideRange={date => date.isBefore(filters.startDate)}
                isDark={isEndDateChanged}
                fromEnhancedFilter
                noBorder
              />
            </div>
            <div className="d-flex align-items-center margin-left-auto mrpx-16">
              <button
                className={classNames('button m-10 ml-24 wpx-96', {
                  'button--primary': !isFilterChanged,
                  'button--secondary': isFilterChanged
                })}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </button>

              <button
                className={classNames('button__reset', {
                  'button__reset--active': isFilterApplied
                })}
                onClick={handleReset}
              >
                Reset
              </button>

              <button
                className="btn btn--outlined-grey export-button prpx-8 plpx-8"
                onClick={handleExportClick}
                disabled={isExporting || isLoading || !dataToShow?.length}
              >
                {isExporting ? (
                  <div className="export-button loading">
                    <Loading />
                  </div>
                ) : (
                  <>
                    <UiDownloadAlt className="download-icon" />
                    {'.CSV'}
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="full-scope-card__content p-relative">
            <Table
              loading={isLoading}
              data={dataToShow}
              columns={[...getColumns(isAscendingOrdered, setIsAscendingOrdered, columnToSortBy, setColumnToSortBy)]}
              showPagination={false}
              noDataText="Leave Issuer Change Requests not found."
              customClassName="leave-issuer-list-table rt-cursor-default"
            />

            <CustomCheckbox
              onChange={onChangeSelectAllCheckbox}
              checked={isSelectAllChecked && selectedRequestIds.length}
              className="position-absolute topx-18 leftx-16"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default withRouter(ChangeLeaveIssuerList);
