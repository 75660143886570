export const REJECTED = 'REJECTED';
export const APPROVED = 'APPROVED';
export const PENDING = 'PENDING';
export const SUBMITTED = 'SUBMITTED';
export const CANCELLED = 'CANCELLED';

export const WORKLOG_STATUS = {
  PENDING: PENDING,
  REJECTED: REJECTED,
  APPROVED: APPROVED,
  SUBMITTED: SUBMITTED,
  CANCELLED: CANCELLED
};

export const WORK_LOCATION = {
  OFFICE: 'OFFICE',
  HOME: 'HOME'
};

export const worklogStatusColor = {
  APPROVED: 'c-green',
  PENDING: 'c-yellow',
  REJECTED: 'c-red',
  SUBMITTED: 'c-brand-secondary',
  CANCELLED: 'c-grey',
  MISSING: 'c-red'
};

export const WORKING_SHIFTS_FILTER_OPTION = [
  {
    label: 'Morning',
    value: 'Morning Shift'
  },
  {
    label: 'Day',
    value: 'Day Shift'
  },
  {
    label: 'Evening',
    value: 'Evening Shift'
  },
  {
    label: 'Late Evening',
    value: 'Late Evening Shift'
  }
];

export const WORKING_SHIFTS_OPTIONS = [
  {
    label: 'Morning',
    value: 'MORNING_SHIFT'
  },
  {
    label: 'Day',
    value: 'DAY_SHIFT'
  },
  {
    label: 'Evening',
    value: 'EVENING_SHIFT'
  },
  {
    label: 'Late Evening',
    value: 'LATE_EVENING_SHIFT'
  }
];
