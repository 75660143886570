import config from 'config';

import http from '../utils/http';
import { stringify } from 'utils/queryParam';
import { getPeopleFinderParam } from 'utils/skillV2';
import { buildUrl, interpolate, unParseQuery } from 'utils/string';

import {
  USER_URL,
  USER_ROLE_URL,
  USER_SKILLS_URL,
  USER_PROFILE_URL,
  USER_RESOURCE_URL,
  USER_ADD_ROLE_URL,
  USER_IMAGE_UPLOAD_URL,
  GET_LEAVE_BALANCE_URL,
  USER_RESOURCE_EXPORT_URL,
  USER_DESIGNATION_UPDATE,
  USER_LEAVE_ISSUER_HISTORY,
  USER_TEAM_MANAGER_HISTORY,
  USER_LEAVE_ISSUER_UPDATE_NOTIFY,
  USER_TEAM_MANAGER_UPDATE_NOTIFY,
  USER_PROJECT_ALLOCATIONS,
  USER_PERMISSIONS,
  DASHBOARD_QUICK_LINKS,
  USER_ACTIVATION_URL,
  USER_ENGAGEMENT_UPDATE,
  USER_TEAM_URL,
  USER_RESIGNATIONS_URL,
  USER_RESIGNATION_URL,
  USER_SKILLSV2_URL,
  PEOPLE_FINDER,
  PEOPLE_FINDER_EXPORT,
  USER_SKILLSV2_STATUS_URL
} from '../constants/endpoints';

export async function fetchAll(params = {}) {
  const data = await http.get(USER_URL + unParseQuery(params));

  return data;
}

export async function fetchIssueeCount(id, fetchType) {
  const url = buildUrl(interpolate(USER_TEAM_URL, { id }));
  const data = await http.get(url + unParseQuery(fetchType));

  return data;
}

export const fetchByEmail = async email => {
  const { data } = await http.get(USER_URL + unParseQuery({ email }));

  return data;
};

export const fetchByEmployeeId = async empId => {
  const { data } = await http.get(USER_URL + unParseQuery({ empId }));

  return data;
};

export async function fetchRoles(id) {
  const { data } = await http.get(interpolate(USER_ROLE_URL, { id }));

  return data;
}

export async function fetchResource(params = {}) {
  const data = await http.get(USER_RESOURCE_URL + unParseQuery(params));

  return data;
}

export async function fetchPeopleFinder(params = {}) {
  const modifyParam = getPeopleFinderParam(params);

  const url = buildUrl(
    PEOPLE_FINDER,
    unParseQuery({
      ...params,
      ...modifyParam
    })
  );

  const data = await http.get(url);

  return data;
}

export async function exportResource(params = {}) {
  const data = await http.get(USER_RESOURCE_EXPORT_URL + unParseQuery(params), { responseType: 'blob' }, true);

  return data;
}

export async function exportPeopleFinder(params = {}) {
  const modifyParam = getPeopleFinderParam(params);

  const url = buildUrl(PEOPLE_FINDER_EXPORT, unParseQuery({ ...params, ...modifyParam }));

  const data = await http.get(url, { responseType: 'blob' }, true);

  return data;
}

export async function fetchById(id) {
  const { data } = await http.get(interpolate(USER_PROFILE_URL, { id }));

  return data;
}

export async function create(user) {
  return await http.post(USER_URL, {
    body: user
  });
}

export async function update(user, id) {
  return await http.put(interpolate(USER_PROFILE_URL, { id }), { body: user });
}

export async function updateSkills(skills, id) {
  return await http.put(interpolate(USER_SKILLS_URL, { id }), { body: skills });
}

export async function upload(userId, employeeImageFomData) {
  const url = interpolate(USER_IMAGE_UPLOAD_URL, {
    id: userId
  });

  return await http.post(url, {
    headers: { 'Content-Type': 'multipart/form-data' },
    body: employeeImageFomData
  });
}

export async function addRole(id, roles) {
  const url = interpolate(USER_ADD_ROLE_URL, {
    id
  });

  const { data } = await http.post(url, { body: { roles } });

  return data;
}

export async function fetchLeaveIssuerHistory(id) {
  const { data } = await http.get(interpolate(USER_LEAVE_ISSUER_HISTORY, { id }));

  return data;
}

export async function updateLeaveIssuer(leaveIssuer, id) {
  const data = await http.put(interpolate(USER_LEAVE_ISSUER_UPDATE_NOTIFY, { id }), { body: leaveIssuer });

  return data;
}

export async function updateDesignation(designation, id) {
  const data = await http.put(interpolate(USER_DESIGNATION_UPDATE, { id }), { body: designation });

  return data;
}

export async function updateEngagementStatus(id, payload) {
  const data = await http.put(interpolate(USER_ENGAGEMENT_UPDATE, { id }), { body: payload });

  return data;
}

export async function fetchLeaves(params) {
  const url = stringify(GET_LEAVE_BALANCE_URL, params);

  const data = await http.get(url);
  return data;
}

export async function updateTeamManager(teamManager, id) {
  const data = await http.put(interpolate(USER_TEAM_MANAGER_UPDATE_NOTIFY, { id }), { body: teamManager });

  return data;
}

export async function fetchTeamManagerHistory(id) {
  const { data } = await http.get(interpolate(USER_TEAM_MANAGER_HISTORY, { id }));

  return data;
}

/**
 * Fetch current active allocation of the user.
 *
 * @param {number} userId
 * @returns {Promise<Array>}
 */

export async function fetchUserCurrentAllocations(userId) {
  const { data } = await http.get(USER_PROJECT_ALLOCATIONS + unParseQuery({ userId, status: 'active' }));

  return data;
}

/**
 * Fetch booking allocation of the user.
 *
 * @param {Number} userId
 * @returns {Object}
 */

export async function fetchUserBookingAllocation(userId) {
  const data = await http.get(
    interpolate(`${config.teamsBaseURI}${config.endpoints.teams.bookingDetails}`, {
      userId: userId
    })
  );

  return data;
}

/**
 * Fetch allocation of the user.
 *
 * @param {Object} params
 * @returns {Promise<Array>}
 */

export async function fetchUserAllocations(params = {}) {
  const { data, meta } = await http.get(USER_PROJECT_ALLOCATIONS, { params: { ...params } });

  return { data, meta };
}

export async function fetchUserAllocationsWithoutPagination(params = {}) {
  const { paginationStatus, ...rest } = params;

  const data = await http.get(USER_PROJECT_ALLOCATIONS, { params: { paginationStatus: 'inactive', ...rest } });

  return data;
}

export async function fetchUserPermissions(id) {
  const { data } = await http.get(interpolate(USER_PERMISSIONS, { id }));

  return data;
}

/**
 *
 * @param {Object} params - query string(s)
 * @returns {Array} quick links
 */
export async function fetchQuickLinks() {
  const data = await http.get(DASHBOARD_QUICK_LINKS);

  return data;
}

export async function activateUser(id, payload) {
  const data = await http.post(interpolate(USER_ACTIVATION_URL, { id }), { body: payload });

  return data;
}

export async function fetchTeamMembers(id, params = {}) {
  const data = await http.get(interpolate(USER_TEAM_URL, { id }) + unParseQuery(params));

  return data;
}

/**
 * Fetch resignation by the userId.
 *
 * @param {number} userId
 * @returns {Object}
 */
export async function fetchResignation(userId) {
  const data = await http.get(interpolate(USER_RESIGNATIONS_URL, { userId }));

  return data;
}

/**
 * Create resignation by the userId.
 *
 * @param {Object} resignation
 * @param {number} userId
 * @returns {Object}
 */
export async function createResignation(resignation, userId) {
  const data = await http.post(interpolate(USER_RESIGNATIONS_URL, { userId }), { body: resignation });

  return data;
}

/**
 * Update resignation of userId by id.
 *
 * @param {Object} resignation
 * @param {number} userId
 * @returns {Object}
 */
export async function updateResignation(resignation, userId, id) {
  const data = await http.put(interpolate(USER_RESIGNATION_URL, { userId, id }), { body: resignation });

  return data;
}

/**
 * Delete resignation of userId by id.
 *
 * @param {Object} resignation
 * @param {number} userId
 * @returns {Object}
 */
export async function deleteResignation(userId, id) {
  const data = await http.delete(interpolate(USER_RESIGNATION_URL, { userId, id }));

  return data;
}

/**
 * Update skills of user
 *
 * @param {number} userId
 * @param {Object} payload
 * @returns {Object}
 */
export async function updateSkillsV2(userId, payload) {
  const data = await http.put(interpolate(USER_SKILLSV2_URL, { userId }), { body: payload });

  return data;
}

export async function approveDirectSkill(userId, skillId, payload) {
  const url = interpolate(USER_SKILLSV2_STATUS_URL, { userId, skillId });

  const data = await http.patch(url, { body: payload });

  return data;
}

/**
 * Map the given values to payload that is accepted by API.
 *
 * @param {Object} values
 */
export function getUserPayload(values) {
  return {
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    empId: values.empId,
    joinDate: values.joinDate,
    recruiteeUrl: values.recruiteeUrl,
    username: values.username,
    departmentId: values.departmentId,
    leaveIssuerId: values.leaveIssuerId,
    teamManagerId: values.teamManagerId,
    scheduledType: values.scheduledType,
    workingShift: values.workingShift,
    availabilityTime: {
      startTime: values.availabilityStartTime.value,
      startTimeType: values.availabilityStartTime.type,
      endTime: values.availabilityEndTime.value,
      endTimeType: values.availabilityEndTime.type
    },
    roleIds: values.roleIds,
    pastExperience: values.pastExperienceYears * 12 + +values.pastExperienceMonths,
    cvUrl: values.cvUrl,
    gender: values.gender,
    dateofBirth: values.dateofBirth,
    bloodGroup: values.bloodGroup,
    maritialStatus: values.maritialStatus,
    personalEmail: values.personalEmail,
    mobilePhone: values.mobilePhone,
    homePhone: values.homePhone,
    emergencyPhone: values.emergencyPhone,
    emergencyContactRelationship: values.emergencyContactRelationship,
    temporaryAddress: values.temporaryAddress,
    permanentAddress: values.permanentAddress,
    country: values.country,
    timezone: values.timezone,
    githubId: values.githubId,
    designationAreaHistory: values.designationAreaHistory,
    empStatusHistory: values.empStatusHistory,
    employeeImage: values.employeeImage && values.employeeImage[0]?.file,
    appraiserId: values.appraiserId || null,
    coAppraiserId: values.coAppraiserId || null
  };
}
