import React from 'react';

import useAuthenticatedImage from 'hooks/useAuthenicatedImage';

export default function AuthenticatedImage(props) {
  const { alt, src, children: fallbackImage, className, onError, onLoad } = props;

  const { src: imageSrc } = useAuthenticatedImage({ src: src, onError });

  return (
    <div>
      {imageSrc ? <img src={imageSrc} alt={alt} className={className || ' '} onLoad={onLoad} /> : fallbackImage}
    </div>
  );
}
