import { FiInfo } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { UiPen } from 'vyaguta-icons/ui';

import LFModal from 'components/common/LFModal';

import PopOver from 'components/common/popover';
import InfoBox from 'components/common/infoBox';
import SkillCategoryChild from 'components/home/skills/components/SkillCategoryChild';
import MyTeam from 'components/home/employees/components/profile/MyTeamComponents/MyTeam';

import useTitle from 'hooks/useTitle';

import * as userService from 'services/user';
import * as skillService from 'services/skillService';

import { isObjectEmpty } from 'utils/object';
import { getFormattedDate } from 'utils/date';
import { handleError } from 'utils/errorHandler';
import { getSearchTreeWithRating } from 'utils/skillV2';
import { toLinkComponent, toTextComponent } from 'utils/component';
import { formatEmployeeAvailabilityTime, getFullName } from 'utils/user';
import { extractFullName, interpolate, startCase, underscoreToSpace } from 'utils/string';

import * as routes from 'constants/routes';
import { SKILL_STATUS } from 'constants/skills';
import { EMPLOYEES, USER_PERMISSIONS } from 'constants/permissions';
import { DATE_FORMAT_FOR_DISPLAY, MONTH_DAY_DISPLAY_FORMAT } from 'constants/appConstants';

import * as skillV2Service from 'services/skillV2Service';

import useNonInitialEffect from 'hooks/useNonIntialEffect';

import FlaggedSkill from './FlaggedSkill';
import VerifiedSkill from './VerifiedSkill';
import SkillReviewOverlay from './SkillReviewOverlay';
import ProjectAllocations from './ProjectAllocations';
import EmployeePageDetail from '../EmployeePageDetail';
import EmployeeHistoryList from '../EmploymentHistoryList';

const DEFAULT_MAX_VISIBLE_SKILLS = 4;

const ProfileDescription = props => {
  const { authUser, employee } = props;

  const {
    value: { permissions, user }
  } = useSelector(store => store.information);

  const [userRoles, setUserRoles] = useState([]);
  const [skills, setSkills] = useState([]);
  const [userSkills, setUserSkills] = useState(employee?.skills || []);
  const [refreshUserSkills, setRefreshUserSkills] = useState(false);

  const isSelf = authUser.id === employee.id;
  const isLeaveIssuer = employee.leaveIssuer?.id === authUser.id;

  useEffect(() => {
    (async () => {
      try {
        if (isObjectEmpty(employee) || (!isSelf && !permissions[USER_PERMISSIONS.VIEW])) {
          return;
        }

        const data = await userService.fetchRoles(employee.id);

        if (!data || !data.roles) {
          return;
        }

        setUserRoles(data.roles);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [employee]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await skillService.fetchAll();

        setSkills(data);
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  useNonInitialEffect(() => {
    (async () => {
      if (!employee.id) {
        return;
      }

      try {
        const { data } = await skillService.fetchByUserId(employee.id);

        setUserSkills(data);
      } catch (error) {
        handleError(error);
      }
    })();
  }, [employee, refreshUserSkills]);

  const EmployeeSkillsV1 = ({ skills, maxVisibleSkills = DEFAULT_MAX_VISIBLE_SKILLS }) => {
    const [showAll, setShowAll] = useState(false);

    if (!skills || !skills.length) {
      return null;
    }

    const skillCount = skills.length;
    const visibleSkills = skills.slice(0, maxVisibleSkills);
    const hiddenSkills = skills.slice(maxVisibleSkills);

    return (
      <div className="d-flex fd-row f-wrap">
        {visibleSkills.map(skill => (
          <Skill skill={skill} />
        ))}
        {showAll && hiddenSkills.map(skill => <Skill skill={skill} />)}
        {skillCount > maxVisibleSkills && (
          <div
            className="selected-skill skills-visibility-toggle"
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'Collapse' : `+ ${skillCount - maxVisibleSkills} More`}
          </div>
        )}
      </div>
    );
  };

  const Skill = ({ skill }) => (
    <div className="selected-skill" key={skill.id}>
      <span>{skill.name}</span>
      {skill.rating && (
        <div className="skill-rating">
          <span>{skill.rating}</span>
        </div>
      )}
    </div>
  );

  const TentaiveExit = ({ resignation }) => {
    return (
      <>
        <span className="pr-5">
          {resignation?.tentativeExitDate
            ? getFormattedDate(resignation.tentativeExitDate, DATE_FORMAT_FOR_DISPLAY)
            : '-'}
        </span>
        <PopOver
          interactive
          html={
            <div className="tooltip-info">
              <p className="tooltip-info__title">{resignation?.reason}</p>
            </div>
          }
        >
          <FiInfo color="#2196F3" size={12} />
        </PopOver>
      </>
    );
  };

  const getEmployeeRoles = userRoles => {
    return userRoles.map(role => role.name).join(', ');
  };

  return (
    <>
      <div className="container d-flex fd-row flex-fix name-wrap">
        <div className="width--full">
          <div className="full-scope-card emp-info-container mt-25">
            <div className="emp-info-title">Personal Info</div>
            <EmployeePageDetail
              fieldMap={[
                {
                  label: 'Gender',
                  DataComponent: toTextComponent(startCase(employee.gender))
                },
                {
                  label: 'Birthday',
                  DataComponent: toTextComponent(
                    authUser.id === employee.id
                      ? getFormattedDate(employee.dateofBirth, MONTH_DAY_DISPLAY_FORMAT)
                      : employee.birthday
                  )
                },
                {
                  label: 'Blood Group',
                  DataComponent: toTextComponent(employee.bloodGroup)
                },
                {
                  label: 'Temporary Address',
                  DataComponent: toTextComponent(employee.temporaryAddress)
                },
                {
                  label: 'Permanent Address',
                  DataComponent: toTextComponent(employee.permanentAddress)
                },
                {
                  label: 'Country',
                  DataComponent: toTextComponent(employee.country)
                },
                {
                  label: 'Time Zone',
                  DataComponent: toTextComponent(employee.timezone)
                }
              ]}
            />
          </div>
          <div className="full-scope-card emp-info-container mt-25">
            <div className="emp-info-title">Work Info</div>
            <EmployeePageDetail
              id={employee.id}
              employee={employee}
              fieldMap={[
                {
                  label: 'Leave Issuer',
                  DataComponent: toLinkComponent(
                    employee.leaveIssuer && extractFullName(employee.leaveIssuer),
                    routes.EMPLOYEE_PROFILE,
                    {
                      id: employee.leaveIssuer && employee.leaveIssuer.id
                    },
                    true
                  )
                },
                {
                  label: 'Team Manager',
                  DataComponent: toLinkComponent(
                    employee.teamManager && extractFullName(employee.teamManager),
                    routes.EMPLOYEE_PROFILE,
                    {
                      id: employee.teamManager && employee.teamManager.id
                    },
                    true
                  )
                },
                {
                  label: 'Department',
                  DataComponent: toTextComponent(employee.department?.name)
                },
                {
                  label: 'Employee since',
                  DataComponent: toTextComponent(getFormattedDate(employee.joinDate, DATE_FORMAT_FOR_DISPLAY))
                },
                {
                  label: 'Working Shift',
                  DataComponent: toTextComponent(underscoreToSpace(employee.workingShift))
                },
                {
                  label: 'Working Type',
                  DataComponent: toTextComponent(underscoreToSpace(employee.scheduledType))
                },
                {
                  label: 'Availability Time',
                  DataComponent: toTextComponent(formatEmployeeAvailabilityTime(employee.availabilityTime))
                },
                {
                  label: 'Roles',
                  DataComponent: toTextComponent(getEmployeeRoles(userRoles)),
                  isUnAuthorizedUser: !isSelf && !permissions[USER_PERMISSIONS.VIEW]
                },
                {
                  label: 'Tentative Exit Date',
                  DataComponent: employee?.resignation?.id ? TentaiveExit : null,
                  isUnAuthorizedUser: !isSelf && !isLeaveIssuer && !permissions[EMPLOYEES.RESIGNATIONS.VIEW],
                  props: { resignation: employee?.resignation }
                },
                {
                  label: 'Skill set',
                  DataComponent: EmployeeSkills,
                  props: { skills: userSkills, totalSkills: skills, employee, authUser, setRefreshUserSkills }
                }
              ]}
            />
          </div>
          <ProjectAllocations
            employeeName={extractFullName(employee)}
            employeeId={employee.id}
            authUserId={authUser.id}
            employee={employee}
            isSelf={isSelf}
          />
        </div>

        <div className="profile-right">
          <div className="full-scope-card emp-history-area emp-history-area--profile mt-25 moml-30 padding--rl custom-scroll-bar">
            <EmployeeHistoryList employee={employee} />
          </div>
          <MyTeam employee={employee} isSelf={isSelf} />
        </div>
      </div>
    </>
  );
};

const EmployeeSkills = ({ skills, totalSkills, employee, authUser, setRefreshUserSkills }) => {
  const history = useHistory();

  const {
    value: { permissions }
  } = useSelector(store => store.information);

  const [isOverlayOpenFor, setIsOverlayOpenFor] = useState(null);
  const [skillModalOpen, setSkillModalOpen] = useState(false);
  const [globalRatingScale, setGlobalRatingScale] = useState([]);

  const fetchGlobalRatingScale = useCallback(async () => {
    try {
      const { data } = await skillV2Service.fetchGlobalRating();

      setGlobalRatingScale(data.globalRatings);
    } catch (error) {
      handleError(error);
    } finally {
    }
  }, []);

  useEffect(() => {
    fetchGlobalRatingScale();
  }, []);

  const isSelf = authUser.id === employee.id;

  const nonVerifiedSkillsCount = skills.filter(curr => !curr.skillStatus?.status).length;

  const verifiedSkillsCount = skills.filter(curr => curr.skillStatus?.status === SKILL_STATUS.APPROVED).length;

  const flaggedSkillsCount = skills.filter(
    curr => curr.skillStatus?.status === SKILL_STATUS.FLAGGED && curr.skillStatus?.rating !== 0
  ).length;

  const deletionRecommendationCount = skills.filter(curr => curr.skillStatus?.rating === 0).length;

  const filteredSelectedSkills = useMemo(() => getSearchTreeWithRating(skills, totalSkills), [skills, totalSkills]);

  const isTeamManager = authUser.id === employee.teamManager?.id;

  const hasOverlayPermission = isTeamManager || (!!isOverlayOpenFor?.skillStatus && isSelf);

  const employeeFullName = getFullName(employee);

  const skillCount = skills.length;

  return (
    <div className="user-skillsv2-profile">
      <div className="user-skillsv2-profile__container">
        <div className="d-flex fd-row f-wrap align-items-center">
          <div>{skillCount} skills</div>
          {!!skillCount && (
            <div onClick={() => setSkillModalOpen(true)} className="view-link">
              View all
            </div>
          )}
          {!!nonVerifiedSkillsCount && isTeamManager && (
            <InfoBox message="Verify the skill sets" className="info-box" />
          )}
        </div>

        {!!skillCount && (
          <div className="skill-count-container">
            <VerifiedSkill count={{ verifiedSkills: verifiedSkillsCount, totalSkills: skills.length }} />
            {!!flaggedSkillsCount && <FlaggedSkill count={flaggedSkillsCount} type="flag" />}
            {!!deletionRecommendationCount && <FlaggedSkill count={deletionRecommendationCount} type="delete" />}
          </div>
        )}
      </div>

      <LFModal
        isOpen={skillModalOpen}
        onClose={() => setSkillModalOpen(false)}
        title={{ text: `Selected Skills of ${employeeFullName}`, type: 'info' }}
        titlePrefixHtml={
          isSelf || permissions[EMPLOYEES.SKILLS.UPDATE] ? (
            <button
              className="profile-skills-edit"
              onClick={() => {
                history.push(interpolate(routes.UPDATE_EMPLOYEE_SKILLS, { id: employee?.id }));
              }}
            >
              <UiPen />
            </button>
          ) : (
            ''
          )
        }
        htmlContent={
          <div className="skill-set-area__container profile-skillset">
            <div className="mb-15">
              {!!flaggedSkillsCount && isSelf && (
                <InfoBox message="Please check all the flagged skills by clicking on them." />
              )}

              {!!nonVerifiedSkillsCount && isTeamManager && (
                <InfoBox message="Verify the skill ratings by flagging the ones that do not match." />
              )}
            </div>

            <div className="skills-list-container">
              {filteredSelectedSkills.map(skill => {
                return (
                  <SkillCategoryChild
                    key={skill.id}
                    selectedCategory={skill}
                    setIsOverlayOpenFor={setIsOverlayOpenFor}
                    isOpenFor={hasOverlayPermission && isOverlayOpenFor}
                    isSelf={isSelf}
                    isTeamManager={isTeamManager}
                  />
                );
              })}
            </div>
            {!!isOverlayOpenFor && (
              <SkillReviewOverlay
                isOpenFor={hasOverlayPermission && isOverlayOpenFor}
                setIsOverlayOpenFor={setIsOverlayOpenFor}
                globalRatingScale={globalRatingScale}
                employee={employee}
                userSkills={skills}
                setRefreshUserSkills={setRefreshUserSkills}
              />
            )}
          </div>
        }
        renderFooter={false}
        closeButton={true}
        className="profile-skill-modal"
      />
    </div>
  );
};

export default ProfileDescription;
