import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { FiAlertCircle, FiCheck, FiFlag } from 'react-icons/fi';

import { SKILL_INFO_MESSAGES, TALK_TO_TM } from 'constants/skills';
import { ACTION_ITEMS, AGREE, APPROVE, FLAG, FLAGGED_REASON_LENGTH, SKILL_STATUS } from 'constants/skills';

import Avatar from 'components/common/avatar';
import InfoBox from 'components/common/infoBox';
import InputWrapper from 'components/common/inputWrapper';

import { success } from 'utils/toast';
import { handleError } from 'utils/errorHandler';
import { getEmpImageUrl, interpolate } from 'utils/string';

import * as userService from 'services/user';

import SkillRatingSelector from './SkillRatingSelector';

const FlaggedRatingSelector = props => {
  const { skillRatingScale, skill, userRating, employee, handleOverlayClose } = props;

  const [isFlagged, setIsFlagged] = useState(false);
  const [flagReason, setFlagReason] = useState('');
  const [teamManagerRating, setTeamManagerRating] = useState(null);
  const [removalReason, setRemovalReason] = useState('');
  const [recommendForRemoval, setRecommendForRemoval] = useState(false);
  const [deletionConfirmation, setDeletionConfirmation] = useState(false);

  const {
    value: { user }
  } = useSelector(store => store.information);

  const isLoggedUser = user.id === employee.id;
  const isTeamManager = user.id === employee.teamManager?.id;

  const isSkillFlagged = skill.skillStatus?.status === SKILL_STATUS.FLAGGED;
  const isNonZeroRating = skill.skillStatus?.rating !== 0;

  const handleSkillStatus = async status => {
    let payload = {
      status: status,
      rating: userRating
    };

    if (status === SKILL_STATUS.FLAGGED && !recommendForRemoval) {
      payload.description = flagReason.trim();
      payload.rating = teamManagerRating;
    }

    if (teamManagerRating === userRating) {
      payload.status = SKILL_STATUS.APPROVED;

      delete payload.description;
    }

    if (recommendForRemoval) {
      payload = { ...payload, rating: 0, description: removalReason.trim() };
    }

    try {
      await userService.approveDirectSkill(employee.id, skill.id, payload);

      handleOverlayClose(true);

      success({
        title: 'Success',
        message: `User skill rating has been ${recommendForRemoval ? 'recommended for removal' : status.toLowerCase()}.`
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleFlagReason = e => {
    setFlagReason(e.target.value);
  };

  const handleRemovalReason = e => {
    setRemovalReason(e.target.value);
  };

  const handleDirectAgree = async () => {
    if (!isNonZeroRating && !deletionConfirmation) {
      setDeletionConfirmation(true);

      return;
    }

    try {
      const payload = {
        rating: skill.skillStatus.rating,
        status: AGREE
      };

      await userService.approveDirectSkill(employee.id, skill.id, payload);

      handleOverlayClose(true);

      success({
        title: 'Success',
        message: `Flagged rating has been agreed.`
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleRecommendForRemoval = () => {
    setRecommendForRemoval(true);

    setTeamManagerRating(null);
    setFlagReason('');
  };

  return (
    <div className="flagged-rating">
      <div className="flagged-rating__top-container">
        <div className="header-text">
          Ratings {skill.skillStatus?.status === SKILL_STATUS.FLAGGED && 'and reasons for flagging'}
        </div>
        <div className="skill-rate-selector">
          <div className="skill-rate-selector__btn-container">
            {skillRatingScale.map(ratingScale => {
              return (
                <div
                  className={classNames('rating-btn flex-1', {
                    'rating-btn--selected': ratingScale.rating === userRating,
                    'rating-btn--selected-green':
                      ratingScale.rating === skill.skillStatus?.rating &&
                      skill.skillStatus?.status === SKILL_STATUS.FLAGGED,
                    'rating-btn--selected-green-reflag':
                      ratingScale.rating === skill.skillStatus?.rating &&
                      skill.skillStatus?.status === SKILL_STATUS.FLAGGED &&
                      isFlagged
                  })}
                  key={ratingScale.rating}
                >
                  <div className="rating-btn__top-container">
                    <div className="scale">{ratingScale.rating}</div>
                    {ratingScale.rating === userRating && (
                      <div className="avatar-container">
                        <div className="avatar">
                          <Avatar image={getEmpImageUrl(employee.empId)} size="extraSmall" />
                        </div>
                        {skill.skillStatus?.status === SKILL_STATUS.APPROVED && (
                          <div className="avatar avatar--stacked">
                            <Avatar image={getEmpImageUrl(skill.skillStatus?.createdBy.empId)} size="extraSmall" />
                          </div>
                        )}
                      </div>
                    )}
                    {ratingScale.rating === skill.skillStatus?.rating &&
                      skill.skillStatus?.status === SKILL_STATUS.FLAGGED && (
                        <div className="avatar-container">
                          <div className="avatar">
                            <Avatar image={getEmpImageUrl(skill.skillStatus?.createdBy.empId)} size="extraSmall" />
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="description">{ratingScale.description}</div>
                </div>
              );
            })}
          </div>
        </div>
        {!isFlagged && !skill.skillStatus?.status && !recommendForRemoval && (
          <div>
            <div className="d-flex align-items-center gap-x-8 mt-15 mb-10">
              <button
                type="button"
                className="btn btn--submit"
                onClick={() => handleSkillStatus(SKILL_STATUS.APPROVED)}
              >
                <FiCheck size={16} className="check-icon" />
                {APPROVE}
              </button>
              <button type="button" className="btn btn--flag" onClick={() => setIsFlagged(true)}>
                <FiFlag size={16} className="flag-icon" />
                {FLAG}
              </button>
            </div>
            <div>
              <button className="removal-remcommendation-btn" onClick={handleRecommendForRemoval}>
                Recommend for removal
              </button>
            </div>
          </div>
        )}
        {!isFlagged && !recommendForRemoval && (
          <div>
            {
              <div className="reflag-skill">
                {isSkillFlagged && (
                  <div
                    className={classNames({
                      'flag-reason-box': isNonZeroRating,
                      'removal-recommendation-box': !isNonZeroRating
                    })}
                  >
                    {!isNonZeroRating && (
                      <div className="removal-recommendation-box__avatar">
                        <Avatar image={getEmpImageUrl(skill.skillStatus?.createdBy.empId)} size="extraSmall" />
                      </div>
                    )}
                    <div>
                      <div
                        className={classNames({
                          'flag-reason-box__heading': isNonZeroRating,
                          'removal-recommendation-box__heading': !isNonZeroRating
                        })}
                      >
                        {isNonZeroRating ? 'Reason for flagging' : 'Recommended for removal'}
                      </div>
                      <div
                        className={classNames({
                          'flag-reason-box__description': isNonZeroRating,
                          'removal-recommendation-box__description': !isNonZeroRating
                        })}
                      >
                        {skill.skillStatus?.description}
                      </div>
                    </div>
                  </div>
                )}

                {!isNonZeroRating && deletionConfirmation && (
                  <div className="deletion-confirmation">
                    <div className="icon-container">
                      <FiAlertCircle className="icon" size={16} />
                    </div>
                    <div>
                      <div className="deletion-confirmation__heading">Are you sure?</div>
                      <div className="deletion-confirmation__body">{SKILL_INFO_MESSAGES.DELETION_CONFIRMATION}</div>
                      <div className="deletion-confirmation__btn-container">
                        <button type="button" className={classNames('btn btn--submit')} onClick={handleDirectAgree}>
                          {ACTION_ITEMS.SURE}
                        </button>
                        <button
                          type="button"
                          className="btn btn--outlined-grey"
                          onClick={() => setDeletionConfirmation(false)}
                        >
                          {ACTION_ITEMS.GO_BACK}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {isTeamManager && skill.skillStatus?.status && (
                  <div className="mt-10">
                    <button type="button" className="btn btn--outlined-grey" onClick={() => setIsFlagged(true)}>
                      <FiFlag size={16} className="flag-icon" />
                      <div>{ACTION_ITEMS.FLAG_AGAIN}</div>
                    </button>
                  </div>
                )}
                {isLoggedUser && skill.skillStatus?.status === SKILL_STATUS.FLAGGED && !deletionConfirmation && (
                  <div className="skill-agree-container">
                    <button type="button" className="btn btn--approve" onClick={handleDirectAgree}>
                      <FiCheck size={16} className="check-icon" />
                      <div>{skill.skillStatus?.rating === 0 ? ACTION_ITEMS.AGREE : ACTION_ITEMS.AGREE_WITH_RATING}</div>
                    </button>
                    <div className="additional-text">
                      or <span>{TALK_TO_TM}</span>
                    </div>
                  </div>
                )}
              </div>
            }
            {skill.skillStatus?.status && !deletionConfirmation && (
              <div className="mt-10">
                <div className="info-box">
                  <div className="info-box__icon-container">
                    <FiAlertCircle className="icon" size={16} />
                  </div>
                  {skill.skillStatus?.status === SKILL_STATUS.FLAGGED && (
                    <div>
                      {isTeamManager && SKILL_INFO_MESSAGES.REFLAG_DIRECT_SKILL}
                      {isLoggedUser && skill.skillStatus?.rating === 0 && SKILL_INFO_MESSAGES.AGREE_FOR_REMOVAL}
                      {isLoggedUser && skill.skillStatus?.rating !== 0 && SKILL_INFO_MESSAGES.AGREE_WITH_TEAM_MANAGER}
                    </div>
                  )}
                  {skill.skillStatus?.status === SKILL_STATUS.APPROVED && (
                    <div>
                      {isLoggedUser && SKILL_INFO_MESSAGES.TALK_TO_TM}
                      {isTeamManager && SKILL_INFO_MESSAGES.REFLAG_DIRECT_SKILL}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {recommendForRemoval && (
        <div className="flagged-rating__bottom-container">
          <InfoBox
            message={interpolate(SKILL_INFO_MESSAGES.RECOMMENDATION_REMOVAL_INFO, { firstName: employee.firstName })}
          />

          <InputWrapper label="Reason to recommend for removal" isMandatory className="mt-10">
            <textarea
              name="reason"
              placeholder="Write your reason here..."
              className="form-elem font-weight-regular"
              value={removalReason}
              onChange={handleRemovalReason}
            />
          </InputWrapper>

          <div className="d-flex align-items-center gap-x-8 mt-15">
            <button
              type="button"
              className={classNames('btn btn--submit', {
                'btn--disable': removalReason.length < FLAGGED_REASON_LENGTH
              })}
              disabled={removalReason.length < FLAGGED_REASON_LENGTH}
              onClick={() => handleSkillStatus(SKILL_STATUS.FLAGGED)}
            >
              Submit
            </button>
            <button type="button" className="btn btn--outlined-grey" onClick={() => setRecommendForRemoval(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {isFlagged && !recommendForRemoval && (
        <div className="flagged-rating__bottom-container">
          <div className="heading-text">Please re-rate the skills for {employee.firstName} </div>
          <SkillRatingSelector
            showAvatar
            skill={skill}
            skillRatingScale={skillRatingScale}
            rating={teamManagerRating}
            setRating={setTeamManagerRating}
            disabledRating={skill.skillStatus?.rating}
            loggedUser={user}
            showDescription={false}
          />

          <div className="mt-10">
            <button className="removal-remcommendation-btn" onClick={handleRecommendForRemoval}>
              Recommend for removal
            </button>
          </div>

          <div className="flag-reason-container">
            {teamManagerRating !== userRating && (
              <div>
                <InputWrapper label="Reason for flagging" isMandatory>
                  <textarea
                    name="reason"
                    placeholder="Write your reason here..."
                    className="form-elem font-weight-regular flag-reason-textarea"
                    value={flagReason}
                    onChange={handleFlagReason}
                  />
                </InputWrapper>
              </div>
            )}
            {teamManagerRating === userRating && (
              <div className="info-box">
                <div className="info-box__icon-container">
                  <FiAlertCircle className="icon" size={16} />
                </div>
                <div>
                  {isTeamManager &&
                    interpolate(SKILL_INFO_MESSAGES.AGREE_WITH_DIRECT, { firstName: employee.firstName })}
                </div>
              </div>
            )}
            <div className="d-flex align-items-center gap-x-8 mt-15">
              <button
                type="button"
                className={classNames('btn btn--submit', {
                  'btn--disable':
                    (flagReason.length < FLAGGED_REASON_LENGTH || !teamManagerRating) &&
                    teamManagerRating !== userRating
                })}
                disabled={
                  (flagReason.length < FLAGGED_REASON_LENGTH || !teamManagerRating) && teamManagerRating !== userRating
                }
                onClick={() => handleSkillStatus(SKILL_STATUS.FLAGGED)}
              >
                Submit
              </button>
              <button type="button" className="btn btn--outlined-grey" onClick={() => setIsFlagged(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlaggedRatingSelector;
