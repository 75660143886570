import config from 'config';
import isEqual from 'lodash/isEqual';
import pinterpolate from 'pinterpolate';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Header as VyagutaHeader,
  SecondaryNav,
  Global as VyagutaWrapper,
  NotificationBar
} from '@vyaguta/vyaguta-ui';

import { getEmpImageUrl } from 'utils/string';
import { addClassToChildren } from 'utils/dom';
import { handleError } from 'utils/errorHandler';

import * as authService from 'services/auth';

import { NOTICE_BOARD } from 'constants/routes';
import { getHeaderNavs, getSecondaryNav } from 'constants/navigation';

import Notices from 'components/common/notices/Notices';
import Overlay from 'components/common/overlay/Overlay';
import QuickLinksMenu from 'components/common/quicklinks/QuickLinksMenu';
import AskUsAnything from 'components/common/ask-us-anything/AskUsAnything';
import QuickLinksContainer from 'components/common/quicklinks/QuickLinksContainer';
import { useFetchQuickLinks } from 'hooks/query/useQuickLinksQuery';

import useAuthenticatedImage from 'hooks/useAuthenicatedImage';

const Header = props => {
  const { user: loggedInUser, history, noticeCount, isNoticeCountLoading } = props;
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
  const [quickLinks, setQuickLinks] = useState([]);
  const [hasErrorInLoadingQuickLinks, setHasErrorInLoadingQuickLinks] = useState(false);
  const [topPosition, setTopPosition] = useState(0);

  const reminderRef = useRef(null);
  const headerRef = useRef(null);
  const overlayRef = useRef(null);
  const quickLinksButtonRef = useRef(null);

  const {
    value: { reminders, permissions }
  } = useSelector(state => state.information);

  const secondaryNav = getSecondaryNav();

  const secondaryNavItems = useMemo(
    () => secondaryNav.filter(item => !item?.allowFor || item?.allowFor?.some(permission => permissions[permission])),
    [permissions]
  );

  const headerNav = useMemo(() => getHeaderNavs(history.location.pathname), [history.location.pathname]);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);

      await authService.logout();

      authService.redirectToLogin();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const avatarMenus = [
    {
      type: 'link',
      props: {
        label: 'View Profile',
        href: getUserProfileUrl(loggedInUser)
      }
    },
    {
      type: 'button',
      props: {
        label: isLoggingOut ? 'Logging out' : 'Logout',
        onClick: handleLogout,
        disabled: isLoggingOut
      }
    }
  ];

  const sidebarMenus = [
    {
      type: 'link',
      props: {
        label: 'View Profile',
        href: getUserProfileUrl(loggedInUser)
      },
      position: 'body'
    },
    {
      type: 'button',
      props: {
        label: isLoggingOut ? 'Logging out' : 'Logout',
        onClick: handleLogout,
        disabled: isLoggingOut
      },
      position: 'footer'
    }
  ];

  const { src: imageSrc } = useAuthenticatedImage({ src: getEmpImageUrl(loggedInUser.empId) });

  const user = useMemo(
    () => ({
      ...loggedInUser,
      name: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
      image: imageSrc
    }),
    [loggedInUser, imageSrc]
  );

  useEffect(() => {
    const header = document.querySelector('#header');

    if (header) {
      addClassToChildren(header, 'flex-fix', el => window.getComputedStyle(el).display === 'flex');
    }
  }, []);

  const { isLoading: isQuickLinksLoading } = useFetchQuickLinks({
    onSuccess: data => {
      if (!isEqual(data, quickLinks)) {
        setQuickLinks(data);
      }
    },
    onError: _error => setHasErrorInLoadingQuickLinks(true)
  });

  useEffect(() => {
    const handleResize = () => {
      const reminderHeight = reminderRef?.current?.clientHeight;
      const headerHeight = headerRef?.current?.clientHeight;

      // if both reminder and header height is not available, then return
      if (!reminderHeight && !headerHeight) {
        return;
      }

      setTopPosition(reminderHeight + headerHeight);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [reminderRef?.current?.clientHeight, headerRef?.current?.clientHeight]);

  useEffect(() => {
    const handleClickOutsideOverlay = event => {
      if (!overlayRef.current?.contains(event.target) && !quickLinksButtonRef.current?.contains(event.target)) {
        setIsQuickLinksOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutsideOverlay);

    return () => {
      document.removeEventListener('click', handleClickOutsideOverlay);
    };
  }, [isQuickLinksOpen, overlayRef?.current, quickLinksButtonRef?.current]);

  const handleQuickLinkMenuClick = () => {
    setIsQuickLinksOpen(prev => !prev);
  };

  return (
    <div className="flex-fix" id="header">
      <VyagutaWrapper>
        <div ref={reminderRef}>
          <NotificationBar
            notifications={reminders}
            event={{
              startDate: config.events.startDate,
              endDate: config.events.endDate,
              link: config.events.link,
              title: config.events.title
            }}
          />
        </div>
        <div ref={headerRef}>
          <VyagutaHeader headerNavs={headerNav} avatarMenus={avatarMenus} user={user} sidebarMenus={sidebarMenus}>
            <VyagutaHeader.Right>
              <div className="mr-16">
                <div className="header-content">
                  <AskUsAnything />

                  <div ref={quickLinksButtonRef}>
                    <QuickLinksMenu
                      isQuickLinksOpen={isQuickLinksOpen}
                      setIsQuickLinksOpen={setIsQuickLinksOpen}
                      onClick={handleQuickLinkMenuClick}
                    />
                  </div>
                  <Notices
                    active={history.location.pathname === NOTICE_BOARD}
                    noticeCount={noticeCount}
                    isNoticeCountLoading={isNoticeCountLoading}
                  />
                </div>
              </div>
            </VyagutaHeader.Right>
          </VyagutaHeader>
        </div>
        <Overlay
          isOpen={isQuickLinksOpen}
          onClose={() => setIsQuickLinksOpen(false)}
          parentScroll={false}
          topPosition={topPosition}
        >
          <div ref={overlayRef}>
            <QuickLinksContainer
              linkLibraryData={quickLinks}
              isLoading={isQuickLinksLoading}
              hasErrorInLoadingQuickLinks={hasErrorInLoadingQuickLinks}
            />
          </div>
        </Overlay>
        {props.location.pathname !== '/' && props.location.pathname !== NOTICE_BOARD && (
          <Switch>
            <SecondaryNav navItems={secondaryNavItems} />
          </Switch>
        )}
      </VyagutaWrapper>
    </div>
  );
};

/**
 * Gets users lms profiles url.
 *
 * @param {*} user
 * @returns
 */
const getUserProfileUrl = user => {
  return user && pinterpolate(config.endpoints.lms.userProfile, { id: user.id });
};

export default withRouter(Header);
