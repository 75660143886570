import React, { useState, useEffect } from 'react';

import classnames from 'classnames';

import * as toast from 'utils/toast';
import Loading from 'components/common/loading/Loading';
import AuthenticatedImage from 'components/common/authenticatedImage';

const ImageViewer = props => {
  const { visible, onClose, image } = props;

  const onKeyDown = event => event.key === 'Escape' && onClose();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    document.body.style.overflow = visible ? 'hidden' : 'auto';
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [visible]);

  return (
    visible && (
      <div className="profile-image-wrapper">
        <span
          className="cross-btn"
          onClick={() => {
            onClose();
          }}
        ></span>
        <AuthenticatedImage
          className={classnames('profile-image-original', {
            'loaded-image': !loading
          })}
          src={image.src}
          alt={image.alt}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            toast.error({
              title: 'Error',
              message: 'Failed to load the image'
            });
          }}
        >
          {loading && (
            <div className="profile-loading-wrapper">
              <Loading className="profile-loading" />
            </div>
          )}
        </AuthenticatedImage>
      </div>
    )
  );
};

export default ImageViewer;
