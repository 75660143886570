import React, { useMemo, useEffect } from 'react';

import { isDateInFuture } from 'utils/date';

import Input from 'components/common/inputWrapper/components/Input';
import Select from 'components/common/inputWrapper/components/Select';
import DateSelect from 'components/common/inputWrapper/components/DateSelect';
import FormRadioGroup from 'components/common/inputWrapper/components/RadioGroup';

import { COUNTRIES } from 'constants/countries';
import { COMPLETE, ERROR, PERSONAL_INFORMATION_FORM_INPUTS } from 'constants/createEmployeeForm';
import { BLOOD_GROUPS, GENDER_OPTIONS, MARITAL_STATUS, TIME_ZONES } from 'constants/appConstants';

const PersonalInformationForm = props => {
  const { errors, values, touched, handleChange, handleBlur, canEdit, currentForm, shouldForcefullyShowError } = props;

  const bloodGroupOptions = useMemo(
    () => BLOOD_GROUPS.map(bloodGroup => ({ label: bloodGroup, value: bloodGroup })),
    []
  );

  const countryOptions = useMemo(() => COUNTRIES.map(country => ({ label: country, value: country })), []);

  const timezoneOptions = useMemo(() => TIME_ZONES.map(timezone => ({ label: timezone, value: timezone })), []);

  const maritalStatus = useMemo(() => MARITAL_STATUS.map(status => ({ value: status })), []);

  useEffect(() => {
    if (currentForm) {
      const isFormEmpty = PERSONAL_INFORMATION_FORM_INPUTS.some(key => {
        if (!values[key]) {
          return true;
        }
        return false;
      });

      if (!isFormEmpty) {
        currentForm.state = COMPLETE;
        return;
      }
      currentForm.state = ERROR;
    }
  }, [values]);

  return (
    <div className="office-information-form personal-information">
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <FormRadioGroup
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.gender}
            error={(shouldForcefullyShowError || touched.gender) && errors.gender}
            label="Gender"
            name="gender"
            disabled={!canEdit('gender')}
            radioList={GENDER_OPTIONS}
            isMandatory
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <DateSelect
            name="dateofBirth"
            onChange={handleChange}
            disabled={!canEdit('dateofBirth')}
            onBlur={handleBlur}
            value={values.dateofBirth}
            error={(shouldForcefullyShowError || touched.dateofBirth) && errors.dateofBirth}
            isOutsideRange={day => isDateInFuture(day)}
            label="Date of Birth"
            customClassName={'employee-form__date'}
            isMandatory
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Select
            name="bloodGroup"
            onChange={handleChange}
            onBlur={handleBlur}
            canEdit={!canEdit('bloodGroup')}
            value={values.bloodGroup}
            error={(shouldForcefullyShowError || touched.bloodGroup) && errors.bloodGroup}
            label="Blood Group"
            isMandatory
            options={bloodGroupOptions}
            className={'employee-form__dropdown'}
          />
        </div>
      </div>
      <div className="office-information-form__full-width__space">
        <FormRadioGroup
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.maritialStatus}
          error={(shouldForcefullyShowError || touched.maritialStatus) && errors.maritialStatus}
          disabled={!canEdit('maritialStatus')}
          label="Marital Status"
          name="maritialStatus"
          radioList={maritalStatus}
          isMandatory
          selected="Single"
        />
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="personalEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.personalEmail}
            error={(shouldForcefullyShowError || touched.personalEmail) && errors.personalEmail}
            disabled={!canEdit('personalEmail')}
            label="Personal Email"
            isMandatory
            placeholder="ribby@gmail.com"
            multiline={false}
            type="email"
            customClassName={'employee-form__input'}
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="mobilePhone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobilePhone}
            disabled={!canEdit('mobilePhone')}
            error={(shouldForcefullyShowError || touched.mobilePhone) && errors.mobilePhone}
            label="Mobile Number"
            isMandatory
            placeholder="981234567"
            type="text"
            customClassName={'employee-form__input'}
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Input
            name="homePhone"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!canEdit('homePhone')}
            value={values.homePhone}
            error={(shouldForcefullyShowError || touched.homePhone) && errors.homePhone}
            label="Alternate Number"
            placeholder="9812345670"
            multiline={false}
            type="text"
            customClassName={'employee-form__input'}
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="emergencyPhone"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!canEdit('emergencyPhone')}
            value={values.emergencyPhone}
            error={(shouldForcefullyShowError || touched.emergencyPhone) && errors.emergencyPhone}
            label="Emergency Contact"
            isMandatory
            placeholder="9812345678"
            type="text"
            customClassName={'employee-form__input'}
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Input
            name="emergencyContactRelationship"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!canEdit('emergencyContactRelationship')}
            value={values.emergencyContactRelationship}
            error={
              (shouldForcefullyShowError || touched.emergencyContactRelationship) && errors.emergencyContactRelationship
            }
            label="Relationship with emergency contact"
            placeholder="Mom"
            multiline={false}
            type="text"
            isMandatory
            customClassName={'employee-form__input'}
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="temporaryAddress"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.temporaryAddress}
            error={(shouldForcefullyShowError || touched.temporaryAddress) && errors.temporaryAddress}
            label="Temporary Address"
            isMandatory
            disabled={!canEdit('temporaryAddress')}
            placeholder="Damak"
            multiline={false}
            type="text"
            customClassName={'employee-form__input'}
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Input
            name="permanentAddress"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.permanentAddress}
            error={(shouldForcefullyShowError || touched.permanentAddress) && errors.permanentAddress}
            label="Permanent Address"
            isMandatory
            disabled={!canEdit('permanentAddress')}
            placeholder="Kathmandu"
            multiline={false}
            type="text"
            customClassName={'employee-form__input'}
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Select
            name="country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
            disabled={!canEdit('country')}
            error={(shouldForcefullyShowError || touched.country) && errors.country}
            label="Currently Located at"
            isMandatory
            options={countryOptions}
            className={'employee-form__dropdown'}
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Select
            label="Time Zone"
            name="timezone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.timezone}
            isDisabled={!canEdit('timezone')}
            error={(shouldForcefullyShowError || touched.timezone) && errors.timezone}
            isMandatory
            options={timezoneOptions}
            className={'employee-form__dropdown'}
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="githubId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.githubId}
            disabled={!canEdit('githubId')}
            error={(shouldForcefullyShowError || touched.githubId) && errors.githubId}
            label="Github ID"
            placeholder="ribby"
            customClassName={'employee-form__input'}
          />
        </div>
        <div className="office-information-form__full-width__space"></div>
      </div>
    </div>
  );
};

export default PersonalInformationForm;
