import * as lodash from 'lodash';
import interpolate from 'pinterpolate';
import React, { useEffect } from 'react';

import MyTeamFilters from './MyTeamFilters';
import Table from 'components/common/table';
import Avatar from 'components/common/avatar';
import PopOver from 'components/common/popover';

import { MY_TEAM_FILTERS } from 'constants/myTeam';
import { MY_TEAM_FULLNAME_LENGTH, MY_TEAM_PROJECT_NAME_LENGTH } from 'constants/string';

import history from 'utils/history';
import { getEmpImageUrl } from 'utils/string';

import * as routes from 'constants/routes';

const MyTeamContent = props => {
  const { activeFilter, setActiveFilter, issuerTeam, managerTeam, appraiserTeam, coAppraiserTeam, isLoading } = props;

  const myTeamFilters = [
    {
      label: 'Issuer',
      count: issuerTeam.length,
      active: activeFilter === MY_TEAM_FILTERS.ISSUER,
      isVisible: !!issuerTeam.length
    },
    {
      label: 'Manager',
      count: managerTeam.length,
      active: activeFilter === MY_TEAM_FILTERS.MANAGER,
      isVisible: !!managerTeam.length
    },
    {
      label: 'Appraiser',
      count: appraiserTeam.length,
      active: activeFilter === MY_TEAM_FILTERS.APPRAISER,
      isVisible: !!appraiserTeam.length
    },
    {
      label: 'Co-appraiser',
      count: coAppraiserTeam.length,
      active: activeFilter === MY_TEAM_FILTERS.COAPPRAISER,
      isVisible: !!coAppraiserTeam.length
    }
  ];

  const filterContent = {
    [MY_TEAM_FILTERS.ISSUER]: {
      content: issuerTeam
    },
    [MY_TEAM_FILTERS.MANAGER]: {
      content: managerTeam
    },
    [MY_TEAM_FILTERS.APPRAISER]: {
      content: appraiserTeam
    },
    [MY_TEAM_FILTERS.COAPPRAISER]: {
      content: coAppraiserTeam
    }
  };

  const columns = [
    {
      Header: <span className="text-bold capital-text">Name</span>,
      maxWidth: 200,
      Cell: props => {
        const { user } = props.original;
        const { fullname } = user;

        return (
          <div className="d-flex flex-row align-items-center">
            <Avatar
              className="nametag nametag--my-team profile-photo my-team-profile mr-0"
              image={getEmpImageUrl(user.empId)}
              alt={fullname}
            />
            <div className="d-flex flex-column align-items-start ml-2x">
              {fullname.length > MY_TEAM_FULLNAME_LENGTH ? (
                <PopOver
                  html={
                    <div className="tooltip-info word-break">
                      <p className="tooltip-info__title">{fullname}</p>
                    </div>
                  }
                  customClass="d-il-grid"
                >
                  <div className="ellipsis">{fullname}</div>
                </PopOver>
              ) : (
                <div>{fullname}</div>
              )}
            </div>
          </div>
        );
      }
    },
    {
      Header: 'Project/Area',
      accessor: 'projectName',
      maxWidth: 400,
      Cell: props => {
        const data = props.original;

        const { currentAllocations } = data;

        if (!currentAllocations || currentAllocations.length === 0) {
          return '-';
        }

        const projectNames = currentAllocations.map(({ name }) => name).join(', ');

        return projectNames.length > MY_TEAM_PROJECT_NAME_LENGTH ? (
          <PopOver
            html={
              <div className="tooltip-info word-break">
                <p className="tooltip-info__title">{projectNames}</p>
              </div>
            }
          >
            <div className="ellipsis">{projectNames}</div>
          </PopOver>
        ) : (
          <div>{projectNames}</div>
        );
      }
    }
  ];

  useEffect(() => {
    let newActiveFilter = null;

    if (issuerTeam.length) {
      newActiveFilter = MY_TEAM_FILTERS.ISSUER;
    } else if (managerTeam.length) {
      newActiveFilter = MY_TEAM_FILTERS.MANAGER;
    } else if (appraiserTeam.length) {
      newActiveFilter = MY_TEAM_FILTERS.APPRAISER;
    } else if (coAppraiserTeam.length) {
      newActiveFilter = MY_TEAM_FILTERS.COAPPRAISER;
    }

    setActiveFilter(newActiveFilter);
  }, [isLoading]);

  return (
    <>
      <div className="my-team-list__filters">
        {myTeamFilters.map(filter => (
          <MyTeamFilters
            label={filter.label}
            loading={false}
            count={filter.count}
            active={filter.active}
            isVisible={filter.isVisible}
            onClick={() => setActiveFilter(MY_TEAM_FILTERS[lodash.camelCase(filter.label).toUpperCase()])}
          />
        ))}
      </div>
      <div className="my-team-list__content custom-scroll-bar">
        <Table
          loading={isLoading}
          data={filterContent[activeFilter]?.content}
          columns={columns}
          showPagination={false}
          getTrProps={(_state, rowInfo) => {
            return {
              className: 'align-items-center d-flex',
              onClick: e => {
                const {
                  user: { id }
                } = rowInfo.original;

                const url = interpolate(routes.EMPLOYEE_PROFILE, { id });
                if (e.ctrlKey || e.metaKey) {
                  window.open(url);
                } else {
                  history.push(url);
                }
              }
            };
          }}
        />
      </div>
    </>
  );
};

export default MyTeamContent;
