import React from 'react';
import classNames from 'classnames';

function CustomCheckbox(props) {
  const { checked = false, label = '', onChange, className, disabled = false, indeterminate } = props;

  return (
    <label
      className={classNames('lf-custom-checkbox__container', className)}
      role="button"
      onKeyUp={() => {}}
      onClick={e => {
        e.stopPropagation();
      }}
      tabIndex={0}
    >
      {label}
      <input
        checked={checked}
        className="lf-custom-checkbox__input"
        onChange={onChange}
        type="checkbox"
        disabled={disabled}
      />
      <span
        className={classNames('lf-custom-checkbox__checkmark', {
          disabled,
          'lf-custom-checkbox__indeterminate': indeterminate
        })}
      />
    </label>
  );
}

export default CustomCheckbox;
