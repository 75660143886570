import React from 'react';
import classnames from 'classnames';
import { truncate } from 'utils/string';
import PopOver from '../popover/popover';
import { TRAINING_TRUNCATE_LENGTH } from 'constants/string';

import Avatar from '../avatar';

const Badge = props => {
  const { closeIcon, label, color, clickableLabel, isTrainingLabel = false, handleOnClick, imageUrl, disable } = props;

  return (
    <div className={classnames('badge', 'badge--rounded', 'badge--grey ', { [disable]: disable })}>
      {imageUrl && (
        <div className="mr-5">
          <Avatar image={imageUrl} alt={label} size="small" />
        </div>
      )}

      {isTrainingLabel && label.length > TRAINING_TRUNCATE_LENGTH ? (
        <PopOver
          interactive
          html={
            <div className="tooltip-info">
              <p className="tooltip-info__title">{label}</p>
            </div>
          }
        >
          <span
            className={classnames('badge__label', { [color]: color }, { [clickableLabel]: clickableLabel })}
            onClick={handleOnClick}
          >
            {truncate(label, TRAINING_TRUNCATE_LENGTH)}
          </span>
        </PopOver>
      ) : (
        <span
          className={classnames('badge__label', { [color]: color }, { [clickableLabel]: clickableLabel })}
          onClick={handleOnClick}
        >
          {label}
        </span>
      )}

      {closeIcon && (
        <span className={classnames('badge__icon', 'icon ml-2x', { [disable]: disable })}>{closeIcon}</span>
      )}
    </div>
  );
};

export default Badge;
