import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

const Overlay = props => {
  const { isOpen, className, onClose, shouldCloseOnOverlayClick, parentScroll = true, children, topPosition } = props;

  return (
    <ReactModal
      preventScroll
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classNames(className, 'lf-overlay-modal')}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={classNames(`tpx-${topPosition}`, 'lf-overlay z-index-1000')}
      bodyOpenClassName={classNames('lf-overlay-body', {
        'lf-overlay-body--scroll': parentScroll,
        'lf-overlay-body--hidden': !parentScroll
      })}
    >
      <div className="lf-overlay-modal__body">{children}</div>
    </ReactModal>
  );
};

export default Overlay;
