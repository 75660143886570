import React from 'react';

import Avatar from '@vyaguta/vyaguta-ui/dist/src/components/common/avatar/Avatar';

import useAuthenticatedImage from 'hooks/useAuthenicatedImage';

export default function MyAvatar(props) {
  const { image, ...rest } = props;

  const { src } = useAuthenticatedImage({ src: image });

  return <Avatar image={src} {...rest} />;
}
