import lodash from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { UiSearch } from 'vyaguta-icons/ui';
import React, { useState, useEffect, useCallback } from 'react';

import * as routes from 'constants/routes';
import * as roleService from 'services/role';
import * as userService from 'services/user';
import * as departmentService from 'services/department';
import * as designationService from 'services/designation';
import * as engagementStatusService from 'services/engagementStatus';

import { parse } from 'utils/queryParam';
import { handleError } from 'utils/errorHandler';
import { EMPLOYEES } from 'constants/permissions';
import { fetchOptions } from 'utils/dropdownsearch';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { MAKE_SEARCH_BY } from 'constants/fetchTypes';
import { extractFullName, pluralize } from 'utils/string';
import DropDownSearch from 'components/common/dropdownsearch';
import { handleUserOfflineMode } from 'utils/internetConnection';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import {
  BREAKPOINTS,
  TYPE_USER_TEXT,
  MIN_DEBOUNCE_TIME,
  NO_USERS_FOUND_TEXT,
  MIN_USER_SEARCH_LENGTH
} from 'constants/appConstants';
import { WORKING_SHIFTS_FILTER_OPTION } from 'constants/worklog';

const EmployeesFilter = props => {
  const { data, applyFilter, setIsFilterApplied, isFilterApplied, isDataLoading } = props;
  const queryParams = parse(props.location.search);

  const [filters, setFilters] = useState({ ...queryParams });
  const [isFetchingFilters, setIsFetchingFilters] = useState(true);
  const [isFetchingLeaveIssuer, setIsFetchingLeaveIssuer] = useState(false);
  const [isFetchingSupervisor, setIsFetchingSupervisor] = useState(false);
  const [isFetchingAppraiser, setIsFetchingAppraiser] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [moreOptionsFilter, setMoreOptionsFilter] = useState([]);
  const [rolesOptionsFilter, setRolesOptionsFilter] = useState([]);
  const [departmentOptionsFilter, setDepartmentOptionsFilter] = useState([]);
  const [engagmentStatusOptionsFilter, setEngagmentStatusOptionsFilter] = useState([]);
  const [leaveIssuerOptionsFilter, setLeaveIssuerOptionsFilter] = useState([]);
  const [appraiserOptionsFilter, setAppraiserOptionsFilter] = useState([]);
  const [designationOptionsFilter, setDesignationOptionsFilter] = useState([]);
  const [workingShiftOptionsFilter, setWorkingShiftOptionsFilter] = useState([]);
  const [supervisorOptionsFilter, setSupervisorOptionsFilter] = useState([]);

  const [rolesData, setRolesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [engagementStatusData, setEngagmentStatusData] = useState([]);
  const [leaveIssuerData, setLeaveIssuerData] = useState([]);
  const [appraiserData, setAppraiserData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [supervisorData, setSupervisorData] = useState([]);

  const [selectedMoreFilterList, setSelectedMoreList] = useState([]);
  const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
  const [selectedEngStatusList, setSelectedEngStatusList] = useState([]);
  const [selectedLeaveIssuerList, setSelectedLeaveIssuerList] = useState([]);
  const [selectedAppraiserList, setSelectedAppraiserList] = useState([]);
  const [selectedSupervisorList, setSelectedSupervisorList] = useState([]);
  const [selectedDesignationList, setSelectedDesignationList] = useState([]);
  const [selectedWorkShiftList, setSelectedWorkShiftList] = useState([]);
  const [selectedRolesList, setSelectedRolesList] = useState([]);

  const [leaverIssuerCode, setLeaveIssuerCode] = useState('');
  const [supervisorCode, setSupervisorCode] = useState('');
  const [appraiserCode, setAppraiserCode] = useState('');

  const debounceLoadingStates = {
    leaveIssuerId: isFetchingLeaveIssuer,
    coachId: isFetchingAppraiser,
    supervisorId: isFetchingSupervisor
  };

  const isMobileView = useMediaQuery({ maxWidth: BREAKPOINTS.xs });

  const filtersSettings = {
    department: {
      query: 'departmentId',
      loadingState: isFetchingFilters,
      label: 'Department',
      allowMultiple: true,
      getOptions() {
        return fetchOptions(departmentService.fetchAll, 'id', 'name');
      }
    },
    employeeStatus: {
      query: 'empStatus',
      loadingState: isFetchingFilters,
      label: 'Employment Status',
      allowMultiple: true,
      getOptions() {
        return fetchOptions(engagementStatusService.fetchAll, 'name', 'name');
      }
    },
    roles: {
      query: 'roleIds',
      loadingState: isFetchingFilters,
      label: 'Roles',
      allowMultiple: true,
      getOptions() {
        return roleService.fetchAll();
      }
    },
    leaveIssuer: {
      query: 'leaveIssuerId',
      loadingState: isFetchingLeaveIssuer,
      label: 'Leave Issuer',
      allowMultiple: true,
      getOptions() {
        return fetchOptions(userService.fetchAll, 'id', data => extractFullName(data));
      }
    },
    supervisor: {
      query: 'supervisorId',
      loadingState: isFetchingSupervisor,
      label: 'Supervisor',
      allowMultiple: true,
      getOptions() {
        return fetchOptions(userService.fetchAll, 'id', data => extractFullName(data));
      }
    },
    appraiser: {
      query: 'coachId',
      loadingState: isFetchingAppraiser,
      label: 'Appraiser',
      allowMultiple: true,
      getOptions() {
        return fetchOptions(userService.fetchAll, 'id', data => extractFullName(data));
      }
    },
    designation: {
      query: 'designationId',
      loadingState: isFetchingFilters,
      label: 'Designation',
      allowMultiple: true,
      getOptions() {
        return fetchOptions(designationService.fetchAll, 'id', 'name');
      }
    },
    workingShift: {
      query: 'workingShiftId',
      loadingState: false,
      label: 'Working Shift Timing',
      allowMultiple: true,
      getOptions: () => {
        return WORKING_SHIFTS_FILTER_OPTION;
      }
    }
  };

  function formatOptionValue(data) {
    return {
      id: data.id,
      empId: data.empId
    };
  }

  useEffect(() => {
    (async () => {
      if (!props.permissions[EMPLOYEES.FILTER]) {
        return;
      }
      const moreList = Object.keys(filtersSettings).map(item => ({
        value: filtersSettings[item].query,
        label: filtersSettings[item].label
      }));
      setMoreOptionsFilter(moreList);

      Object.keys(filtersSettings).forEach((filter, index) => {
        handleSelectedOptions(filter, filtersSettings[filter].query);
      });
      setIsFetchingFilters(false);
    })();
  }, [props.permissions]);

  useEffect(() => {
    (async () => {
      if (!leaverIssuerCode) {
        return;
      }

      if (leaverIssuerCode.trimStart().length < MIN_USER_SEARCH_LENGTH) {
        return;
      }

      try {
        setIsFetchingLeaveIssuer(true);

        const leaveIssuerData = await fetchOptions(
          userService.fetchAll,
          data => formatOptionValue(data),
          data => extractFullName(data),
          {
            q: leaverIssuerCode,
            searchBy: MAKE_SEARCH_BY.join(',')
          }
        );

        const filterOutList = !selectedLeaveIssuerList.length
          ? leaveIssuerData
          : leaveIssuerData.filter(leaveIssuer => {
              return !selectedLeaveIssuerList.some(item => item.value.id === leaveIssuer.value.id);
            });

        setLeaveIssuerData(filterOutList);
        setLeaveIssuerOptionsFilter(filterOutList);
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingLeaveIssuer(false);
      }
    })();
  }, [leaverIssuerCode]);

  useEffect(() => {
    (async () => {
      if (!supervisorCode) {
        return;
      }

      if (supervisorCode.trimStart().length < MIN_USER_SEARCH_LENGTH) {
        return;
      }

      try {
        setIsFetchingSupervisor(true);

        const supervisorData = await fetchOptions(
          userService.fetchAll,
          data => formatOptionValue(data),
          data => extractFullName(data),
          {
            q: supervisorCode,
            searchBy: MAKE_SEARCH_BY.join(',')
          }
        );

        setSupervisorData(supervisorData);
        setSupervisorOptionsFilter(supervisorData);
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingSupervisor(false);
      }
    })();
  }, [supervisorCode]);

  useEffect(() => {
    (async () => {
      if (!appraiserCode) {
        return;
      }

      if (appraiserCode.trimStart().length < MIN_USER_SEARCH_LENGTH) {
        return;
      }

      try {
        setIsFetchingAppraiser(true);

        const appraiserData = await fetchOptions(
          userService.fetchAll,
          data => formatOptionValue(data),
          data => extractFullName(data),
          {
            q: appraiserCode,
            searchBy: MAKE_SEARCH_BY.join(',')
          }
        );

        setAppraiserData(appraiserData);
        setAppraiserOptionsFilter(appraiserData);
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingAppraiser(false);
      }
    })();
  }, [appraiserCode]);

  const handleSelectedOptions = async (filterItem, query) => {
    const shouldApplySelectedQueries = ['leaverIssuer', 'supervisor', 'appraiser'].includes(filterItem);

    if (shouldApplySelectedQueries) {
      return;
    }

    const selectedQueries = !filters[query] ? [] : filters[query].split(',');

    if (!selectedQueries.length) {
      return;
    }
    const filterList = (await handleFetchFilter(query)) || [];

    const selectedOptions = selectedQueries.map(selectedId => {
      const selectedItem = filterList.reduce((acc, current) => {
        if (`${current.value}` === selectedId) {
          return {
            value: selectedId,
            label: current.label
          };
        }

        return acc;
      }, {});

      return selectedItem;
    });

    handleAlreadySelected(query, selectedQueries, selectedOptions);
    const setFilterObj = { value: filtersSettings[filterItem].query, label: filtersSettings[filterItem].label };
    setMoreOptionsFilter(moreData => moreData.filter(item => item.value !== query));
    setSelectedMoreList(prevData => [...prevData, setFilterObj]);
  };

  const handleAlreadySelected = (query, selectedQueries, selectedOptions) => {
    switch (query) {
      case filtersSettings.roles.query:
        setRolesOptionsFilter(roleData => roleData.filter(item => !selectedQueries.includes(`${item.value}`)));
        setSelectedRolesList(selectedOptions);
        break;
      case filtersSettings.department.query:
        setDepartmentOptionsFilter(depData => depData.filter(item => !selectedQueries.includes(`${item.value}`)));
        setSelectedDepartmentList(selectedOptions);
        break;
      case filtersSettings.employeeStatus.query:
        setEngagmentStatusOptionsFilter(engData => engData.filter(item => !selectedQueries.includes(`${item.value}`)));
        setSelectedEngStatusList(selectedOptions);
        break;
      case filtersSettings.designation.query:
        setDesignationOptionsFilter(desgData => desgData.filter(item => !selectedQueries.includes(`${item.value}`)));
        setSelectedDesignationList(selectedOptions);
        break;
      case filtersSettings.workingShift.query:
        setWorkingShiftOptionsFilter(workShiftData =>
          workShiftData.filter(item => !selectedQueries.includes(`${item.value}`))
        );
        setSelectedWorkShiftList(selectedOptions);
        break;
      default:
        break;
    }
  };

  const updateFilter = newState => {
    const updatedNewState = Object.entries(newState).reduce((acc, [key, value]) => {
      if (!value.length) {
        return acc;
      }

      return { [key]: key === 'q' ? value : value.join(','), ...acc };
    }, {});

    setFilters(prevState => {
      const isFilterChange = !lodash.isEqual(queryParams, updatedNewState);
      setIsFilterChanged(isFilterChange);

      return { ...prevState, ...newState };
    });
  };

  const handleApplyFilter = () => {
    applyFilter(filters);
    setIsFilterChanged(false);
    setIsFilterApplied(true);
  };

  const handleSearchChange = event => {
    const searchValue = event.target.value.trimLeft();
    const filterSearchValue = !queryParams.q ? '' : queryParams.q;

    updateFilter({ q: searchValue });

    if (searchValue === filterSearchValue) {
      setIsFilterChanged(false);
    }
  };

  const handleSearchClose = () => {
    const updatedFilters = { ...filters, q: undefined };

    if (!queryParams.q) {
      setFilters(updatedFilters);
      setIsFilterChanged(false);

      return;
    }

    setFilters(updatedFilters);
    applyFilter(updatedFilters);
    setIsFilterChanged(false);
  };

  useEffect(() => {
    const isRolesFilterAbsent =
      !selectedRolesList.length &&
      !selectedSupervisorList.length &&
      !selectedAppraiserList.length &&
      !selectedDepartmentList.length &&
      !selectedDesignationList.length &&
      !selectedEngStatusList.length &&
      !selectedLeaveIssuerList.length &&
      !selectedWorkShiftList.length;

    const isQueryParamsAbsent =
      !queryParams.roleIds &&
      !queryParams.departmentId &&
      !queryParams.empStatus &&
      !queryParams.leaveIssuerId &&
      !queryParams.coachId &&
      !queryParams.desingationId &&
      !queryParams.supervisorId;

    if (isRolesFilterAbsent) {
      if (isQueryParamsAbsent) {
        setIsFilterChanged(false);
        setIsFilterApplied(false);
      }

      return;
    }

    updateFilter({
      roleIds: selectedRolesList.map(({ value }) => value),
      departmentId: selectedDepartmentList.map(({ value }) => value),
      empStatus: selectedEngStatusList.map(({ value }) => value),
      leaveIssuerId: selectedLeaveIssuerList.map(({ value }) => value.id),
      coachId: selectedAppraiserList.map(({ value }) => value.id),
      designationId: selectedDesignationList.map(({ value }) => value),
      supervisorId: selectedSupervisorList.map(({ value }) => value.id),
      workingShiftId: selectedWorkShiftList.map(({ value }) => value)
    });
  }, [
    selectedRolesList,
    selectedDepartmentList,
    selectedDesignationList,
    selectedWorkShiftList,
    selectedEngStatusList,
    selectedLeaveIssuerList,
    selectedAppraiserList,
    selectedSupervisorList,
    queryParams.roleIds,
    queryParams.departmentId,
    queryParams.empStatus,
    queryParams.leaveIssuerId,
    queryParams.coachId,
    queryParams.desingationId,
    queryParams.supervisorId
  ]);

  const handleFetchFilter = async item => {
    try {
      switch (item) {
        case filtersSettings.department.query:
          const department = await filtersSettings.department.getOptions();
          setDepartmentData(department);
          setDepartmentOptionsFilter(department);

          return department;
        case filtersSettings.employeeStatus.query:
          const engagementStatus = await filtersSettings.employeeStatus.getOptions();
          setEngagmentStatusData(engagementStatus);
          setEngagmentStatusOptionsFilter(engagementStatus);

          return engagementStatus;
        case filtersSettings.roles.query:
          const roles = await filtersSettings.roles.getOptions();
          const rolesList = roles.map(({ id, name }) => ({ value: id, label: name }));
          const sortedRoles = lodash.sortBy(rolesList, ['label']);
          setRolesData(sortedRoles);
          setRolesOptionsFilter(sortedRoles);

          return sortedRoles;
        case filtersSettings.designation.query:
          const designation = await filtersSettings.designation.getOptions();
          setDesignationData(designation);
          setDesignationOptionsFilter(designation);

          return designation;
        case filtersSettings.workingShift.query:
          const workingShift = await filtersSettings.workingShift.getOptions();
          setWorkingShiftOptionsFilter(workingShift);

          return workingShift;
        default:
          break;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleOnMoreFilterChange = options => {
    setSelectedMoreList(options);
    if (options.length === 0) {
      const moreList = Object.keys(filtersSettings).map(item => ({
        value: filtersSettings[item].query,
        loadingState: filtersSettings[item].loadingState,
        label: filtersSettings[item].label
      }));
      setMoreOptionsFilter(moreList);
      setSelectedMoreList([]);

      return;
    }
    handleFetchFilter(options[options.length - 1].value);
    const filteredList = moreOptionsFilter.filter(moreFilter => moreFilter.value !== options.value);
    setMoreOptionsFilter(filteredList);
  };

  const handleEachFilterChange = (item, options) => {
    switch (item) {
      case filtersSettings.department.query:
        setSelectedDepartmentList(options);
        if (options.length === 0) {
          setDepartmentOptionsFilter(departmentData);
          setSelectedDepartmentList([]);
          setFilters({ ...filters, departmentId: undefined });

          return;
        }
        const departmentFilterList = departmentOptionsFilter.filter(department => department.value !== options.value);

        setDepartmentOptionsFilter(departmentFilterList);
        break;
      case filtersSettings.employeeStatus.query:
        setSelectedEngStatusList(options);
        if (options.length === 0) {
          setEngagmentStatusOptionsFilter(engagementStatusData);
          setSelectedEngStatusList([]);
          setFilters({ ...filters, empStatus: undefined });

          return;
        }
        const empFilteredList = engagmentStatusOptionsFilter.filter(empStatus => empStatus.value !== options.value);

        setEngagmentStatusOptionsFilter(empFilteredList);
        break;
      case filtersSettings.roles.query:
        setSelectedRolesList(options);
        if (options.length === 0) {
          setRolesOptionsFilter(rolesData);
          setSelectedRolesList([]);
          setFilters({ ...filters, roleIds: undefined });

          return;
        }
        const roleFilteredList = rolesOptionsFilter.filter(role => role.value !== options.value);

        setRolesOptionsFilter(roleFilteredList);
        break;
      case filtersSettings.leaveIssuer.query:
        setSelectedLeaveIssuerList(options);
        setLeaveIssuerCode('');
        if (options.length === 0) {
          setLeaveIssuerOptionsFilter(leaveIssuerData);
          setSelectedLeaveIssuerList([]);
          setFilters({ ...filters, leaveIssuerId: undefined });

          return;
        }
        const leaveIssuerList = leaveIssuerOptionsFilter.filter(leaveIssuer => leaveIssuer.value !== options.value);

        setLeaveIssuerOptionsFilter(leaveIssuerList);
        break;
      case filtersSettings.supervisor.query:
        setSelectedSupervisorList(options);
        if (options.length === 0) {
          setSupervisorOptionsFilter(supervisorData);
          setSelectedSupervisorList([]);
          setFilters({ ...filters, supervisorId: undefined });

          return;
        }
        const supervisorFilterdList = supervisorOptionsFilter.filter(supervisor => supervisor.value !== options.value);

        setSupervisorOptionsFilter(supervisorFilterdList);
        break;
      case filtersSettings.appraiser.query:
        setSelectedAppraiserList(options);
        if (options.length === 0) {
          setAppraiserOptionsFilter(appraiserData);
          setSelectedAppraiserList([]);
          setFilters({ ...filters, coachId: undefined });

          return;
        }
        const appraiserFilterList = appraiserOptionsFilter.filter(appraiser => appraiser.value !== options.value);

        setAppraiserOptionsFilter(appraiserFilterList);
        break;
      case filtersSettings.designation.query:
        setSelectedDesignationList(options);
        if (options.length === 0) {
          setDesignationOptionsFilter(designationData);
          setSelectedDesignationList([]);
          setFilters({ ...filters, designationId: undefined });

          return;
        }
        const designationFilteredList = designationOptionsFilter.filter(
          designation => designation.value !== options.value
        );

        setDesignationOptionsFilter(designationFilteredList);
        break;
      case filtersSettings.workingShift.query:
        if (options.length === 0) {
          setWorkingShiftOptionsFilter(WORKING_SHIFTS_FILTER_OPTION);
          setSelectedWorkShiftList([]);
          setFilters({ ...filters, workingShiftId: undefined });
        } else {
          const workingShiftFilteredList = workingShiftOptionsFilter.filter(
            workingShift => workingShift.value !== options.value
          );

          setWorkingShiftOptionsFilter(workingShiftFilteredList);

          setSelectedWorkShiftList(options);
        }
        break;
      default:
        break;
    }
  };

  const handleRemoveFilter = (item, index, optionsArray) => {
    handleEachFilterChange(item.value, []);
    optionsArray.splice(index, 1);
    const options = [...optionsArray];
    handleOnMoreFilterChange(options);
  };

  const handleRemoveFilters = () => {
    setFilters({});
    setIsFilterApplied(false);
    setSelectedRolesList([]);
    setSelectedAppraiserList([]);
    setSelectedDepartmentList([]);
    setSelectedDesignationList([]);
    setSelectedEngStatusList([]);
    setSelectedLeaveIssuerList([]);
    setSelectedSupervisorList([]);
    setDepartmentOptionsFilter(departmentData);
    setDesignationData(designationData);
    setEngagmentStatusOptionsFilter(engagementStatusData);
    setRolesOptionsFilter(rolesData);
    setSelectedWorkShiftList([]);
    applyFilter({});
  };

  const handleInputChange = (item, code) => {
    if (!code) {
      return;
    }
    switch (item) {
      case filtersSettings.leaveIssuer.query:
        if (code.trimStart().length < MIN_USER_SEARCH_LENGTH) {
          setIsFetchingLeaveIssuer(false);

          return;
        }

        setIsFetchingLeaveIssuer(false);
        leaveIssuerDebounceFunction(code);
        break;

      case filtersSettings.supervisor.query:
        if (code.trimStart().length < MIN_USER_SEARCH_LENGTH) {
          setIsFetchingSupervisor(false);

          return;
        }

        setIsFetchingSupervisor(false);
        supervisorDebounceFunction(code);
        break;

      case filtersSettings.appraiser.query:
        if (code.trimStart().length < MIN_USER_SEARCH_LENGTH) {
          setIsFetchingAppraiser(false);

          return;
        }

        setIsFetchingAppraiser(false);
        appraiserDebounceFunction(code);
        break;

      default:
        break;
    }
  };

  const leaveIssuerDebounceFunction = useCallback(
    lodash.debounce(code => {
      setLeaveIssuerCode(code);
    }, MIN_DEBOUNCE_TIME),
    []
  );

  const supervisorDebounceFunction = useCallback(
    lodash.debounce(code => {
      setSupervisorCode(code);
    }, MIN_DEBOUNCE_TIME),
    []
  );

  const appraiserDebounceFunction = useCallback(
    lodash.debounce(code => {
      setAppraiserCode(code);
    }, MIN_DEBOUNCE_TIME),
    []
  );

  const handleNoOptionsMessage = item => {
    switch (item) {
      case filtersSettings.leaveIssuer.query:
        return leaverIssuerCode ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT;
      case filtersSettings.supervisor.query:
        return supervisorCode ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT;
      case filtersSettings.appraiser.query:
        return appraiserCode ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT;
      default:
        break;
    }
  };

  const getEachFilterData = item => {
    switch (item) {
      case filtersSettings.department.query:
        return selectedDepartmentList;
      case filtersSettings.employeeStatus.query:
        return selectedEngStatusList;
      case filtersSettings.roles.query:
        return selectedRolesList;
      case filtersSettings.leaveIssuer.query:
        return selectedLeaveIssuerList;
      case filtersSettings.supervisor.query:
        return selectedSupervisorList;
      case filtersSettings.appraiser.query:
        return selectedAppraiserList;
      case filtersSettings.designation.query:
        return selectedDesignationList;
      case filtersSettings.workingShift.query:
        return selectedWorkShiftList;
      default:
        break;
    }
  };

  const getEachFilterOptions = item => {
    switch (item) {
      case filtersSettings.department.query:
        return !departmentOptionsFilter ? [] : lodash.sortBy(departmentOptionsFilter, ['label']);
      case filtersSettings.employeeStatus.query:
        return engagmentStatusOptionsFilter;
      case filtersSettings.roles.query:
        return rolesOptionsFilter;
      case filtersSettings.leaveIssuer.query:
        return leaveIssuerOptionsFilter;
      case filtersSettings.supervisor.query:
        return supervisorOptionsFilter;
      case filtersSettings.appraiser.query:
        return appraiserOptionsFilter;
      case filtersSettings.designation.query:
        return !designationOptionsFilter ? [] : lodash.sortBy(designationOptionsFilter, ['label']);
      case filtersSettings.workingShift.query:
        return WORKING_SHIFTS_FILTER_OPTION;
      default:
        break;
    }
  };

  function isUserListFilter(item) {
    return [
      filtersSettings.leaveIssuer.query,
      filtersSettings.supervisor.query,
      filtersSettings.appraiser.query
    ].includes(item);
  }

  function handleOnKeyUp(e) {
    let applyFilter = false;

    if (isMobileView && (e.currentTarget.value.length >= MIN_USER_SEARCH_LENGTH || e.currentTarget.value === '')) {
      applyFilter = true;
    }

    if (e.key === 'Enter' && isFilterChanged) {
      applyFilter = true;
    }

    return applyFilter && handleApplyFilterBtnClick();
  }

  const handleApplyFilterBtnClick = () => handleUserOfflineMode(handleApplyFilter);

  const handleRemoveFiltersBtnClick = () => handleUserOfflineMode(handleRemoveFilters);

  return (
    <div className="full-scope-card__header">
      <div className="employees__header name-wrap">
        <div className="employees__main-title">
          <div>
            <div className="employees__title">Leapfroggers</div>
            {!isDataLoading && (
              <div className="employees__subtitle">
                Showing {data.size} of {data.total} {pluralize('Leapfrogger', data.total)}
              </div>
            )}
          </div>
          <div
            className="employees__search-bar d-flex flex-row align-items-center"
            title="Search by ID, Name, Location, Designation, Blood Group etc."
          >
            <UiSearch className="employees__search-icon" />
            <input
              className="employees__search-input"
              value={filters.q ?? ''}
              type="text"
              onKeyUp={handleOnKeyUp}
              onChange={handleSearchChange}
              placeholder="Search leapfroggers..."
              data-cy="search-input"
            />
            {filters.q && filters.q.trim().length > 0 && (
              <IoMdClose className="employees__search-icon__close" onClick={handleSearchClose} />
            )}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center ">
          <div className="employees__filter-bar title-bar__filter-buttons d-flex align-items-center f-wrap">
            {props.permissions[EMPLOYEES.FILTER] && (
              <div className="sm-d-none">
                <DropDownSearch
                  showSelected={true}
                  title="More Filters"
                  placeholder={''}
                  values={selectedMoreFilterList}
                  isPlain
                  isBig
                  isLoading={isFetchingFilters}
                  options={moreOptionsFilter}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  customClassName={classNames('lf-select')}
                  onChange={handleOnMoreFilterChange}
                  searchLabel="Select"
                  data-cy="more-filter"
                />
              </div>
            )}

            <button
              className={classNames('button ml-10 sm-d-none', {
                'button--primary': !isFilterChanged,
                'button--secondary': isFilterChanged
              })}
              onClick={isFilterChanged && handleApplyFilterBtnClick}
              data-cy="apply-filter"
            >
              Apply Filter
            </button>

            <button
              className={classNames('button__reset ml-10  sm-d-none', {
                'button__reset--active': isFilterApplied
              })}
              onClick={isFilterApplied && handleRemoveFiltersBtnClick}
              data-cy="reset-filter"
            >
              Reset
            </button>

            {props.permissions[EMPLOYEES.CREATE] && (
              <Link className="add-employee-button ml-20" to={routes.CREATE_EMPLOYEE} data-cy="add-leapfrogger">
                Add <span className="md-d-none sm-d-none">Leapfrogger</span>
              </Link>
            )}
          </div>
        </div>
      </div>
      {!isFetchingFilters && (
        <div className={classNames('employees employees__more-filters sm-d-none')}>
          {selectedMoreFilterList.map((item, index, array) => {
            return (
              <DropDownSearch
                key={`dropDownSearch${index}`}
                showSelected={true}
                title={item.label}
                placeholder={item.label}
                values={getEachFilterData(item.value)}
                isPlain
                isRemovable
                isBig
                isLoading={item.loadingState}
                isDebounceLoading={debounceLoadingStates[item.value]}
                handleInputChange={code => handleInputChange(item.value, code)}
                noOptionsMessage={
                  isUserListFilter(item.value) ? () => handleNoOptionsMessage(item.value) : () => 'No options'
                }
                options={getEachFilterOptions(item.value)}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                customClassName={classNames('lf-select')}
                onChange={options => handleEachFilterChange(item.value, options)}
                onRemove={() => handleRemoveFilter(item, index, array)}
                searchLabel="All"
                iconComponent={isUserListFilter(item.value) && AvatarComponent}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  permissions: state.information.value.permissions
});

export default connect(mapStateToProps)(EmployeesFilter);
